import React, {useEffect} from 'react';
import ProfileTextField from "./ProfileTextField";
import Container from "@material-ui/core/Container";
import useProfileTextField from "./useProfileTextField";
import Button from '@material-ui/core/Button';
import {REG_EX_EMAIL, REG_EX_FIRST_NAME, REG_EX_LAST_NAME, REG_EX_USERNAME} from "../properties/form";
import {makeStyles} from "@material-ui/core/styles";
import ChangePasswordForm from "./password/ChangePasswordForm";
import useForm from "../../form/useForm";
import ConfirmProfileChangeForm from "./ConfirmProfileChangeForm";
import Call from "../../server/Call";
import {getSecureText} from "../../xss/xss";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    buttons: {
        margin: theme.spacing(2)
    },
    cancelButton: {
        marginLeft: theme.spacing(1)
    }
}));

/**
 * The form for displaying and changing the profile data of the signed in user.
 *
 * @param userProfile profile (profile data) of the signed in user
 * @param setUserProfile functions for setting the profile of the signed in user (when editing a profile it is necessary to set / display the changed data)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 04.02.2020 22:04
 */
function ProfileForm({userProfile, setUserProfile}) {

    const classes = useStyles();
    const useChangePasswordForm = useForm();

    const useConfirmProfileChangeForm = useForm();

    const {signIn, changeProfile} = Call();

    const {t} = useTranslation()

    const txtFirstName = useProfileTextField(
        "first-name",
        true,
        false,
        t("profile-form-txt-first-name-init-helper-text"),
        "profile-first-name-text-field",
        t("profile-form-txt-first-name-init-label"),
        "text",
        t("profile-form-txt-first-name-init-placeholder"),
        getProfileAttribute("firstName"),
        REG_EX_FIRST_NAME);

    const txtLastName = useProfileTextField("last-name",
        false,
        false,
        t("profile-form-txt-last-name-init-helper-text"),
        "profile-last-name-text-field",
        t("profile-form-txt-last-name-init-label"),
        "text",
        t("profile-form-txt-last-name-init-placeholder"),
        getProfileAttribute("lastName"),
        REG_EX_LAST_NAME);

    const txtUsername = useProfileTextField("username",
        false,
        false,
        t("profile-form-txt-username-init-helper-text"),
        "profile-username-text-field",
        t("profile-form-txt-username-init-label"),
        "text",
        t("profile-form-txt-username-init-placeholder"),
        getProfileAttribute("username"),
        REG_EX_USERNAME);

    const txtEmail = useProfileTextField("email",
        false,
        false,
        t("profile-form-txt-email-init-helper-text"),
        "profile-email-text-field",
        t("profile-form-txt-email-init-label"),
        "email",
        t("profile-form-txt-email-init-placeholder"),
        getProfileAttribute("email"),
        REG_EX_EMAIL);

    function getProfileAttribute(profileProperty) {
        return userProfile ? getSecureText(userProfile[profileProperty]) : ""
    }

    useEffect(() => {
        setProfileDataToTextFields()
    }, [userProfile]);

    function setProfileDataToTextFields() {
        txtFirstName.setValue(getProfileAttribute('firstName'));
        txtLastName.setValue(getProfileAttribute('lastName'));
        txtUsername.setValue(getProfileAttribute('username'));
        txtEmail.setValue(getProfileAttribute('email'));
    }

    /**
     * Determine whether a user has changed any (/ at least one) item in the profile.
     * <br/>
     * <i>In order to display buttons for saving or canceling changes.</i>
     *
     * @returns {boolean} true if the user changed at least one profile item, otherwise false
     */
    function hasChangedProfile() {
        // The object does not need to be passed / loaded from the server the first time it is loaded
        if (!userProfile) {
            return false;
        }

        return areValuesDifferent(txtFirstName.value, userProfile.firstName) || areValuesDifferent(txtLastName.value, userProfile.lastName) || areValuesDifferent(txtUsername.value, userProfile.username) || areValuesDifferent(txtEmail.value, userProfile.email)
    }

    function areValuesDifferent(value1, value2) {
        return value1.trim() !== value2.trim()
    }

    /**
     * Save changes to the profile.
     * <br/>
     * <i>The values from the text fields are taken, making a request to the server to save the changes.</i>
     * <br/>
     * <i>It is necessary to call the user sign-in again, because the changes in the profile will be saved, but in the access token there are values contained in the user profile, therefore it is necessary to generate a new access token with current (changed) profile data.</i>
     *
     * @param password verified (/ valid) password of the currently signed-in user, will be used for re-sign-in to generate a new access token
     */
    function saveProfileChanges(password) {
        useConfirmProfileChangeForm.closeForm();

        const dtoIn = createChangeProfileDtoIn();
        changeProfile(dtoIn, profileDataDtoOut => {
            setUserProfile(profileDataDtoOut)
            handleSignIn(profileDataDtoOut.email, password)
        })
    }

    const createChangeProfileDtoIn = () => {
        return {
            originalUsername: getProfileAttribute('username'),
            originalEmail: getProfileAttribute('email'),
            editedUsername: txtUsername.value,
            editedEmail: txtEmail.value,
            firstName: txtFirstName.value,
            lastName: txtLastName.value
        }
    };

    /**
     * User sign-in to obtain a new (/ valid) access token and save it in Cookies with the appropriate validity.
     *
     * @param email email of currently signed-in user (potentially changed)
     * @param password the verified password of the currently signed-n user
     */
    function handleSignIn(email, password) {
        const dtoIn = {
            usernameOrEmail: email,
            password,
            rememberMe: false
        }

        signIn(dtoIn, () => {
        })
    }

    return (
        <>
            <Container component="main" maxWidth="sm">
                <ProfileTextField use={txtFirstName}/>
                <ProfileTextField use={txtLastName}/>
                <ProfileTextField use={txtUsername}/>
                <ProfileTextField use={txtEmail}/>

                <div className={classes.buttons}>
                    <Button variant={"outlined"} onClick={useChangePasswordForm.openForm}>
                        {t("profile-form-btn-change-password")}
                    </Button>
                </div>

                {hasChangedProfile() &&
                <div className={classes.buttons}>
                    <Button variant="contained" color="primary" onClick={useConfirmProfileChangeForm.openForm}>
                        {t("profile-form-btn-save-changes")}
                    </Button>
                    <Button variant="contained" color="secondary" className={classes.cancelButton}
                            onClick={setProfileDataToTextFields}>
                        {t("profile-form-btn-cancel")}
                    </Button>
                </div>}

                <ChangePasswordForm isFormOpen={useChangePasswordForm.isFormOpen}
                                    closeForm={useChangePasswordForm.closeForm}
                                    username={getProfileAttribute('username')}
                                    email={getProfileAttribute('email')}/>
            </Container>

            <ConfirmProfileChangeForm isFormOpen={useConfirmProfileChangeForm.isFormOpen}
                                      closeForm={useConfirmProfileChangeForm.closeForm}
                                      username={getProfileAttribute('username')}
                                      email={getProfileAttribute('email')}
                                      confirmProfileChange={saveProfileChanges}/>
        </>
    )
}

export default ProfileForm;
