import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AnswerList from "./AnswerList";
import QuestionOrAnswerTextOrImage from "./image/QuestionOrAnswerTextOrImage";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForever from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useForm from "../../../form/useForm";
import ConfirmDeleteAnswerForm from "./form/ConfirmDeleteAnswerForm";
import Call from "../../../server/Call";
import CreateEditAnswerForm from "./form/CreateEditAnswerForm";
import Button from '@material-ui/core/Button';
import ConfirmDeleteQuestionAnswers from "./form/ConfirmDeleteQuestionAnswers";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    },
    questionItem: {
        textAlign: 'center'
    }
}));

/**
 * Representation of one specific question in the list of all questions shown.
 *
 * @param question the question that this component should represent / display
 * @param deleteQuestion delete question function
 * @param openEditQuestionForm the function for opening the form for editing the affirmative question above in the "question" parameter
 * @param editQuestion function for setting (/ saving) correct answer in database
 * @param setQuestionsStatusInfo functions to find out information about the status of questions in the database (whether they meet the conditions for playing the game)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 15.09.2019 20:14
 */

function QuestionListItem({question, deleteQuestion, openEditQuestionForm, editQuestion, setQuestionsStatusInfo}) {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const {deleteAnswerById, deleteQuestionAnswers} = Call();

    const useDeleteAnswersToQuestionConfirmForm = useForm();

    const useDeleteAnswerConfirmForm = useForm();
    const [answerToDelete, setAnswerToDelete] = useState(undefined);

    const useCreateEditAnswerForm = useForm();
    const [answerFormTitle, setAnswerFormTitle] = useState('');
    const [answerForEdit, setAnswerForEdit] = useState(undefined);

    const [questionAnswerValidationIcon, setQuestionAnswerValidationIcon] = useState(<QuestionAnswerIcon/>);

    const {addAnswer, updateAnswer, setCorrectAnswer, getQuestionAnswerValidationInformation} = Call();

    // User-defined new correct answer id
    const [correctAnswerId, setCorrectAnswerId] = useState(findCorrectAnswerId());
    // true if editing the correct answer, otherwise false
    const [changeCorrectAnswer, setChangeCorrectAnswer] = useState(false);

    const {t} = useTranslation()

    /**
     * Find the id of the currently set correct answer.
     *
     * @returns {undefined|*} undefined if no correct answer is found, otherwise id the correct answer to the question
     */
    function findCorrectAnswerId() {
        if (!question.answers) {
            return undefined;
        }
        const correctAnswer = question.answers.find(answer => answer.correct);
        return correctAnswer ? correctAnswer.id : undefined;
    }

    /**
     * Each time user change a question that is being rendered in this component, set the correct answer id to display it in Checkbox.
     */
    useEffect(() => {
        setCorrectAnswerId(findCorrectAnswerId());
    }, [question]);

    /**
     * If editing the correct answer is enabled, the user can mark any correct answer.
     *
     * @param answerId ID of the answer that the user has marked as correct
     */
    function selectCorrectAnswer(answerId) {
        if (changeCorrectAnswer) {
            setCorrectAnswerId(answerId);
        }
    }

    /**
     * Whether the answerId is the answer id that is marked as correct.
     *
     * @param answerId the answer id to determine if this is the user-defined correct answer
     * @returns {boolean|boolean} true if answerId is id of the correct answer, otherwise false
     */
    function isCorrectAnswer(answerId) {
        return Boolean(correctAnswerId && correctAnswerId === answerId);
    }

    /**
     * Create an object to change the correct answer in the database. Returns the entire question with correctly set answers, which will be displayed in the application.
     */
    function saveCorrectAnswer() {
        const correctAnswerDtoIn = {
            answerId: correctAnswerId
        };

        setCorrectAnswer(correctAnswerDtoIn, questionWithAnswersResponse => {
            setChangeCorrectAnswer(false);
            editQuestion(questionWithAnswersResponse);
            handleQuestionAnswerValidationInformation();
            setQuestionsStatusInfo();
        })
    }

    /**
     * Set the original correct answer without making any changes.
     * <br/>
     * <i>The user could mark the Checkbox - the correct answer with another, but he thought it was not, so the original correct answer will be returned (ie the changes made will not be applied).</i>
     */
    function stopEditingCorrectAnswer() {
        // Finish editing the correct answer
        setChangeCorrectAnswer(false);
        // Return the original original correct answer, or undefined if the correct answer is not present
        setCorrectAnswerId(findCorrectAnswerId());
    }

    useEffect(() => {
        if (answerToDelete) {
            useDeleteAnswerConfirmForm.openForm();
        }
    }, [answerToDelete]);

    // Only the first time a component with a specific question is displayed, the question validation will be retrieved and an icon or tooltip with information will be displayed accordingly
    useEffect(() => {
        if (question) {
            handleQuestionAnswerValidationInformation();
        }
    }, [question]);

    function openDeleteAnswerForm(answer) {
        setAnswerToDelete(answer);
    }

    function closeDeleteAnswerForm() {
        setAnswerToDelete(undefined);
        useDeleteAnswerConfirmForm.closeForm();
    }

    function deleteAnswer() {
        deleteAnswerById(answerToDelete.id, () => {
            question.answers = question.answers.filter(answer => {
                return answer.id !== answerToDelete.id;
            });
            handleQuestionAnswerValidationInformation();
            setQuestionsStatusInfo();
        });

        useDeleteAnswerConfirmForm.closeForm();
    }

    function handleClick() {
        setOpen(!open);
    }

    function createAnswer(answer, clearFields) {
        addAnswer(answer, answerResponse => {
            if (!question.answers) {
                question.answers = [];
            }
            question.answers.push(answerResponse);
            closeCreateEditAnswerForm(clearFields);
            handleQuestionAnswerValidationInformation();
            setQuestionsStatusInfo();
        })
    }

    function editAnswer(answer, clearFields) {
        updateAnswer(answer, answerResponse => {
            const answerIndex = question.answers.findIndex(a => a.id === answerResponse.id);
            question.answers[answerIndex] = answerResponse;
            closeCreateEditAnswerForm(clearFields)
        })
    }

    function openCreateAnswerForm() {
        setAnswerForEdit(undefined);
        setAnswerFormTitle(t("question-list-item-create-answer-form-title"));
        useCreateEditAnswerForm.openForm();
    }

    function openEditAnswerForm(answer) {
        setAnswerForEdit(answer);
        setAnswerFormTitle(t("question-list-item-edit-answer-form-title"));
        useCreateEditAnswerForm.openForm();
    }

    function closeCreateEditAnswerForm(clearFields) {
        clearFields();
        setAnswerForEdit(undefined);
        useCreateEditAnswerForm.closeForm();
    }

    function getEditCorrectAnswerButtons() {
        if (changeCorrectAnswer) {
            return <>
                <Tooltip title={t("question-list-item-btn-tooltip-save-changed-correct-answer")}>
                    <Button variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={saveCorrectAnswer}
                    >
                        {t("question-list-item-btn-save-correct-answer")}
                    </Button>
                </Tooltip>
                <Tooltip title={t("question-list-item-btn-tooltip-return-original-correct-answer")}>
                    <Button variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={stopEditingCorrectAnswer}
                    >
                        {t("question-list-item-btn-cancel-change-correct-answer")}
                    </Button>
                </Tooltip>
            </>
        }

        return <Tooltip title={t("question-list-item-btn-tooltip-change-correct-answer")}>
            <Button variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setChangeCorrectAnswer(true)}
            >
                {t("question-list-item-btn-change-correct-answer")}
            </Button>
        </Tooltip>
    }

    /**
     * A final request to BE to find out if the question is valid.
     * <br/>
     * <i>If the question is not valid, an exclamation mark icon will be displayed next to the question and a description of the error will appear in the Tooltip.</i>
     */
    function handleQuestionAnswerValidationInformation() {
        const dtoIn = {
            questionId: question.id
        };

        getQuestionAnswerValidationInformation(dtoIn, validationInformation => {
            if (validationInformation.valid) {
                setQuestionAnswerValidationIcon(
                    getQuestionItemIcon(
                        t("question-list-item-question-answer-validation-information-tooltip"),
                        <QuestionAnswerIcon/>
                    ));
            } else {
                setQuestionAnswerValidationIcon(
                    getQuestionItemIcon(validationInformation.message, <ErrorIcon color={"error"}/>)
                );
            }
        })
    }

    function getQuestionItemIcon(tooltipTitle, icon) {
        return <Tooltip title={tooltipTitle}>
            {icon}
        </Tooltip>
    }

    /**
     * Opens a dialog to confirm the deletion of answers to the selected question.
     */
    function openDeleteQuestionAnswersForm() {
        useDeleteAnswersToQuestionConfirmForm.openForm();
    }

    /**
     * Delete all the answers to the question.
     * <br/>
     * <i>Next, the status of questions in the database is populated to inform the administrator that the game cannot be played because there will be no questions in the database.</i>
     */
    function deleteAnswersToQuestion() {
        deleteQuestionAnswers(question.id, () => {
            handleQuestionAnswerValidationInformation();
            question.answers = [];
            setQuestionsStatusInfo();
        });

        useDeleteAnswersToQuestionConfirmForm.closeForm();
    }

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    {questionAnswerValidationIcon}
                </ListItemIcon>
                <ListItemText
                    className={classes.questionItem}
                    primary={
                        <QuestionOrAnswerTextOrImage
                            questionOrAnswer={question}
                            isQuestion={true}/>
                    }/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <div>
                    <Tooltip title={t("question-list-item-btn-create-new-answer")}>
                        <IconButton className={classes.button}
                                    aria-label="create answer"
                                    color="primary"
                                    size="medium"
                                    onClick={openCreateAnswerForm}>
                            <AddCircleIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("question-list-item-btn-edit-question")}>
                        <IconButton className={classes.button}
                                    aria-label="edit question"
                                    color="primary"
                                    onClick={openEditQuestionForm}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t("question-list-item-btn-delete-question")}>
                        <IconButton className={classes.button}
                                    aria-label="delete question"
                                    color="secondary"
                                    onClick={deleteQuestion}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                    {question.answers && question.answers.length > 0 &&
                    <Tooltip title={t("question-list-item-btn-delete-all-questions")}>
                        <IconButton className={classes.button}
                                    aria-label="delete all answers"
                                    color="secondary"
                                    onClick={openDeleteQuestionAnswersForm}>
                            <DeleteForever/>
                        </IconButton>
                    </Tooltip>}
                </div>
                <AnswerList answers={question.answers}
                            editAnswer={openEditAnswerForm}
                            deleteAnswer={openDeleteAnswerForm}
                            selectCorrectAnswer={selectCorrectAnswer}
                            isCorrectAnswer={isCorrectAnswer}
                />
                {question.answers && question.answers.length > 0 && getEditCorrectAnswerButtons()}
            </Collapse>

            <CreateEditAnswerForm
                isFormOpen={useCreateEditAnswerForm.isFormOpen}
                closeForm={closeCreateEditAnswerForm}
                formTitle={answerFormTitle}
                answerForEdit={answerForEdit}
                createAnswer={createAnswer}
                editAnswer={editAnswer}
                questionId={question.id}
            />

            <ConfirmDeleteAnswerForm
                isFormOpen={useDeleteAnswerConfirmForm.isFormOpen}
                closeForm={closeDeleteAnswerForm}
                answer={answerToDelete}
                deleteAnswer={deleteAnswer}
            />

            <ConfirmDeleteQuestionAnswers
                isFormOpen={useDeleteAnswersToQuestionConfirmForm.isFormOpen}
                closeForm={useDeleteAnswersToQuestionConfirmForm.closeForm}
                question={question}
                deleteAnswersToQuestion={deleteAnswersToQuestion}
            />
        </>
    )
}

export default QuestionListItem;
