import React from 'react';
import {V1, V2, V3, V4} from "./constant";
import {useTranslation} from "react-i18next";

/**
 * Displays the tooltip for the desired column in the chart.
 *
 * @author Jan Krunčík
 * @since 10.07.2020 12:02
 */

function MixColumnChartTooltip(props) {

    const {t} = useTranslation()

    function getPayloadValue(payload, index) {
        return payload && " (" + payload[index].value + ")"
    }

    /**
     * Get a label for a specific column and its part (index = part of one column).
     *
     * @param label the name of the column for which the label is to be obtained
     * @param columnIndex the index of the column to get the label, it is basically a part of the column, a specific v(x) value
     * @param payload field with values in the chart for a specific column and its part
     * @returns {string} label for a specific part of the column in the chart
     */
    function getColumnIntro(label, columnIndex, payload) {
        if (label === t("questions")) {
            if (columnIndex === V1) {
                return t("mix-column-chart-v1-questions-given-by-text") + getPayloadValue(payload, 0)
            } else if (columnIndex === V2) {
                return t("mix-column-chart-v2-questions-given-by-image") + getPayloadValue(payload, 1)
            } else if (columnIndex === V3) {
                return t("mix-column-chart-v3-valid-questions-count") + getPayloadValue(payload, 2)
            } else return t("mix-column-chart-v4-invalid-questions-count") + getPayloadValue(payload, 3)
        } else if (label === t("answers")) {
            if (columnIndex === V1) {
                return t("mix-column-chart-v1-answers-given-by-text") + getPayloadValue(payload, 0)
            } else if (columnIndex === V3) {
                return t("mix-column-chart-v3-answers-given-by-image") + getPayloadValue(payload, 1)
            }
        } else if (label === t("users")) {
            if (columnIndex === V1) {
                return t("mix-column-chart-v1-players-in-db-count") + getPayloadValue(payload, 0)
            } else if (columnIndex === V3) {
                return t("mix-column-chart-v3-registered-users-in-db") + getPayloadValue(payload, 1)
            }
        } else if (label === t("played-games")) {
            if (columnIndex === V1) {
                return t("mix-column-chart-v1-played-games-count") + getPayloadValue(payload, 0);
            }
        }
        return "";
    }

    /**
     * Get a description for a column by label
     *
     * @param label the name of the column for which the label is to be obtained
     * @returns {string} label for the required column
     */
    function getColumnDescription(label) {
        if (label === t("questions")) {
            return t("questions-overview-in-db");
        } else if (label === t("answers")) {
            return t("answers-overview-in-db");
        } else if (label === t("users")) {
            return t("users-overview-in-db");
        } else if (label === t("played-games")) {
            return t("played-games-overview-in-db");
        }
        return t("unknown-column");
    }

    function getColumnTooltip() {
        const {active} = props;

        if (active) {
            const {payload, label} = props;
            return (
                <div className="custom-tooltip">
                    <p className="desc">{getColumnDescription(label)}</p>
                    <p className="intro">{getColumnIntro(label, V1, payload)}</p>
                    <p className="intro">{getColumnIntro(label, V2, payload)}</p>
                    <p className="intro">{getColumnIntro(label, V3, payload)}</p>
                    <p className="intro">{getColumnIntro(label, V4, payload)}</p>
                </div>
            )
        }

        return <div/>
    }

    return (
        getColumnTooltip()
    )
}

export default MixColumnChartTooltip;
