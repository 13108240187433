import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FaceIcon from "@material-ui/icons/Face";
import {MDBIcon} from "mdbreact";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {FILTER_BY_EMAIL} from "../../player/table/filterProperties";
import {FILTER_BY_FIRST_NAME, FILTER_BY_LAST_NAME, FILTER_BY_USERNAME} from "./filterProperties";
import {useTranslation} from "react-i18next";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

export const FIRST_NAME_ICON = <MDBIcon icon="id-badge"/>
const LAST_NAME_ICON = <MDBIcon icon="id-card"/>
const USERNAME_ICON = <FaceIcon/>
const EMAIL_ICON = <MDBIcon icon="at"/>

/**
 * Drop-down menu with items for selecting the column according to which the items (/ users) are to be filtered / searched.
 *
 * @param anchorEl settings for the menu, whether it should be open or not
 * @param closeMenu close menu function
 * @param handleFilterBy function to set values for filtering items
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 16.07.2020 23:45
 */

function FilterMenu({anchorEl, closeMenu, handleFilterBy}) {

    const {t} = useTranslation()

    const TXT_FILTER_BY_FIRST_NAME = t("users-table-filter-menu-txt-filter-by-first-name");
    const TXT_FILTER_BY_LAST_NAME = t("users-table-filter-menu-txt-filter-by-last-name");
    const TXT_FILTER_BY_USERNAME = t("users-table-filter-menu-txt-filter-by-username");
    const TXT_FILTER_BY_EMAIL = t("users-table-filter-menu-txt-filter-by-email");

    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
        >
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_FIRST_NAME, TXT_FILTER_BY_FIRST_NAME, FIRST_NAME_ICON)}>
                <ListItemIcon>
                    {FIRST_NAME_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_FIRST_NAME}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_LAST_NAME, TXT_FILTER_BY_LAST_NAME, LAST_NAME_ICON)}>
                <ListItemIcon>
                    {LAST_NAME_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_LAST_NAME}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_USERNAME, TXT_FILTER_BY_USERNAME, USERNAME_ICON)}>
                <ListItemIcon>
                    {USERNAME_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_USERNAME}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_EMAIL, TXT_FILTER_BY_EMAIL, EMAIL_ICON)}>
                <ListItemIcon>
                    {EMAIL_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_EMAIL}/>
            </StyledMenuItem>
        </StyledMenu>
    )
}

export default FilterMenu;
