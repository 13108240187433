import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import QuestionListItem from "./QuestionListItem";
import QuestionPagination from "./pagination/QuestionPagination";
import {QuestionPaginationContext} from "./pagination/QuestionPaginationContext";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        width: '100%',
        backgroundColor: theme.palette.background.paper
    }
}));

/**
 * Representation of a list of questions retrieved from the database on the selected page with the selected number of questions (items).
 *
 * @param questions loaded questions on the selected page to display
 * @param questionCount total number of questions in the database - it is needed to set the paging (go to pages, go to last | first page etc.)
 * @param deleteQuestion delete question function
 * @param openEditQuestionForm function to open the dialog for editing the selected question
 * @param setQuestionsStatusInfo functions to find out information about the status of questions in the database (whether they meet the conditions for playing the game)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 15.09.2019 20:11
 */
function QuestionsOverview({questions, questionCount, deleteQuestion, openEditQuestionForm, setQuestionsStatusInfo}) {

    const classes = useStyles();

    const questionPaginationContext = useContext(QuestionPaginationContext);

    const {t} = useTranslation()

    function handleChangePage(event, newPage) {
        questionPaginationContext.setCurrentPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        questionPaginationContext.setQuestionsPerPage(+event.target.value);
        questionPaginationContext.setCurrentPage(0);
    }

    /**
     * Save the edited question (its correct answer) to the questions array to show the user the changes.
     *
     * @param editedQuestion question that contains the edited correct answer (another answer has been marked as correct)
     */
    function editQuestion(editedQuestion) {
        const questionIndex = questions.findIndex(q => q.id === editedQuestion.id);
        questions[questionIndex] = editedQuestion;
    }

    /**
     * Return the rendering component.
     *
     * @returns {*} if there are no questions in the questions parameter, it returns information that no questions were found, otherwise an object with rendered questions retrieved from the database is returned
     */
    function getComponentToRender() {
        if (questions.length === 0) {
            return <div>{t("questions-overview-no-questions-found-in-db")}</div>
        }

        return <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        {t("questions-overview-all-questions-in-db")}
                    </ListSubheader>
                }
                className={classes.root}
            >
                {questions.map((question, index) => {
                    return <QuestionListItem key={index} question={question}
                                             deleteQuestion={() => deleteQuestion(question)}
                                             openEditQuestionForm={() => openEditQuestionForm(question)}
                                             editQuestion={editQuestion}
                                             setQuestionsStatusInfo={setQuestionsStatusInfo}/>
                })}
            </List>

            <QuestionPagination
                length={questionCount}
                questionsPerPage={questionPaginationContext.questionsPerPage}
                currentPage={questionPaginationContext.currentPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    }

    return getComponentToRender()
}

export default QuestionsOverview;
