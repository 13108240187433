import React from 'react';
import List from "@material-ui/core/List";
import QuizResultAnswerListItem from "./QuizResultAnswerListItem";
import {useTranslation} from "react-i18next";

/**
 * Display a list with answers to a player's guessing question.
 *
 * @param answers the answers available to the question
 * @param selectedAnswerInfo information about the answer to the question, which answer the player chose and which answer is correct
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 29.01.2020 18:01
 */

function QuizResultAnswerList({answers, selectedAnswerInfo}) {

    const {t} = useTranslation()

    return (
        <List component="div" disablePadding>
            {answers && answers.length > 0
                ? answers.map(answer => {
                    return <QuizResultAnswerListItem
                        key={answer.id}
                        answer={answer}
                        selectedAnswerInfo={selectedAnswerInfo}/>
                })
                : <div>{t("quiz-result-answer-list-no-answer-specified")}</div>}
        </List>
    )
}

export default QuizResultAnswerList;
