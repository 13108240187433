import React from 'react';
import PropTypes from "prop-types"
import {useTranslation} from "react-i18next"

/**
 * Displays the progress bar, which represents the remaining time that a player can earn bonus points for answering a question.
 *
 * @param progressBarBgColor the color of the progress bar will be a different color according to the remaining time
 * @param completed percentage value of time remaining (in the interval 0 - 100)
 * @param time time in seconds to be displayed on the bar
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 17.07.2020 23:15
 */

function ProgressBar({progressBarBgColor, completed, time}) {

    const {t} = useTranslation()

    const containerStyles = {
        height: 35,
        backgroundColor: "#e0e0de",
        borderRadius: 5
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: progressBarBgColor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
        padding: 1
    }

    const labelStyles = {
        float: "right",
        color: 'black',
        fontWeight: 'bold',
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        marginRight: 5
    }

    const remainingTime = {
        float: "left",
        color: 'black',
        fontWeight: 'bold',
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        margin: 5
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                {time >= 8 && <span style={remainingTime}>{t("progress-bar-txt-remaining-time")}</span>}
                <span style={labelStyles}>{time}</span>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    progressBarBgColor: PropTypes.string.isRequired,
    completed: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired
}

export default ProgressBar;
