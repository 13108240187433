import React from 'react';
import PropTypes from "prop-types"
import PlayerGamesTable from "./table/PlayerGamesTable"
import {PLAYER_EMAIL, PLAYER_USERNAME} from "../property/Properties"
import {REG_EX_EMAIL, REG_EX_USERNAME} from "../../auth/properties/form"
import {makeStyles} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import useSessionStorage from "../../session-storage/useSessionStorage"

const useStyles = makeStyles(() => ({
    divInvalidPlayerCredentials: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold"
    }
}))

/**
 * Render of a table with information about the games played by a particular player.
 *
 * @param lastPlayedGame data on the played (/ saved) game of the respective player for which the result is to be displayed
 * @returns {*} the table described above with an overview of the games played or information that the player data could not be obtained
 * @constructor
 *
 * @author Jan Krunčík
 * @since 02.02.2020 0:13
 */
function PlayerGames({lastPlayedGame}) {

    const classes = useStyles()

    const {t} = useTranslation()

    const {getItem} = useSessionStorage()

    function getComponentToRender() {
        const username = getItem(PLAYER_USERNAME)
        const email = getItem(PLAYER_EMAIL)

        // The existence of the player could still be tested, but the dialog is displayed immediately after the end of the game, when the existence of the player is already checked (or the player is created), so only the data from the sessionStorage is checked here
        if (username && username.match(REG_EX_USERNAME) && email && email.match(REG_EX_EMAIL)) {
            return (
                <PlayerGamesTable
                    lastPlayedGame={lastPlayedGame}
                />
            )
        }

        return (
            <div className={classes.divInvalidPlayerCredentials}>
                {t("player-games-invalid-player-credentials-message")}
            </div>
        )
    }

    return getComponentToRender()
}

PlayerGames.propTypes = {
    lastPlayedGame: PropTypes.shape({
        id: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
        lives: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired
    }).isRequired
}

export default PlayerGames;
