import React, {useState} from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import FilterMenu, {USERNAME_ICON} from "./FilterMenu";
import {PlayerToolbarContext} from "../context/PlayerToolbarContext";
import {FILTER_BY_USERNAME} from "../../../quiz/score/filterProperties";
import {REG_EX_FILTER_USERNAME} from "../../../quiz/property/Properties";
import {FILTER_BY_EMAIL, REG_EX_CHARACTERS_IN_EMAIL} from "./filterProperties";
import {useTranslation} from "react-i18next";

const useSearchTextFieldStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: '0'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    }
}));

/**
 * Toolbar with text box to filter certain values (/ players).
 * <br/>
 * Toolbar source: https://material-ui.com/components/tables/#sorting-amp-selecting
 * <br/>
 * Search text field source: https://material-ui.com/components/text-fields/#customized-inputs
 * <br/>
 * Pictures / Icons with arrows from bootstrap:
 * - https://www.npmjs.com/package/mdbreact
 * - https://mdbootstrap.com/docs/react/content/icons-list/
 *
 * @author Jan Krunčík
 * @since 11.06.2020 23:41
 */

function PlayerTableToolbar() {

    const classes = useSearchTextFieldStyles(() => {
    });

    const playerToolbarContext = React.useContext(PlayerToolbarContext);

    const {t} = useTranslation()

    // An icon representing the column by which the data will be filtered
    const [filterByIcon, setFilterByIcon] = useState(USERNAME_ICON);
    const [filterByText, setFilterByText] = useState(t("player-filter-menu-filter-by-username"));

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleOpenMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleCloseMenu() {
        setAnchorEl(null);
    }

    function checkEmptyValue(text) {
        return text.replace(/\s/, '') === ""
    }

    /**
     * Set a value in the text box with the value by which items (/ players) will be filtered.
     * <br/>
     * <i>User will only be able to enter a value in the text field if it meets the syntax of the regular expression.
     *
     * @param event the event of pressing a key (entering a value) in the text field, to get the entered value
     */
    function handleChange(event) {
        const {value} = event.target;

        if (playerToolbarContext.filterBy === FILTER_BY_USERNAME) {
            if (checkEmptyValue(value) || REG_EX_FILTER_USERNAME.test(value)) {
                playerToolbarContext.setFilter(value);
            }
        } else {
            // Filter by email
            if (checkEmptyValue(value) || REG_EX_CHARACTERS_IN_EMAIL.test(value)) {
                playerToolbarContext.setFilter(value);
            }
        }
    }

    /**
     * If the expression to be filtered by the players matches the required regular expression, the players are searched.
     */
    function searchPlayers() {
        if ((playerToolbarContext.filterBy === FILTER_BY_USERNAME && !REG_EX_FILTER_USERNAME.test(playerToolbarContext.filter)) ||
            (playerToolbarContext.filterBy === FILTER_BY_EMAIL && !REG_EX_CHARACTERS_IN_EMAIL.test(playerToolbarContext.filter))) {
            return;
        }

        playerToolbarContext.loadPlayerList()
    }

    /**
     * Set values for filtering by a specific column.
     * <br/>
     * <i>For example, a user chose to filter items that contain a username, so sets the appropriate icon next to the filter text field, a placeholder that informs the user what value to filter, closes the menu (user has selected a filter), and empties text field for re-entering the filtering value.</i>
     *
     * @param filterBy the column by which the values will be filtered
     * @param selectedFilterByText text for placeholder text box
     * @param selectedFilterByIcon icon representing the selected item in the filter menu
     */
    function handleFilterBy(filterBy, selectedFilterByText, selectedFilterByIcon) {
        playerToolbarContext.setFilterBy(filterBy);
        playerToolbarContext.setFilter("");
        setFilterByIcon(selectedFilterByIcon);
        setFilterByText(selectedFilterByText);
        setAnchorEl(null);
    }

    return (
        <Toolbar className={classes.root}>
            <IconButton className={classes.iconButton}
                        aria-label="menu"
                        onClick={handleOpenMenu}>
                {filterByIcon}
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder={filterByText}
                inputProps={{'aria-label': filterByText}}
                value={playerToolbarContext.filter}
                onChange={handleChange}
            />
            <IconButton type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={searchPlayers}>
                <SearchIcon/>
            </IconButton>

            <FilterMenu anchorEl={anchorEl}
                        closeMenu={handleCloseMenu}
                        handleFilterBy={handleFilterBy}/>
        </Toolbar>
    )
}

export default PlayerTableToolbar;
