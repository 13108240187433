import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {ASC, DESC} from "./TableProperties";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const RANK_ID = 'rank';
const PLAYER_USERNAME_ID = 'username';

const HEAD_CELLS = [
    {id: RANK_ID, numeric: false, disablePadding: true, labelTranslationText: 'players-score-enhanced-table-head-rank'},
    {
        id: PLAYER_USERNAME_ID,
        numeric: false,
        disablePadding: true,
        labelTranslationText: 'players-score-enhanced-table-head-username'
    },
    {
        id: 'score',
        numeric: true,
        disablePadding: false,
        labelTranslationText: 'players-score-enhanced-table-head-score'
    },
    {
        id: 'time',
        numeric: true,
        disablePadding: false,
        labelTranslationText: 'players-score-enhanced-table-head-total-game-time'
    }
];

/**
 * Table title, resp. first row of the table with column headings.
 * <br/>
 * <i>Contains options for sorting values by columns with score and time, but not by the username. Since only single player results are shown in the table, there is no reason to sort items by username when only one username is available, namely the player who played the game and his games / game results will be displayed in the table.</i>
 *
 * @author Jan Krunčík
 * @since 03.02.2020 11:19
 */
function EnhancedTableHead(props) {

    const {t} = useTranslation()

    const classes = useStyles(() => {
    });

    const {order, orderBy, onRequestSort} = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {HEAD_CELLS.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id !== PLAYER_USERNAME_ID && headCell.id !== RANK_ID ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : ASC}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.labelTranslationText)}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                  {order === DESC ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                            : t(headCell.labelTranslationText)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf([ASC, DESC]).isRequired,
    orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
