import {useEffect, useState} from 'react';

/**
 * "Auxiliary object" to which the data needed to display the text field in the required form and with the required functions (text validation, alignment, error display ...) is passed.
 *
 * @param initAutoComplete set automatic text completion according to the type of value in the text field
 * @param initAutoFocus true if the cursor will be in the field that is to be displayed in the dialog where the text field is located at the first view, otherwise false
 * @param initError true if the text field is to be highlighted as containing error text, otherwise false
 * @param initHelperText informational text for the user to be displayed in case of invalid value in the text field
 * @param initId component id
 * @param initLabel text / "title" text field
 * @param initType the value type in the text field
 * @param initPlaceholder text displayed in the text field if the text field is empty (information for the user what kind of value to enter in the field)
 * @param initValue the default value in the text box
 * @param initRexEx regular expression to validate values in a text field
 * @param initDisabled true if the text field is to be inaccessible to editing, otherwise false
 * @returns {{autoComplete: string, handleChange: handleChange, disabled: boolean, autoFocus: boolean, id: string, label: string, placeholder: string, error: boolean, type: string, helperText: string, value: string, validate: validate}}
 *
 * @author Jan Krunčík
 * @since 04.02.2020 22:05
 */
function useProfileTextField(initAutoComplete = "text",
                             initAutoFocus = false,
                             initError = false,
                             initHelperText = "",
                             initId = "profile-text-field",
                             initLabel = "",
                             initType = "text",
                             initPlaceholder = "",
                             initValue = "",
                             initRexEx,
                             initDisabled = false) {

    const [autoComplete] = useState(initAutoComplete);
    const [autoFocus] = useState(initAutoFocus);
    const [error, setError] = useState(initError);
    const [helperText, setHelperText] = useState(initHelperText);
    const [id] = useState(initId);
    const [label] = useState(initLabel);
    const [type] = useState(initType);
    const [placeholder] = useState(initPlaceholder);
    const [value, setValue] = useState(initValue);
    const [regEx] = useState(initRexEx);
    const [disabled] = useState(initDisabled);

    function handleChange(e) {
        setValue(e.target.value);
    }

    useEffect(() => {
        if (validate()) {
            setError(false);
            setHelperText("")
        } else {
            setError(true);
            setHelperText(initHelperText)
        }
    }, [value]);

    function validate() {
        // If no regular expression is given, the text is returned valid, otherwise a text field with an invalid value would be displayed
        if (regEx === undefined) {
            return true;
        }
        return regEx.test(value);
    }

    return {
        autoComplete,
        autoFocus,
        error,
        helperText,
        id,
        label,
        type,
        placeholder,
        value,
        setValue,
        disabled,
        handleChange,
        validate
    }
}

export default useProfileTextField;
