import {useState} from 'react';

/**
 * To save and work with the user's score.
 *
 * @author Jan Krunčík
 * @since 25.08.2019 19:26
 */

function Score(initialValue) {

    const [value, setValue] = useState(initialValue);

    function increaseScore() {
        setValue(value + 1);
    }

    function setScoreValue(score) {
        setValue(score);
    }

    return {
        value,
        increaseScore,
        setScoreValue
    };
}

export default Score;