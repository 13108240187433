import React, {useContext, useEffect, useState} from 'react';
import Score from "./use/Score";
import Live from "./use/Live";
import GameOverForm from "./form/GameOverForm";
import useForm from "../form/useForm";
import Timer from "./timer/Timer";
import Call from "../server/Call";
import CountdownTimer from "./timer/CountdownTimer";
import QuizResult from "./result/QuizResult";
import {REG_EX_EMAIL, REG_EX_USERNAME} from "../auth/properties/form";
import GameContent from "./content/GameContent";
import {GameContext} from "./context/GameContext";
import {
    DEFAULT_LIVES,
    DEFAULT_QUESTION_INDEX,
    DEFAULT_SCORE,
    PLAYER_EMAIL,
    PLAYER_USERNAME
} from "./property/Properties";
import {useMountEffect} from "../mount/useMountEffect";
import {QuizContext} from "./context/QuizContext";
import {QUESTION_STATUS_LOADING} from "./content/quiz/properties";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../snackbar/Settings";
import {WARNING} from "../snackbar/Variant";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom"
import SaveGameAskDialog from "./form/SaveGameAskDialog"
import SignInAndRegisterPlayerDialog from "./form/SignInAndRegisterPlayerDialog";
import useSessionStorage from "../session-storage/useSessionStorage";

const useStyles = makeStyles(() => ({
    root: {
        margin: "auto",
        maxWidth: "95%"
    }
}))

/**
 * Component with quiz items (root component of game representation).
 * <br/>
 * <i>From here all components belonging to the quiz game.</i>
 *
 * @param setContent setting the content to render on the main / home page (in this case, either rendering the components for playing the game or displaying the results of the game played)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 25.08.2019 15:07
 */
function Quiz({setContent}) {

    const quizContext = useContext(QuizContext);

    const classes = useStyles()

    const history = useHistory()

    const {getItem} = useSessionStorage()

    // Whether to open a dialog asking to save the game when going to another page while the game is running
    const [isSaveGameAskDialogOpen, setIsSaveGameAskDialogOpen] = useState(false)
    // Saving a new url to which the user wants to go while the game is running, after a request to save the game will be answered, the user will be redirected to this desired page
    const [tmpNewPage, setTmpNewPage] = useState("")

    // Save the history unblock function to go to another page
    // When starting the game, the history will be blocked, if the user tries to go to another page, he will be asked to save the game, then the transition to another page will be allowed
    const [unblockHistory, setUnblockHistory] = useState(() => {
    })

    const [questionIndex, setQuestionIndex] = useState(-1);
    const [isGameRunning, setIsGameRunning] = useState(false);
    const useGameOverForm = useForm();
    const useSignAndRegisterPlayerDialog = useForm();
    const [questions, setQuestions] = useState([]);
    const score = Score(DEFAULT_SCORE);
    const lives = Live(DEFAULT_LIVES);
    const gameTimer = Timer();
    const questionCountdown = CountdownTimer();
    const {existPlayer, generateQuestions, getQuestionsStatus, addGame} = Call();
    const [confirmOperation, setConfirmOperation] = useState(() => startGame);

    // To recognize whether the correct answer has been shown to the player and it is possible to end the game (win or lose)
    // It will be set to true after the interval for displaying the correct answer expires
    const [answerWasDisplayed, setAnswerWasDisplayed] = useState(false)

    // The last game played, about which the data will be displayed in the GameOver dialog after the end of the game
    const [lastPlayedGame, setLastPlayedGame] = useState({id: -1, time: -1, lives: -1, score: -1});

    // Information about the id of the correct answer and the user-selected answer id while playing the game, it is needed to view the results of the game
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    const [isQuestionsStatusReady, setIsQuestionsStatusReady] = useState(QUESTION_STATUS_LOADING);

    /**
     * Get an current question that it's up to the player to answer it.
     *
     * @returns {*} current questions to be displayed to the player for guessing / answering
     */
    function getQuestionToAsk() {
        if (isGameRunning && questionIndex > -1 && questionIndex < questions.length) {
            return questions[questionIndex]
        }
        return {
            id: -1,
            givenByImage: false,
            text: "",
            answers: []
        }
    }

    // Incrementing the index of the guessing question, after setting the value, the component with the new / next question is redrawn
    function nextQuestion() {
        quizContext.useAskQuestionSound.play() // Play sound for the newly generated question
        setQuestionIndex(questionIndex + 1);
    }

    // If a player goes through all the questions or run out of lives, the game is over
    // It is necessary to "watch" these two values separately (two useEffects), otherwise the "stopGame" function would be called twice
    useEffect(() => {
        // If there are no more questions the game is over
        if (questionIndex >= questions.length) {
            stopGame();
        }
    }, [questionIndex, questions.length]);
    useEffect(() => {
        // If a player runs out of lives (lives.value) and has been shown the correct answer (the result of the last incorrectly answered question) (answerWasDisplayed), the game will end
        if (lives.value === 0 && answerWasDisplayed) {
            stopGame();
        }
    }, [lives.value, answerWasDisplayed]);

    // If the the variable 'questions' is changed, the game will start (or stop) (questions generated)
    useEffect(() => {
        if (questions.length === 0) {
            gameTimer.stopTimer();
            questionCountdown.stopCountdown();
            setIsGameRunning(false);
            return;
        }

        gameTimer.startTimer();
        questionCountdown.startCountdown();
        setQuestionIndex(DEFAULT_QUESTION_INDEX);
        setIsGameRunning(true);
        score.setScoreValue(DEFAULT_SCORE);
        lives.setLives(DEFAULT_LIVES);
        setSelectedAnswers([]);
    }, [questions]);

    /**
     * The first time the page is loaded, the status (validation) of the questions is determined, if the conditions for playing the game are met, the components are prepared for this, otherwise a message will be displayed that the game cannot be played.
     */
    useMountEffect(() => loadQuestionsStatusAndSetGameComponents())

    // As long as the game is running, the default background sound for / while playing the game will also run
    // There is need to react to changes in the 'areSoundsOn' variable, when sounds are turned on, application need to play a background sound (/ if it's not running)
    useEffect(() => {
        if (!quizContext.useBgSound.isPlaying() && isGameRunning) {
            quizContext.useBgSound.play()
        }
    }, [isGameRunning, quizContext.areSoundsOn])

    /**
     * When the game starts, a listener is set up to respond to whether the user refresh the page or enter a different URL.
     * <br/>
     * If so, user will be asked if the user wants to leave the page, if he / she confirm, the game will not be saved and the page will be refreshed or redirected to the desired page (at a different URL).
     */
    useEffect(() => {
        window.addEventListener("beforeunload", checkForAllowRefreshOrRedirectPage)
        return () => window.removeEventListener("beforeunload", checkForAllowRefreshOrRedirectPage)
    }, [isGameRunning])

    /**
     * Check whether to display a dialog asking / notifying that the game will not be saved.
     * <br/>
     * The function will be called if the user plays the game and refreshes the page or changes (manually enters another) URL.
     * <br/>
     * If the game is running, ie the user is playing the game, a dialog will be displayed informing the user that if he refreshes the page or goes to another page (by manually entering another URL), the game currently played will not be saved.
     *
     * @param event an event with the data that triggered the call of this function are needed to set the question of whether the user wants to leave the game
     */
    function checkForAllowRefreshOrRedirectPage(event) {
        if (isGameRunning) {
            event.preventDefault()
            window.alert = console.log
            // Chrome requires returnValue to be set
            event.returnValue = ""
        } else {
            // The game is not running, there is no need to block a page change or refresh
            delete event['returnValue']
        }
    }

    /**
     * 'Watch' whether the user should be allowed to go to another page.
     * <br/>
     * If the game is started and the user tries to go to another page, a dialog will be displayed if he wants to save the played game.
     * <br/>
     * Depending on the answer, the game will either be saved and the user will be shown a dialog with information about the game played or the game will only be stopped and the user will be redirected to the desired page.
     */
    useEffect(() => {
        const unblock = history.block(prompt => {
            if (isGameRunning) {
                setTmpNewPage(prompt.pathname)
                setIsSaveGameAskDialogOpen(true)
                return false
            }
            return true
        })

        setUnblockHistory(() => unblock)
    }, [isGameRunning])

    /**
     * Unblock history and go to the desired page as needed.
     * <br/>
     * First, the history is unblocked, so that switching to another address will be allowed within the application routing.
     * <br/>
     * Subsequently, the game will be stopped and if it was selected (saveGame is true), the played (/ ongoing) game will be saved. And a dialog with information about the played game will be displayed.
     * <br/>
     * If saveGame is false, then the currently playing game will only be stopped (times, sounds, states, etc.) and user will be taken to the desired page. The game will not be saved in the database.
     *
     * @param saveGame true if the current game is to be stored in the database, otherwise false -> the game will only be stopped and the user will be moved to the desired page
     */
    function unblockHistoryAndGoToRequiredPage(saveGame) {
        // Allow going to another page
        unblockHistory()

        if (saveGame) {
            // Save the game and display the dialog of the played game
            stopGame()
        } else {
            // Stop the game and move the user to the desired page
            stopGame(false)
            history.push(tmpNewPage)
        }
    }

    /**
     * Add information about the selected answer and the correct answer to the question.
     *
     * @param correctAnswerId id of the correct answer to question
     * @param selectedAnswerId id user-selected answers when playing quiz game
     */
    function addSelectedAnswerIdInfo(correctAnswerId, selectedAnswerId) {
        const answerInfo = {
            selectedAnswerId,
            correctAnswerId
        };
        selectedAnswers.push(answerInfo);
    }

    /**
     * Checking the data to start the game. If it goes well, the game will start.
     * <br/>
     * <i>If the user has entered a username and email and they are stored in sessionStorage, the existence of data in the database will be tested and then the game will start. If the data does not exist, it will be created after agreeing with the GDPR, stored in the database and then the game will start.</i>
     */
    function checkUsernameAndEmailBeforeGameStartAndThenStartGame() {
        const existPlayerRequest = createExistPlayerRequest();

        if (existPlayerRequest) {
            existPlayer(existPlayerRequest, existPlayerResult => {
                if (existPlayerResult) {
                    // Player is in the database, no need to create a new one (open the dialog for its entry)
                    startGame()
                } else {
                    // The player does not exist, it is necessary to create new player
                    openSignInAndRegisterDialog(() => startGame)
                }
            })
        } else {
            // Player is not specified or invalid (intentionally changed), new must be created or existing must be used
            openSignInAndRegisterDialog(() => startGame)
        }
    }

    /**
     * Create an object to send to the controller with the intention of determining whether the appropriate combination of username and email address matches an existing player.
     *
     * @returns {{email: *, username: *}|undefined}
     */
    function createExistPlayerRequest() {
        const username = getItem(PLAYER_USERNAME);
        const email = getItem(PLAYER_EMAIL);

        if (username && username.match(REG_EX_USERNAME) && email && email.match(REG_EX_EMAIL)) {
            return {
                username,
                email
            }
        }

        return undefined;
    }

    function openSignInAndRegisterDialog(confirmOperationFce) {
        setConfirmOperation(confirmOperationFce);
        useSignAndRegisterPlayerDialog.openForm();
    }

    // Starting the game (setting default values, ie lives, score, time ...)
    function startGame() {
        // Guessing questions are generated when player start the game.
        // After the questions are generated, they will be stored in 'questions' variable.
        // When questions are saved, useEffect is called to respond to the change in the state of the mentioned variable and sets the values to start the game.
        generateQuestions(data => {
            if (!quizContext.useBgSound.isPlaying()) {
                quizContext.useBgSound.play() // Play sound that runs in the background (during the game)
            }
            questionCountdown.resetCountdown()
            setQuestions(data)
        })
    }

//         do readme napsat zprovoznei aplikace - nasazeni
//     - v tom CorsFilterProd zadat tu adresu, kde to pobezi
//     - v reactu v calls nechat pouze lomitko


    // FIXME TODO ted pokracovat
    // dodelat na strance s hraci - v dashboardu tabulku dodelam, ze půjde smazat i jedna hra!

    /**
     * Save newly played game.
     *
     * @param gameTimeInEpoch (total) game time (in epoch format)
     */
    function saveGamePlayed(gameTimeInEpoch) {
        const dtoIn = createAddGameDtoIn(gameTimeInEpoch);
        addGame(dtoIn, dtoOut => {
            setLastPlayedGame(dtoOut)
            useGameOverForm.openForm();
        })
    }

    function createAddGameDtoIn(gameTimeInEpoch) {
        return {
            username: getItem(PLAYER_USERNAME),
            email: getItem(PLAYER_EMAIL),
            time: gameTimeInEpoch,
            lives: lives.value,
            score: score.value
        }
    }

    /**
     * Stop the game and display the game over dialog to save the game (or username and email dialog (if sessionStorage has changed)).
     * <br/>
     * If the 'saveGame' parameter is true, the game will be stopped and the result will be saved to the database.
     * <br/>
     * If the saveGame parameter is false, the game will only be stopped, but its result will not be saved anywhere.
     *
     * @param saveGame true if the game is to be stored in a database, otherwise false
     */
    function stopGame(saveGame = true) {
        setIsGameRunning(false)
        questionCountdown.stopCountdown()

        // If the dialog is open after the game is over, it must be closed because the user still has not confirmed saving the game before going to another page
        setIsSaveGameAskDialogOpen(false)

        // Stop music playing in the background (game over)
        quizContext.useBgSound.stop()

        // If the game is not to be stored in the database, there is need to stop the game time
        if (!saveGame) {
            gameTimer.stopTimer()
            return
        }

        gameTimer.stopTimer(gameTimeInEpoch => {
            const existPlayerRequest = createExistPlayerRequest();
            if (existPlayerRequest) {
                existPlayer(existPlayerRequest, existPlayerResult => {
                    if (existPlayerResult) {
                        // The entered data has not been changed and is valid (existing player), the game can be saved
                        saveGamePlayed(gameTimeInEpoch);
                    } else {
                        // The user intentionally changed the data stored in sessionStorage, he / she must enter the current player's data and then save the game played
                        // The player does not exist in the database, a game played is created and then saved to it
                        openSignInAndRegisterDialog(() => saveGamePlayed.bind(null, gameTimeInEpoch))
                    }
                })
            } else {
                // The dialog box for entering new data opens and then the new / played game is saved to the newly created data (player)
                // The player does not exist in the database, a game played is created and then saved to it
                openSignInAndRegisterDialog(() => saveGamePlayed.bind(null, gameTimeInEpoch))
            }
        });
    }

    /**
     * Closes the dialog with the game result information displayed and displays the results of the game played - its answered questions, where the player will see which questions are answered well and which ones are wrong.
     *
     * @returns {Promise<void>}
     */
    async function closeGameOverFormAndDisplayGameResult() {
        await useGameOverForm.closeForm();
        displayResult()
    }

    /**
     * Display of game results.
     */
    function displayResult() {
        setContent(
            <QuizResult questions={getAnsweredQuestions()}
                        selectedAnswers={selectedAnswers}
                        setContent={setContent}/>
        )
    }

    /**
     * Get a question index to show the order of the current question versus the total number of questions to be guessed.
     *
     * The last question returns its index because it is an index outside the field (total number of questions)
     *
     * @returns {number} order of current questions
     */
    function getQuestionIndexForDisplay() {
        if (questionIndex < questions.length) {
            return questionIndex + 1;
        }
        return questionIndex;
    }

    /**
     * Get an overview of the order of the current question.
     * <br/>
     * <i>Thus, obtaining information in the form of: 'current question order / total number of questions'.
     *
     * @returns {string} text in the form of 'current question order / total number of questions'
     */
    function getQuestionState() {
        return getQuestionIndexForDisplay() + "/" + questions.length
    }

    /**
     * Filter only the player's answered questions in the game (until he has lost or finished the game).
     *
     * @returns {*[]} a array of questions the player has answered (had generated in the game)
     */
    function getAnsweredQuestions() {
        return questions.slice(0, selectedAnswers.length)
    }

    /**
     * Determining the status (validation) of questions in the database.
     * <br/>
     * <i>If the question status meets the requirements for playing the game, the game playing components are displayed. Otherwise, only the message that the player cannot play the game will be displayed, so the user should contact support.</i>
     */
    function loadQuestionsStatusAndSetGameComponents() {
        quizContext.spinner.setIsSpinning(true);

        getQuestionsStatus(questionsStatusDtoOut => {
            setIsQuestionsStatusReady(questionsStatusDtoOut.ready);
            quizContext.spinner.setIsSpinning(false);
            if (!questionsStatusDtoOut.ready) {
                quizContext.snackBar.openSnackBar(WARNING, questionsStatusDtoOut.message, VERTICAL_POSITION, HORIZONTAL_POSITION);
            }
        }, () => {
            quizContext.spinner.setIsSpinning(false);
        })
    }

    return (
        <div className={classes.root}>
            {/*<div>Total game time: {gameTimer.currentTimeInString}</div>*/}

            <GameContext.Provider
                value={{
                    getQuestionState,
                    score,
                    lives,
                    questionCountdown,
                    isGameRunning,
                    checkUsernameAndEmailBeforeGameStartAndThenStartGame,
                    getQuestionToAsk,
                    nextQuestion,
                    setAnswerWasDisplayed,
                    addSelectedAnswerIdInfo,
                    isQuestionsStatusReady,
                    stopGame
                }}>
                <GameContent/>
            </GameContext.Provider>

            <SignInAndRegisterPlayerDialog
                isOpen={useSignAndRegisterPlayerDialog.isFormOpen}
                closeDialog={useSignAndRegisterPlayerDialog.closeForm}
                handleConfirm={confirmOperation}
            />

            <GameOverForm
                isFormOpen={useGameOverForm.isFormOpen}
                closeForm={useGameOverForm.closeForm}
                displayResult={closeGameOverFormAndDisplayGameResult}
                time={gameTimer.currentTimeInString}
                lastPlayedGame={lastPlayedGame}
            />

            <SaveGameAskDialog
                open={isSaveGameAskDialogOpen}
                fceConfirm={() => unblockHistoryAndGoToRequiredPage(true)}
                fceCancel={() => unblockHistoryAndGoToRequiredPage(false)}
            />
        </div>
    )
}

export default Quiz;
