import React from 'react';
import List from "@material-ui/core/List";
import AnswerListItem from "./AnswerListItem";
import {useTranslation} from "react-i18next";

/**
 * Representation of the list of answers to the selected question.
 *
 * @param answers answer to be displayed
 * @param editAnswer functions for editing the answer, respectively. save changes made by the user
 * @param deleteAnswer function to delete the corresponding response from the database
 * @param selectCorrectAnswer setting the answer id marked as correct
 * @param isCorrectAnswer whether the answer with function parameter (answerId) is the answer id that is marked as correct
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 15.09.2019 21:08
 */

function AnswerList({answers, editAnswer, deleteAnswer, selectCorrectAnswer, isCorrectAnswer}) {

    const {t} = useTranslation()

    return (
        <List component="div" disablePadding>
            {answers && answers.length > 0
                ? answers.map(answer => {
                    return <AnswerListItem key={answer.id}
                                           answer={answer}
                                           editAnswer={() => editAnswer(answer)}
                                           deleteAnswer={() => deleteAnswer(answer)}
                                           selectCorrectAnswer={() => selectCorrectAnswer(answer.id)}
                                           isCorrectAnswer={() => isCorrectAnswer(answer.id)}
                    />
                })
                : <div>{t("answer-list-no-answer-present")}</div>}
        </List>
    )
}

export default AnswerList;
