import {getSoundUrl} from "../../server/ServerCall"

// Addresses of sounds to play when playing the game
export const SRC_BACKGROUND_SOUND = getSoundUrl("BACKGROUND")
export const SRC_ADD_SCORE_SOUND = getSoundUrl("ADD_SCORE")
export const SRC_ANSWER_SELECTION_SOUND = getSoundUrl("ANSWER_SELECTION")
export const SRC_ASK_QUESTION_SOUND = getSoundUrl("ASK_QUESTION")
export const SRC_CORRECT_ANSWER_1_SOUND = getSoundUrl("CORRECT_ANSWER_1")
export const SRC_CORRECT_ANSWER_2_SOUND = getSoundUrl("CORRECT_ANSWER_2")
export const SRC_WRONG_ANSWER_SOUND = getSoundUrl("WRONG_ANSWER")

// Cookies value for setting whether sounds should be played or not
// true, the sounds will be played, otherwise false
export const SOUNDS_ON = "soundOn"
