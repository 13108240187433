import React, {useContext, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useTextField from "../../textfield/useTextField";
import TextFieldForm from "../../textfield/TextFieldForm";
import Call from "../../server/Call";
import {QuizContext} from "../../quiz/context/QuizContext";
import {SUCCESS, WARNING} from "../../snackbar/Variant";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../snackbar/Settings";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

/**
 * User login form by entering a username or email address and password.
 *
 * https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/sign-in/SignIn.js
 *
 * @author Jan Krunčík
 * @since 14.09.2019 11:11
 */

function SignInForm() {

    const quizContext = useContext(QuizContext);
    const [rememberMe, setRememberMe] = useState(false);
    const classes = useStyles();
    const {signIn} = Call();

    const {t} = useTranslation()

    const usernameOrEmail = useTextField(
        'sign-in-form-txt-username-or-email',
        // 'admin@gmail.com',
        '',
        undefined,
        true,
        "normal",
        "Username or Email Address",
        "text",
        true,
        "email",
        "outlined");
    const password = useTextField(
        'sign-in-form-txt-password',
        // 'Passwordš_123#',
        '',
        undefined,
        false,
        "normal",
        "Password",
        "password",
        true,
        "current-password",
        "outlined");

    function handleSignIn() {
        if (!checkEmptyFields()) {
            return;
        }

        const loginRequest = createLoginRequest();
        signIn(loginRequest, () => {
            signInSuccessSnackbar()
        });
    }

    function createLoginRequest() {
        return {
            usernameOrEmail: usernameOrEmail.value,
            password: password.value,
            rememberMe
        }
    }

    function checkEmptyFields() {
        if (!usernameOrEmail.value) {
            quizContext.snackBar.openSnackBar(WARNING, t("sign-in-form-username-or-email-not-specified"), VERTICAL_POSITION, HORIZONTAL_POSITION);
            return false;
        }
        if (!password.value) {
            quizContext.snackBar.openSnackBar(WARNING, t("sign-in-form-password-not-specified"), VERTICAL_POSITION, HORIZONTAL_POSITION);
            return false;
        }
        return true;
    }

    /**
     * View information about the successful sign-in to the application.
     */
    function signInSuccessSnackbar() {
        quizContext.snackBar.openSnackBar(SUCCESS, t("sign-in-form-sign-in-success"), VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t("sign-in-form-sign-in-title")}
                </Typography>
                <div className={classes.form}>
                    <form>
                        <TextFieldForm
                            use={usernameOrEmail}/>
                        <TextFieldForm
                            use={password}/>
                    </form>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                                value="remember"
                                color="primary"
                            />
                        }
                        label={t("sign-in-form-remember-me")}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSignIn}
                    >
                        {t("sign-in-form-btn-sign-in")}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/" variant="body2">
                                {t("sign-in-form-forgot-password")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/" variant="body2">
                                {t("sign-in-form-sign-up-link")}
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    )
}

export default SignInForm;
