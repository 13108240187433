// The constants represent the image (/ animation) to be displayed

// The default animation that will play when the image is displayed
export const MODERATOR_DEFAULT = "MODERATOR_DEFAULT"
// Animation / Image displayed after entering a new question
export const MODERATOR_NEW_QUESTION = "MODERATOR_NEW_QUESTION"
// A static image (not an animation) that will be displayed when the animation for the new question is complete
export const MODERATOR_NEW_QUESTION_STATIC = "MODERATOR_NEW_QUESTION_STATIC"
// Animation / Image displayed after evaluating the correct answer
export const MODERATOR_CORRECT_ANSWER = "MODERATOR_CORRECT_ANSWER"
// A static image (not an animation) displayed after the animation for the correct answer is complete
export const MODERATOR_CORRECT_ANSWER_STATIC = "MODERATOR_CORRECT_ANSWER_STATIC"
// Animation / Image displayed after evaluating a wrong answer
export const MODERATOR_WRONG_ANSWER = "MODERATOR_WRONG_ANSWER"
// A static image (not an animation) that will be displayed after the animation for the incorrect answer is complete
export const MODERATOR_WRONG_ANSWER_STATIC = "MODERATOR_WRONG_ANSWER_STATIC"
