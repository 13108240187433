import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";

const styles = theme => ({
    root: {
        margin: 'auto',
        padding: theme.spacing(2)
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4">{children}</Typography>
        </MuiDialogTitle>
    )
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        margin: 'auto',
        textAlign: 'center'
    }
}))(MuiDialogContent);

/**
 * A dialog that informs the user that developer tools are open, which is not allowed.
 * <br/>
 * https://material-ui.com/components/dialogs/#customized-dialogs
 *
 * @param isFormOpen true if this dialog is to be opened, otherwise false
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 31.01.2020 22:55
 */
const DevToolsOpenForm = ({isFormOpen}) => {

    const {t} = useTranslation()

    return (
        <Dialog aria-labelledby="devtools-open-dialog-title" open={isFormOpen}>
            <DialogTitle id="devtools-open-dialog-title">
                {t("dev-tools-open-form-title")}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {t("dev-tools-open-form-info-about-open-dev-tools")}
                </Typography>
                <Typography gutterBottom>
                    {t("dev-tools-open-form-info-about-closing-dev-tools")}
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

export default DevToolsOpenForm;
