import {useState} from 'react';

/**
 * Save the value in the text field. It also allows you to validate a given value according to a regular expression.
 *
 * @author Jan Krunčík
 * @since 25.08.2019 23:44
 */

function useTextField(initialId, initialState, initialRegEx, initialAutoFocus, initialMargin, initialLabel, initialType, initialRequired, initialAutoComplete, initialVariant) {

    const [value, setValue] = useState(initialState);
    const [regEx] = useState(initialRegEx);
    const [id] = useState(initialId);
    const [autoFocus] = useState(initialAutoFocus);
    const [margin] = useState(initialMargin);
    const [label] = useState(initialLabel);
    const [type] = useState(initialType);
    const [required] = useState(initialRequired);
    const [autoComplete] = useState(initialAutoComplete);
    const [variant] = useState(initialVariant);

    function handleChange(e) {
        setValue(e.target.value);
    }

    function validate() {
        // If no regular expression is given, the text is returned valid, otherwise a text field with an invalid value would be displayed
        if (!regEx) {
            return true;
        }
        return regEx.test(value);
    }

    return {
        value,
        setValue,
        id,
        autoFocus,
        margin,
        label,
        type,
        required,
        autoComplete,
        variant,
        handleChange,
        validate
    }
}

export default useTextField;
