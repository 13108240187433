import {useState} from 'react'
import {useMountEffect} from "../../mount/useMountEffect"
import PropTypes from "prop-types"
import useSoundSupport from "./useSoundSupport"

/**
 * 'Hook' for playing audio files.
 * <br/>
 * Used to play sound files while playing a game, such as playing background music, playing a sound effect when selecting answers, and so on.
 *
 * @param source the source (address / link) of the audio file to be played
 * @param playInLoop true if the audio file is to be played in an infinite loop, otherwise false
 * @returns {{play: play, isPlaying: boolean, stop: stop}} return values to determine if the sound is currently playing and functions to start and stop it
 *
 * @author Jan Krunčík
 * @since 29.10.2020 23:22
 */
function useSound(source, playInLoop) {

    const {isSoundOn} = useSoundSupport()

    const [audio] = useState(new Audio(source))

    const isPlaying = () => !audio.paused

    const play = () => {
        if (isSoundOn()) {
            audio.loop = playInLoop
            audio.preload = "auto"
            audio.play()
        }
    }
    const stop = () => {
        audio.pause()
        audio.currentTime = 0
    }

    useMountEffect(() => {
        audio.addEventListener('ended', stop)
        return () => audio.removeEventListener('ended', stop)
    })

    return {
        isPlaying,
        play,
        stop
    }
}

useSound.propTypes = {
    source: PropTypes.string.isRequired,
    playInLoop: PropTypes.bool.isRequired
}

export default useSound
