import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import QuizGameContent from "./quiz/QuizGameContent";
import ScoreGrid from "./quiz/score/ScoreGrid";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    }
}));

/**
 * Component division for responsive behavior.
 * <br/>
 * <i>The left and right curtains are drawn here and the component for playing the game is shown in the middle 'column'. It contains components for playing the game, marking the answer, displaying the score, etc.
 *
 * @author Jan Krunčík
 * @since 17.07.2020 13:53
 */

function GameContent() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justify="space-evenly"
            >
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6} zeroMinWidth>
                    <QuizGameContent/>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
            >
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6} zeroMinWidth>
                    <ScoreGrid/>
                </Grid>
            </Grid>
        </div>
    )
}

export default GameContent;
