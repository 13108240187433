import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes, {shape} from "prop-types";
import Answer from "./Answer";
import {DEFAULT_ID} from "../../../property/Properties";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 10,
        marginBottom: 15,
        flexGrow: 1
    },
    answersGrid: {
        marginTop: 10
    }
}));

/**
 * Display the grid with answers.
 * <br/>
 * <i>Display the grid with answers.</i>
 *
 * @param answers list of answers to the question
 * @param chooseAnswer function that sets (/ saves) the id of the selected answer, which will be evaluated, according to the selected answer, the background of the answer will be highlighted
 * @param selectedAnswerId id of the answer marked by the player
 * @param correctAnswerId id the correct answer to the question
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 17.07.2020 17:16
 */
function Answers({answers, chooseAnswer, selectedAnswerId, correctAnswerId}) {

    const classes = useStyles()

    /**
     * Creating a list of grids, one grid = one item in returned list = one line with answers.
     * <br/>
     * There will always be either one or two answers on one line.
     * <br/>
     * The answer entered by the image will always be aligned on one line to the horizontal center occupying the full width of the line.
     * <br/>
     * <br/>
     * The answer entered in the text.
     * <br/>
     * - If there is one answer on one line, it will be centered on the horizontal center of the line and will occupy the width of one column.
     * <br/>
     * - If there are two answers on one line, they will be aligned in two columns.
     * <br/>
     * <br/>
     * Calculation:
     *   - It is determined whether the first answer entered is an image, if so, it is inserted on one line.
     *   <br/>
     *   - If the first answer is entered in text, it is determined whether the second answer is also entered in text or an image. If specified by text, the two will be side by side if the image. The first will be centered, the second will be inserted on a new line. If there is no second answer, only the first one will be displayed.
     *
     * @returns {[]} a list of grids containing as individual items lines with answers, each item will contain one or two answers according to the above rules
     */
    function getAnswersToRender() {
        const componentAnswers = []

        for (let i = 0; i < answers.length; i += 1) {
            const answer1 = answers[i]
            const answer2 = i + 1 < answers.length ? answers[i + 1] : undefined

            if (answer1.givenByImage) {
                // The first (currently iterated) answer is entered by an image, it will be displayed on the whole line
                componentAnswers.push(
                    createGridRow(i,
                        <Grid item xs={11} sm={12} md={12} lg={12} xl={12} zeroMinWidth>
                            {getAnswer(answer1)}
                        </Grid>
                    )
                )
            } else {
                // The first (/ currently iterated) answer is given by the text
                if (!answer2) {
                    // The second (/ next) answer is not given, only the first answer given by the text is given
                    componentAnswers.push(
                        createGridRow(i,
                            <Grid item xs={11} sm={6} md={6} lg={6} xl={6} zeroMinWidth>
                                {getAnswer(answer1)}
                            </Grid>
                        )
                    )
                } else if (answer2.givenByImage) {
                    // The second answer is given by an image, the first answer will be the width of one column on one line, the second answer will be on the next line in its entire width
                    i += 1
                    componentAnswers.push(
                        createGridRow(i,
                            <>
                                <Grid item xs={11} sm={6} md={6} lg={6} xl={6} zeroMinWidth>
                                    {getAnswer(answer1)}
                                </Grid>
                                <Grid item xs={11} sm={12} md={12} lg={12} xl={12} zeroMinWidth>
                                    {getAnswer(answer2)}
                                </Grid>
                            </>
                        )
                    )
                } else {
                    // The second answer is given by the text, so there will be two answers on one line (in two columns)
                    i += 1
                    componentAnswers.push(
                        createGridRow(i,
                            <>
                                <Grid item xs={11} sm={6} md={6} lg={6} xl={6} zeroMinWidth>
                                    {getAnswer(answer1)}
                                </Grid>
                                <Grid item xs={11} sm={6} md={6} lg={6} xl={6} zeroMinWidth>
                                    {getAnswer(answer2)}
                                </Grid>
                            </>
                        )
                    )
                }
            }
        }

        return componentAnswers
    }

    function createGridRow(key, item) {
        return (
            <Grid
                key={key}
                container
                direction="row"
                justify="center"
                spacing={2}
                className={classes.answersGrid}
            >
                {item}
            </Grid>
        )
    }

    function getAnswer(answer) {
        return (
            <Answer
                answer={answer}
                chooseAnswer={chooseAnswer}
                selectedAnswerId={selectedAnswerId}
                correctAnswerId={correctAnswerId}
            />
        )
    }

    return (
        <div className={classes.root}>
            {getAnswersToRender()}
        </div>
    )
}

Answer.defaultProps = {
    answers: [],
    selectedAnswerId: DEFAULT_ID,
    correctAnswerId: DEFAULT_ID
}

Answers.propTypes = {
    answers: PropTypes.arrayOf(shape({
        id: PropTypes.number.isRequired,
        questionId: PropTypes.number.isRequired,
        text: PropTypes.string,
        imgId: PropTypes.number,
        givenByImage: PropTypes.bool.isRequired
    })).isRequired,
    chooseAnswer: PropTypes.func.isRequired,
    selectedAnswerId: PropTypes.number.isRequired,
    correctAnswerId: PropTypes.number.isRequired
}

export default Answers;
