import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import QuestionOrAnswerTextOrImage from "../../../../quiz/image/QuestionOrAnswerTextOrImage";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    dialogContent: {
        textAlign: 'center'
    }
}));

/**
 * Confirmation of deletion of all answers to the selected question.
 *
 * @param isFormOpen true if the dialog is to be opened, otherwise false
 * @param closeForm function to close the dialog
 * @param question whose answers are to be deleted is passed here to the user to see that the answers to that question will be deleted
 * @param deleteAnswersToQuestion the function for deleting the answers to the question
 * @returns {*} the dialog described above to confirm the deletion of answers to the selected question
 * @constructor
 *
 * @author Jan Krunčík
 * @since 26.01.2020 17:27
 */
function ConfirmDeleteQuestionAnswers({isFormOpen, closeForm, question, deleteAnswersToQuestion}) {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <Dialog
            open={isFormOpen}
            aria-labelledby="confirm-delete-answers-to-question-dialog-title"
            aria-describedby="confirm-delete-answers-to-question-dialog-description"
        >
            <DialogTitle
                id="confirm-delete-answers-to-question-dialog-title">{t("confirm-delete-question-answers-title")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-delete-answers-to-question-dialog-description"
                                   className={classes.dialogContent}>
                    <QuestionOrAnswerTextOrImage
                        questionOrAnswer={question}
                        isQuestion={true}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeForm} color="primary">
                    {t("confirm-delete-question-answers-btn-cancel")}
                </Button>
                <Button onClick={deleteAnswersToQuestion} color="primary" autoFocus>
                    {t("confirm-delete-question-answers-btn-delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteQuestionAnswers;
