import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types"
import {QuizContext} from "./quiz/context/QuizContext"
import SnackBarDialog from "./snackbar/SnackBarDialog"
import Spinner from "./spinner/Spinner"
import useSnackBar from "./snackbar/useSnackBar"
import useSpinner from "./spinner/useSpinner"
import useSoundSupport from "./quiz/sound/useSoundSupport"
import DevToolsHandler from "./devtools/DevToolsHandler"
import MainRoute from "./route/MainRoute"

/**
 * Displaying the content of the page, the snack bar - a dialog with information for the user and, if necessary, a spinner over the 'whole' page, as needed so that the user does not click on anything unwanted.
 * <br/>
 * There is need to pass sounds to this component so that only the value of the sounds (whether they play or not) is set in this component, so that they are not loaded every time user moves between pages. As a result, the state with sounds will be declared in another component, which will not be re-rendered, thanks to which the sounds will not be reloaded.
 * <br/>
 * The snack bar and spinner are also displayed here (instead of in the parent component), as their status may change so that sounds are not reloaded.
 *
 * @param useBgSound game background sound
 * <br/>
 * @param useAskQuestionSound sound to play when generating a new / next question
 * <br/>
 * @param useCorrectAnswer1Sound sound to play when the user selects an answer (version 1)
 * <br/>
 * @param useCorrectAnswer2Sound sound to play when the user selects an answer (version 2)
 * <br/>
 * @param useAddScoreSound sound to play when the question is successfully answered, ie increase the player's score
 * <br/>
 * @param useWrongAnswerSound sound to play when the user's selected answer is incorrect
 * <br/>
 * @param useAnswerSelectionSound sound to play when the user selects an answer from the options offered
 * @returns {JSX.Element} the component described above for displaying the content of the page, passing the required values in context and displaying the snack bar and spinner
 * @constructor
 *
 * @author Jan Krunčík
 * @since 06.02.2021 21:57
 */
function AppContent({
                        useBgSound,
                        useAskQuestionSound,
                        useCorrectAnswer1Sound,
                        useCorrectAnswer2Sound,
                        useAddScoreSound,
                        useWrongAnswerSound,
                        useAnswerSelectionSound
                    }) {

    const snackBar = useSnackBar()
    const spinner = useSpinner()

    const {isSoundOn} = useSoundSupport()
    // It is required to respond when the user turns the sounds on / off so that the sounds either turn off immediately or start playing the background sound when the sounds are turned on
    const [areSoundsOn, setAreSoundsOn] = useState(isSoundOn())

    /**
     * Responding to sound on / off.
     * <br/>
     * If the user mutes the sounds, all sounds must be stopped immediately.
     */
    useEffect(() => {
        if (!areSoundsOn) {
            useBgSound.stop()
            useAskQuestionSound.stop()
            useCorrectAnswer1Sound.stop()
            useCorrectAnswer2Sound.stop()
            useAddScoreSound.stop()
            useWrongAnswerSound.stop()
            useAnswerSelectionSound.stop()
        }
    }, [areSoundsOn])

    return (
        <>
            <QuizContext.Provider value={{
                snackBar,
                spinner,
                areSoundsOn,
                setAreSoundsOn,
                useBgSound,
                useAskQuestionSound,
                useCorrectAnswer1Sound,
                useCorrectAnswer2Sound,
                useAddScoreSound,
                useWrongAnswerSound,
                useAnswerSelectionSound
            }}>
                {/*<DevToolsHandler/>*/}
                <MainRoute/>
            </QuizContext.Provider>
            <SnackBarDialog snackBar={snackBar}/>
            {spinner.isSpinning && <Spinner isSpinning={spinner.isSpinning}/>}
        </>
    )
}

AppContent.propTypes = {
    useBgSound: PropTypes.object.isRequired,
    useAskQuestionSound: PropTypes.object.isRequired,
    useCorrectAnswer1Sound: PropTypes.object.isRequired,
    useCorrectAnswer2Sound: PropTypes.object.isRequired,
    useAddScoreSound: PropTypes.object.isRequired,
    useWrongAnswerSound: PropTypes.object.isRequired,
    useAnswerSelectionSound: PropTypes.object.isRequired
}

export default AppContent
