import React, {useContext} from 'react';
import {GameContext} from "../../../context/GameContext";
import ProgressBar from "./ProgressBar";
import {INITIAL_START_VALUE} from "../../../timer/CountdownTimer";

/**
 * Render a time progress bar that represents the time until which a player can receive a bonus for quickly answering a question.
 * <br/>
 * Source: https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl
 *
 * @author Jan Krunčík
 * @since 17.07.2020 22:51
 */

function BonusProgressBar() {

    const gameContext = useContext(GameContext)

    /**
     * Get the color of the progress bar according to the 'status' (/ time) that remains.
     * <br/>
     * <i>The remaining time will be converted to a percentage value and one of the four colors will be selected accordingly.
     *
     * @param time the remaining time until which the player can receive the bonus, this time will be converted to a percentage value and the color of the progress bar will be obtained accordingly
     * @returns {string} color for progress bar
     */
    function getProgressBarBgColor(time) {
        const stateInPercent = getCompletedStateInPercent(time)
        if (stateInPercent <= 25) {
            return "#ff0000"
        } else if (stateInPercent <= 50) {
            return "#ff8500"
        } else if (stateInPercent <= 75) {
            return "#008dee"
        }
        return "#50d70b"
    }

    /**
     * Conversion of the remaining time to a percentage value (in the range 0 - 100%).
     *
     * @param time the time to be converted to a percentage
     * @returns {number} percentage value of time in the method parameter
     */
    function getCompletedStateInPercent(time) {
        return (time / INITIAL_START_VALUE) * 100
    }

    return (
        <ProgressBar
            progressBarBgColor={getProgressBarBgColor(gameContext.questionCountdown.countdownValue)}
            completed={getCompletedStateInPercent(gameContext.questionCountdown.countdownValue)}
            time={gameContext.questionCountdown.countdownValue}
        />
    )
}

export default BonusProgressBar;
