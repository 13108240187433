import React from 'react';
import {useTranslation} from "react-i18next";

/**
 * Display of a label (tooltip) after hovering the mouse cursor over a specific column - display of more detailed information.
 *
 * @author Jan Krunčík
 * @since 03.07.2020 10:29
 */

function ShapeCustomChartTooltip(props) {

    const {t} = useTranslation()

    function getCountWithParentheses(count) {
        return " (" + count + ")"
    }

    function getColumnIntro(label, count) {
        if (label === t("shape-column-chart-questions-given-by-text")) {
            return t("shape-column-chart_intro-questions-count-given-by-text") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-questions-given-by-image")) {
            return t("shape-column-chart_intro-questions-count-given-by-image") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-valid-questions-count")) {
            return t("shape-column-chart_intro-valid-questions-count") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-invalid-questions-count")) {
            return t("shape-column-chart_intro-invalid-questions-count") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-answers-given-by-text")) {
            return t("shape-column-chart-intro-answers-given-by-text") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-answers-given-by-image")) {
            return t("shape-column-chart-intro-answers-given-by-image") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-registered-users")) {
            return t("shape-column-chart-intro-registered-users") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-players")) {
            return t("shape-column-chart-intro-players") + getCountWithParentheses(count)
        } else if (label === t("shape-column-chart-played-games")) {
            return t("shape-column-chart-intro-played-games") + getCountWithParentheses(count)
        }
        return "";
    }

    function getColumnTooltip() {
        const {active} = props;

        if (active) {
            const {payload, label} = props;
            return (
                <div className="custom-tooltip">
                    <p className="intro">{getColumnIntro(label, payload && payload[0].value)}</p>
                </div>
            )
        }

        return <div/>
    }

    return (
        getColumnTooltip()
    )
}

export default ShapeCustomChartTooltip;
