import React, {useContext, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {EASY, getQuestionCategoryByName, HARD, MEDIUM} from "../constant/category";
import useTextField from "./useTextField";
import {QuizContext} from "../../../../quiz/context/QuizContext";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../../../snackbar/Settings";
import {WARNING} from "../../../../snackbar/Variant";
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import ImageUploaderComponent from "../image/ImageUploaderComponent";
import ImageRenderComponent from "../image/ImageRenderComponent";
import {useTranslation} from "react-i18next";

const REG_EX_QUESTION_TEXT = /^[\da-zA-ZÁ-ž,'.\s?„"()*=;~+]{1,255}$/

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue
        marginTop: theme.spacing(1)
    },
    formControl: {
        display: 'flex',
        textAlign: 'center'
    }
}));

/**
 * Dialog used to create a new question and edit an existing question.
 *
 * @param isFormOpen true if the form is to be opened, otherwise false
 * @param closeForm close dialog function
 * @param formTitle the title of the dialog, for example, that it is about creating a new question or editing an existing one
 * @param questionForEdit the question that the user has chosen to edit, the values are 'saved' in the form without retention of references and are further worked on
 * @param createQuestion function for creating a new question - sending a request to the database
 * @param editQuestion function for saving modified question, resp. execution operation change question passed in questionForEdit parameter
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 17.09.2019 0:10
 */
function CreateEditQuestionForm({isFormOpen, closeForm, formTitle, questionForEdit, createQuestion, editQuestion}) {

    const classes = useStyles();

    const quizContext = useContext(QuizContext);

    const questionTextField = useTextField('', REG_EX_QUESTION_TEXT);
    const [selectedCategory, setSelectedCategory] = useState(EASY);

    // Set the image for the question
    const [isQuestionGivenByImage, setIsQuestionGivenByImage] = useState(false);
    const [questionImage, setQuestionImage] = useState(undefined);

    // The width of the "space" for the Category heading for the drop-down menu (just enough to fit the text)
    const labelWidth = 75;

    const {t} = useTranslation()

    // In case that an existing question is being edited when the dialog is opened, the necessary values of the form are filled with question values (without reference to question)
    useEffect(() => {
        if (isFormOpen && questionForEdit) {
            questionTextField.setTextValue(questionForEdit.text === null ? "" : questionForEdit.text);
            setIsQuestionGivenByImage(questionForEdit.givenByImage);
            setSelectedCategory(getQuestionCategoryByName(questionForEdit.category));
        }
    }, [isFormOpen]);

    function handleChangeCategory(event) {
        setSelectedCategory(event.target.value);
    }

    function createNewQuestion() {
        if (validateFormValues()) {
            createQuestion(createQuestionObject(), clearFields);
        }
    }

    /**
     * Create an object to send to the controller.
     * <br/>
     * <i>The question has to be sent via "FormData", because it allows sending the file (image).</i>
     * <br/>
     * The object will contain the necessary data entered by the user into the form and the id of questionForEdit in case it is editing an existing question.
     *
     * @returns {FormData} data that will be sent to the controller for saving to the database, it is necessary to use "FormData" because it contains the possibility to send the uploaded file
     */
    function createQuestionObject() {
        const formData = new FormData();

        formData.append("id", questionForEdit ? questionForEdit.id : "");
        formData.append("text", questionTextField.value);
        formData.append("category", selectedCategory.name);
        formData.append("givenByImage", isQuestionGivenByImage.toString());
        formData.append("imgId", getImgId());

        if (isQuestionGivenByImage && questionImage) {
            formData.append("mfImage", questionImage);
        }

        return formData;
    }

    function getImgId() {
        return questionForEdit && questionForEdit.imgId !== null ? questionForEdit.imgId : "";
    }

    /**
     * If the entered data in the form is valid create a new object with the appropriate values and update the relevant question in the database.
     * <br/>
     * <i>It is necessary to create a new object with data from form and especially the id from the passed object questionForEdit. If you set a value from the form to questionForEdit, the components / questions would be redrawn when the form was closed, but the newly created answers would not have an id and a warning would be displayed.</i>
     */
    function editExistingQuestion() {
        if (validateFormValues()) {
            editQuestion(createQuestionObject(), clearFields);
        }
    }

    function validateFormValues() {
        if (isQuestionGivenByImage) {
            // The question is entered by the image, either the newly uploaded image or the id of the existing image must be present for the edited question
            if (!questionImage) {
                // There is no uploaded image, there must be an existing image id, this is only possible with an existing (edited) question
                if (!questionForEdit || questionForEdit.imgId === null) {
                    showSnackBarDialogWarning(t("create-edit-question-form-no-image-uploaded"));
                    return false;
                }
                return true;
            }
            // A newly uploaded image is present
            return true;
        }

        if (questionTextField.value.replace(/\s*/, "") === "") {
            showSnackBarDialogWarning(t("create-edit-question-form-question-not-specified"));
            return false;
        }
        if (!questionTextField.validate()) {
            showSnackBarDialogWarning(t("create-edit-question-form-question-is-not-valid"));
            return false;
        }

        return true;
    }

    /**
     * Delete / Empty all values that the user can fill.
     */
    function clearFields() {
        questionTextField.setEmptyValue();
        setSelectedCategory(EASY);
        setIsQuestionGivenByImage(false);
        setQuestionImage(undefined);
    }

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    /**
     * Obtaining a component for enter the question.
     * <br/>
     * <i>If the form indicates that the question should be entered by an image, the image upload component will return. Otherwise, the text field returns.</i>
     *
     * @returns {*} text field or image uploader component to enter a question
     */
    function getComponentForEnterQuestion() {
        if (!isQuestionGivenByImage) {
            return <TextField
                variant="outlined"
                required
                fullWidth
                id="question-text"
                label={t("create-edit-question-form-txt-label")}
                name="question"
                autoComplete="question"
                multiline
                rows={3}
                error={!questionTextField.validate()}
                value={questionTextField.value}
                onChange={questionTextField.handleChange}
            />
        }

        // Display an image representing a question and a delete button if the image is uploaded
        // Otherwise the text will show that no image is uploaded and a button to upload it
        return <>
            <ImageRenderComponent
                image={questionImage}
                removeImage={removeQuestionImage}
                imgId={questionForEdit && questionForEdit.givenByImage ? questionForEdit.imgId : undefined}
                isQuestion={true}
            />
            {!questionImage && <ImageUploaderComponent
                imageCallback={uploadQuestionImage}/>}
        </>
    }

    function uploadQuestionImage(image) {
        setQuestionImage(image);
    }

    function removeQuestionImage() {
        setQuestionImage(undefined);
    }

    return (
        <Dialog open={isFormOpen}>
            <DialogContent>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <QuestionAnswerIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {formTitle}
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Tooltip
                                        title={t("create-edit-question-form-question-given-by-image-switch-tooltip")}>
                                        <Switch checked={isQuestionGivenByImage}
                                                onChange={() => setIsQuestionGivenByImage(!isQuestionGivenByImage)}/>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                    {getComponentForEnterQuestion()}
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="category-select" required>
                                            {t("create-edit-question-form-category-select")}
                                        </InputLabel>
                                        <Select
                                            value={selectedCategory}
                                            onChange={handleChangeCategory}
                                            autoWidth={true}
                                            input={
                                                <OutlinedInput labelWidth={labelWidth}
                                                               name="category"
                                                               id="category-select"/>}
                                        >
                                            <MenuItem value={EASY}>{t(EASY.text)}</MenuItem>
                                            <MenuItem value={MEDIUM}>{t(MEDIUM.text)}</MenuItem>
                                            <MenuItem value={HARD}>{t(HARD.text)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => closeForm(clearFields)} color="primary">
                    {t("create-edit-question-form-btn-cancel")}
                </Button>
                <Button onClick={questionForEdit ? editExistingQuestion : createNewQuestion}
                        color="primary" autoFocus>
                    {t("create-edit-question-form-btn-save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateEditQuestionForm;
