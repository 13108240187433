import React from 'react';
import {makeStyles} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from '@material-ui/core/Checkbox';
import QuestionOrAnswerTextOrImage from "./image/QuestionOrAnswerTextOrImage";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
    button: {
        margin: theme.spacing(1)
    },
    answerItem: {
        textAlign: 'center'
    }
}));

/**
 * Representation of one specific answer in the answer list for the selected question.
 *
 * @param answer the answer to be displayed, respectively. that this component should represent
 * @param editAnswer functions for editing the answer, respectively. save changes made by the user
 * @param deleteAnswer function to delete the corresponding response from the database
 * @param selectCorrectAnswer setting the answer id marked as correct
 * @param isCorrectAnswer whether the answer with function parameter (answerId) is the answer id that is marked as correct
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 15.09.2019 21:09
 */

function AnswerListItem({answer, editAnswer, deleteAnswer, selectCorrectAnswer, isCorrectAnswer}) {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <ListItem key={answer.id} button className={classes.nested}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={isCorrectAnswer()}
                    tabIndex={-1}
                    disableRipple
                    onChange={selectCorrectAnswer}
                />
            </ListItemIcon>
            <ListItemText id={answer.id}
                          className={classes.answerItem}
                          primary={
                              <QuestionOrAnswerTextOrImage
                                  questionOrAnswer={answer}
                                  isQuestion={false}/>
                          }/>
            <ListItemSecondaryAction>
                <Tooltip title={t("answer-list-item-btn-edit-answer")}>
                    <IconButton className={classes.button}
                                aria-label="edit answer"
                                color="primary"
                                onClick={editAnswer}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("answer-list-item-btn-delete-answer")}>
                    <IconButton className={classes.button}
                                aria-label="delete answer"
                                color="secondary"
                                onClick={deleteAnswer}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default AnswerListItem;
