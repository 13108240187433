import React, {useState} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import QuestionOrAnswerTextOrImage from "../image/QuestionOrAnswerTextOrImage";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import {makeStyles} from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import QuizResultAnswerList from "./QuizResultAnswerList";

const useStyles = makeStyles(() => ({
    questionItem: {
        textAlign: 'center'
    }
}));

/**
 * Displays one particular answered (/ guessed) question as a single drop-down menu item.
 *
 * @param question answered question generated for the player in the game
 * @param selectedAnswerInfo information about the answer to the question, which answer the player chose and which answer is correct
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 29.01.2020 14:56
 */
function QuizResultQuestionListItem({question, selectedAnswerInfo}) {

    const classes = useStyles();

    const [open, setOpen] = useState(!isAnswerCorrect());

    function isAnswerCorrect() {
        return selectedAnswerInfo.selectedAnswerId === selectedAnswerInfo.correctAnswerId
    }

    /**
     * Icon according to the correct answer to the question.
     * <br/>
     * <i>If this is the correct answer, an icon representing this will be returned. If this is an incorrect answer, an icon will be returned to alert player.<i/>
     */
    function getQuestionIcon() {
        if (isAnswerCorrect()) {
            return <CheckIcon color={"primary"}/>
        }
        return <ClearIcon color={"secondary"}/>
    }

    function handleOpen() {
        setOpen(!open)
    }

    return (
        <>
            <ListItem button onClick={handleOpen}>
                <ListItemIcon>
                    {getQuestionIcon()}
                </ListItemIcon>
                <ListItemText
                    className={classes.questionItem}
                    primary={
                        <QuestionOrAnswerTextOrImage
                            questionOrAnswer={question}
                            isQuestion={true}
                        />
                    }/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <QuizResultAnswerList answers={question.answers}
                                      selectedAnswerInfo={selectedAnswerInfo}/>
            </Collapse>
        </>
    )
}

export default QuizResultQuestionListItem;
