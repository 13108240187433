import React from 'react';
import QuizResultQuestionList from "./QuizResultQuestionList";
import {makeStyles} from "@material-ui/core/styles";
import HomeIcon from '@material-ui/icons/Home';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    }
}));

/**
 * View the results of a game player played.
 * <br/>
 * <i>Display a button to return to the home page with a quiz game to start the next game and below the results.<i/>
 *
 * @param questions the player guessed in the game (got generated)
 * @param selectedAnswers an array of objects with information about what answer the player has chosen and which are correct
 * @param setContent set content (/ components) on the render page
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 29.01.2020 14:55
 */
function QuizResult({questions, selectedAnswers, setContent}) {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <>
            <h2>{t("quiz-game-result-overview-title")}</h2>

            <Tooltip title="Go back to the quiz homepage">
                <IconButton className={classes.button}
                            aria-label="home-quiz"
                            color="primary"
                            onClick={() => setContent(undefined)}>
                    <HomeIcon/>
                </IconButton>
            </Tooltip>

            <QuizResultQuestionList questions={questions}
                                    selectedAnswers={selectedAnswers}/>
        </>
    )
}

export default QuizResult;
