import React, {useContext, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {REG_EX_PASSWORD} from "../properties/form";
import useProfileTextField from "./useProfileTextField";
import ProfileTextField from "./ProfileTextField";
import {WARNING} from "../../snackbar/Variant";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../snackbar/Settings";
import {QuizContext} from "../../quiz/context/QuizContext";
import Call from "../../server/Call";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    title: {
        textAlign: 'center'
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

/**
 * The form for entering the current password as confirmation of the change of data.
 *
 * @param isFormOpen true if this dialog / form is to be opened, otherwise false
 * @param closeForm function to close this dialog
 * @param username obtained from the profile of the signed in user (needed to confirm the password)
 * @param email obtained from the profile of the signed in user (needed to confirm the password)
 * @param confirmProfileChange a function to save changes to the profile that will be called after confirming the correct password of the signed in user
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 12.02.2020 17:12
 */

function ConfirmProfileChangeForm({isFormOpen, closeForm, username, email, confirmProfileChange}) {

    const classes = useStyles();

    const quizContext = useContext(QuizContext);

    const {checkCorrectPassword} = Call();

    const {t} = useTranslation()

    const txtConfirmPassword = useProfileTextField("confirm-password",
        true,
        false,
        t("change-profile-txt-confirm-password-init-helper-text"),
        "current-password-text-field",
        t("change-profile-txt-confirm-password-init-label"),
        "password",
        t("change-profile-txt-confirm-password-init-placeholder"),
        "",
        REG_EX_PASSWORD);

    /**
     * Every time user open the dialog box, there is need to empty the text field.
     * <br/>
     * <i>Otherwise, user would only need to enter the password once, then it will remain pre-filled in the text field.</i>
     */
    useEffect(() => {
        if (isFormOpen) {
            txtConfirmPassword.setValue("")
        }
    }, [isFormOpen]);

    /**
     * Save changes to the profile.
     * <br/>
     * <i>First, it is ascertained whether the user entered the correct password, if so, the changes to the profile are saved.</i>
     */
    function saveProfileChanges() {
        if (!validatePassword()) {
            return;
        }

        const dtoIn = createCheckCorrectPasswordDtoIn();
        checkCorrectPassword(dtoIn, checkCorrectPasswordDtoOut => {
            if (checkCorrectPasswordDtoOut.passwordCorrect) {
                confirmProfileChange(txtConfirmPassword.value)
            } else {
                showSnackBarDialogWarning(checkCorrectPasswordDtoOut.message)
            }
        })
    }

    function validatePassword() {
        if (txtConfirmPassword.value.replace(/\s*/, "") === "") {
            showSnackBarDialogWarning(t("change-profile-confirm-password-not-specified"));
            return false;
        }

        if (!txtConfirmPassword.validate()) {
            showSnackBarDialogWarning(t("change-profile-confirm-password-wrong-syntax"));
            return false;
        }

        return true;
    }

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    const createCheckCorrectPasswordDtoIn = () => {
        return {
            username,
            email,
            password: txtConfirmPassword.value
        }
    };

    return (
        <Dialog aria-labelledby="confirm-profile-change-form-title"
                open={isFormOpen}>
            <DialogTitle id="confirm-profile-change-form-title" onClose={closeForm} className={classes.title}>
                {t("change-profile-title")}
            </DialogTitle>
            <DialogContent dividers>
                <Container component="main" maxWidth="xs">
                    <form>
                        <ProfileTextField use={txtConfirmPassword}/>
                    </form>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={saveProfileChanges} color="primary">
                    {t("change-profile-btn-confirm")}
                </Button>
                <Button autoFocus onClick={closeForm} color="primary">
                    {t("change-profile-btn-cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmProfileChangeForm;
