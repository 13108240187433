import {useState} from 'react';

/**
 * Spinner display settings. Whether show or not.
 *
 * @author Jan Krunčík
 * @since 06.09.2019 16:57
 */

function useSpinner() {

    const [isSpinning, setIsSpinning] = useState(false);

    return {
        isSpinning,
        setIsSpinning
    }
}

export default useSpinner;