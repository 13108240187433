import {useState} from 'react';

/**
 * It is used for dialog manipulation.
 * <br/>
 * Use this component to store a value that tells, whether to open or close the dialog.
 *
 * @author Jan Krunčík
 * @since 30.08.2019 16:43
 */

function useForm() {

    const [isFormOpen, setIsFormOpen] = useState(false);

    function openForm() {
        setIsFormOpen(true);
    }

    function closeForm() {
        setIsFormOpen(false);
    }

    return {
        isFormOpen,
        openForm,
        closeForm
    }
}

export default useForm;