import React from 'react';
import QuestionConfiguration from "./question/QuestionConfiguration";
import {useTranslation} from "react-i18next";

/**
 * Here you solve the setup / configuration of the quiz game. For example, setting the number of guessing questions, the number of lives, etc.
 *
 * @author Jan Krunčík
 * @since 15.09.2019 11:39
 */

function QuizConfiguration() {

    const {t} = useTranslation()

    return (
        <>
            <h1>{t("quiz-configuration-title")}</h1>

            <QuestionConfiguration/>
        </>
    )
}

export default QuizConfiguration;
