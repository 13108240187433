import {useContext} from 'react';
import ServerCall from "./ServerCall";
import {ERROR, SUCCESS, WARNING} from "../snackbar/Variant";
import {QuizContext} from "../quiz/context/QuizContext";
import AuthService from "./auth/AuthService";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../snackbar/Settings";
import {useTranslation} from "react-i18next";

const FileDownload = require('js-file-download');

/**
 * Calling methods in ServerCall that handle communication with the server.
 *
 * This handles the exception handling, displaying the operation result messages (success, error, etc.) and displaying the loading dialog (spinner).
 *
 * @author Jan Krunčík
 * @since 06.09.2019 9:58
 */

function Call() {

    const {setSignedInUserData} = AuthService();

    const quizContext = useContext(QuizContext);

    const {t} = useTranslation()

    function addPlayer(addPlayerDtoIn, callBack) {
        showSpinner(true);

        ServerCall.addPlayer(addPlayerDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    /**
     * Finding out whether a player with a username and email in the existPlayerRequest object exist (in the database) or not.
     *
     * @param existPlayerRequest the player's username and email to see if this combination is in the database
     * @param callBack it will be called in case of successful return of the response from the server (without error), it will pass the result - response from the server
     */
    function existPlayer(existPlayerRequest, callBack) {
        showSpinner(true);

        ServerCall.existPlayer(existPlayerRequest)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    /**
     * Obtaining a specific player (who played the last game) played a game.
     *
     * @param playerGamesListDtoIn data to retrieve and sort player games played for a particular page
     * @param callBack functions that pass data retrieved from the server (ensures the display of data in the table)
     */
    function playerGamesList(playerGamesListDtoIn, callBack) {
        showSpinner(true);

        ServerCall.playerGamesList(playerGamesListDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    /**
     * Calling the API method to retrieve the list of played games (/ scores) of all players, but the output object will contain (among other things) the index of the page where the game with the id specified in dtoIn is located.
     *
     * @param scoreListForPlayerDtoIn input object containing data for paging and sorting settings of found games (/ records) and game id for which the page in the output object is to be determined
     * @param callBack functions that pass data retrieved from the server (ensures the display of data in the table)
     */
    function scoreListForPlayer(scoreListForPlayerDtoIn, callBack) {
        ServerCall.scoreListForPlayer(scoreListForPlayerDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
            })
    }

    function playerList(playerListDtoIn, callBack) {
        showSpinner(true);

        ServerCall.playerList(playerListDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-player-list-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function exportAllPlayersToCsv() {
        showSpinner(true);

        ServerCall.exportAllPlayersToCsv()
            .then(response => {
                FileDownload(response.data, 'players.csv');
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function exportAllPlayersToExcel() {
        showSpinner(true);

        ServerCall.exportAllPlayersToExcel()
            .then(response => {
                FileDownload(response.data, 'players.xlsx');
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function addGame(addGameDtoIn, callBack) {
        showSpinner(true)

        ServerCall.addGame(addGameDtoIn)
            .then((response) => {
                showSuccessSnackBarDialog(t("call-player-game-successfully-saved"))
                callBack(getDataSuccessFromResponse(response))
                showSpinner(false)
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
                showSpinner(false)
            })
    }

    function deleteAllGames() {
        showSpinner(true)

        ServerCall.deleteAllGames()
            .then((response) => {
                showSuccessSnackBarDialog(getDataSuccessFromResponse(response))
                showSpinner(false)
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
                showSpinner(false)
            })
    }

    function scoreList(scoreListDtoIn, callBack) {
        showSpinner(true);

        ServerCall.scoreList(scoreListDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    /**
     * Get the id of the correct answer to the question with the id in the questionId parameter
     *
     * @param questionId id of the question to which the id of the correct answer should be returned
     * @param callBack from the server (correctness of the answer), which is passed to the callBack function as a parameter
     */
    function getCorrectAnswerId(questionId, callBack) {
        ServerCall.getCorrectAnswerId(questionId)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                callBack(undefined);
            })
    }

    function generateQuestions(callBack) {
        showSpinner(true);

        ServerCall.generateQuestions()
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                callBack([]);
                showSpinner(false);
            })
    }

    function signIn(loginRequest, callback) {
        showSpinner(true);

        ServerCall.signIn(loginRequest)
            .then(response => {
                const accessToken = getAccessTokenFromResponse(response)
                const roles = getUserRolesFromResponse(response)
                const accessTokenExpiration = getAccessTokenExpirationFromResponse(response)
                setSignedInUserData(accessToken, roles, accessTokenExpiration)
                callback()
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function signUp(registrationRequest, callBack) {
        showSpinner(true);

        ServerCall.signUp(registrationRequest)
            .then(() => {
                callBack();
                showSuccessSnackBarDialog(t("call-sign-up-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function getQuestionList(dtoIn, callBack) {
        showSpinner(true);

        ServerCall.getQuestionList(dtoIn)
            .then(response => {
                if (response.status === 204) {
                    callBack([], 0);
                    showWarningSnackBarDialog(t("call-get-question-list-no-question"))
                } else {
                    const dataFromResponse = getDataSuccessFromResponse(response);
                    callBack(dataFromResponse.questionWithAnswersDtoList, dataFromResponse.count);
                    showSuccessSnackBarDialog(t("call-get-question-list-success"));
                }
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function addQuestion(question, callBack) {
        showSpinner(true);

        ServerCall.addQuestion(question)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-add-question-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function updateQuestion(question, callBack) {
        showSpinner(true);

        ServerCall.updateQuestion(question)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-update-question-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function deleteQuestionById(id, callBack) {
        showSpinner(true);

        ServerCall.deleteQuestionById(id)
            .then(() => {
                callBack();
                showSuccessSnackBarDialog(t("call-delete-question-by-id-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function deleteAllQuestions(callBack) {
        showSpinner(true);

        ServerCall.deleteAllQuestions()
            .then(() => {
                callBack();
                showSuccessSnackBarDialog(t("call-delete-all-questions-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function deleteQuestionAnswers(questionId, callBack) {
        showSpinner(true);

        ServerCall.deleteQuestionAnswers(questionId)
            .then(() => {
                callBack();
                showSuccessSnackBarDialog(t("call-delete-question-answers-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function getQuestionAnswerValidationInformation(dtoIn, callBack) {
        ServerCall.getQuestionAnswerValidationInformation(dtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
            })
    }

    function addAnswer(answer, callBack) {
        showSpinner(true);

        ServerCall.addAnswer(answer)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-add-answer-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function updateAnswer(answer, callBack) {
        showSpinner(true);

        ServerCall.updateAnswer(answer)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call--update-answer-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function deleteAnswerById(id, callBack) {
        showSpinner(true);

        ServerCall.deleteAnswerById(id)
            .then(() => {
                callBack();
                showSuccessSnackBarDialog(t("call-delete-answer-by-id-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function setCorrectAnswer(correctAnswer, callBack) {
        showSpinner(true);

        ServerCall.setCorrectAnswer(correctAnswer)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-set-correct-answer-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function getProfileData(callBack) {
        showSpinner(true);

        ServerCall.getProfileData()
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function checkCorrectPassword(checkCorrectPasswordDtoIn, callBack) {
        showSpinner(true);

        ServerCall.checkCorrectPassword(checkCorrectPasswordDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function changeProfile(changeProfileDtoIn, callBack) {
        showSpinner(true);

        ServerCall.changeProfile(changeProfileDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response).profileDataDtoOut);
                showSuccessSnackBarDialog(t("call-change-profile-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function changePassword(changePasswordDtoIn, callBack) {
        showSpinner(true);

        ServerCall.changePassword(changePasswordDtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-change-password-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function userList(dtoIn, callBack) {
        showSpinner(true);

        ServerCall.userList(dtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-user-list-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            })
    }

    function getQuestionsStatus(callBack, errorCallback = () => {
    }) {
        ServerCall.getQuestionsStatus()
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                errorCallback()
            });
    }

    function getDashboardOverview(callBack) {
        showSpinner(true);

        ServerCall.getDashboardOverview()
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function getConfiguration(dtoIn, callBack) {
        showSpinner(true);

        ServerCall.getConfiguration(dtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function updateConfiguration(dtoIn, callBack) {
        showSpinner(true);

        ServerCall.updateConfiguration(dtoIn)
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
                showSuccessSnackBarDialog(t("call-update-configuration-success"));
                showSpinner(false);
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
                showSpinner(false);
            });
    }

    function getNumberOfQuestionCategories(callBack) {
        ServerCall.getNumberOfQuestionCategories()
            .then(response => {
                callBack(getDataSuccessFromResponse(response));
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error));
            });
    }

    /**
     * Call an API method to get detail (/ information) about kindOfSound sound.
     *
     * @param kindOfSound the type of sound (within the game) for which information is to be obtained
     * @param successCallBack a function that will be called if the required information about the desired sound is successfully obtained
     * @param errorCallBack a function that will be called if an error occurs when calling the API method
     */
    function soundDetail(kindOfSound, successCallBack, errorCallBack) {
        ServerCall.soundDetail(kindOfSound)
            .then(response => {
                successCallBack(getDataSuccessFromResponse(response))
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
                errorCallBack()
            })
    }

    /**
     * Call an API method to upload (/ update) an audio file.
     *
     * @param dtoIn input object with information about the new sound file and identification of the sound to be replaced
     * @param callBack function that will be called if the desired audio file is successfully updated
     */
    function updateSound(dtoIn, callBack) {
        showSpinner(true)

        ServerCall.updateSound(dtoIn)
            .then(response => {
                showSuccessSnackBarDialog(t("call-upload-sound-success"))
                showSpinner(false)
                callBack(getDataSuccessFromResponse(response))
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
                showSpinner(false)
            })
    }

    /**
     * Call the API method to download the audio file.
     *
     * @param kindOfSound the type of audio file (as part of playing the game) to be downloaded
     * @param fileName file name under which to save to the user's device (downloaded file)
     */
    function downloadSound(kindOfSound, fileName) {
        showSpinner(true)

        ServerCall.downloadSound(kindOfSound)
            .then(response => {
                FileDownload(response.data, fileName)
                showSpinner(false)
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
                showSpinner(false)
            })
    }

    /**
     * Download a PDF file that is identified by a name (/ key) in the 'fileNameKey' parameter.
     *
     * @param fileNameKey key identifying the PDF file to be downloaded
     * @param fileName the name of the file that will be saved to the device on the device (the name of the downloaded file)
     */
    function downloadPdfFile(fileNameKey, fileName) {
        showSpinner(true)

        ServerCall.downloadPdfFile(fileNameKey)
            .then(response => {
                FileDownload(response.data, fileName)
                showSpinner(false)
            })
            .catch(error => {
                showErrorSnackBarDialog(getMessageFromErrorResponse(error))
                showSpinner(false)
            })
    }

    function getAccessTokenFromResponse(response) {
        return getDataSuccessFromResponse(response).token;
    }

    function getUserRolesFromResponse(response) {
        return getDataSuccessFromResponse(response).authorities;
    }

    function getAccessTokenExpirationFromResponse(response) {
        return getDataSuccessFromResponse(response).accessTokenExpiration;
    }

    function getDataSuccessFromResponse(response) {
        return response.data.data;
    }

    function getMessageFromErrorResponse(error) {
        // If an unexpected state occurs and the Failure object is not returned, the error message must be obtained as follows
        if (error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        }

        if (error && error.response && error.response.headers) {
            const headerMessage = error.response.headers["message"];
            if (headerMessage) {
                return headerMessage
            }
        }

        return error.message;
    }

    function showSuccessSnackBarDialog(message) {
        showSnackBarDialog(SUCCESS, message);
    }

    function showWarningSnackBarDialog(message) {
        showSnackBarDialog(WARNING, message);
    }

    function showErrorSnackBarDialog(message) {
        showSnackBarDialog(ERROR, message);
    }

    /**
     * Displays a dialog with the variant in sbVariant and a notification in the message.
     *
     * @param sbVariant variant / style that the dialog should be displayed
     * @param message message to be displayed in the dialog
     */
    function showSnackBarDialog(sbVariant, message) {
        quizContext.snackBar.openSnackBar(sbVariant, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    function showSpinner(show) {
        quizContext.spinner.setIsSpinning(show);
    }

    return {
        addPlayer,
        existPlayer,
        playerGamesList,
        scoreListForPlayer,
        playerList,
        exportAllPlayersToCsv,
        exportAllPlayersToExcel,
        addGame,
        deleteAllGames,
        scoreList,
        getCorrectAnswerId,
        generateQuestions,
        signIn,
        signUp,
        getQuestionList,
        addQuestion,
        updateQuestion,
        deleteQuestionById,
        deleteAllQuestions,
        deleteQuestionAnswers,
        getQuestionAnswerValidationInformation,
        addAnswer,
        updateAnswer,
        deleteAnswerById,
        setCorrectAnswer,
        getProfileData,
        checkCorrectPassword,
        changeProfile,
        changePassword,
        userList,
        getQuestionsStatus,
        getDashboardOverview,
        getConfiguration,
        updateConfiguration,
        getNumberOfQuestionCategories,
        soundDetail,
        updateSound,
        downloadSound,
        downloadPdfFile
    }
}

export default Call;
