import React, {useEffect, useState} from 'react'
import {LIVES_BREAKAGE, LIVES_EMPTY, LIVES_FULL} from "./livesImageEnum"
import FavoriteIcon from "@material-ui/icons/Favorite"
import {makeStyles} from "@material-ui/core/styles"
import HeartBreakage from "./image/heart-breakage.gif"

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: "7%"
    }
}))

/**
 * Hook to display player lives.
 * <br/>
 * <i>Either a red filled heart will be displayed, representing that the player has the appropriate life.</i>
 * <br/>
 * <i>Or a 'heart-breaking' animation will be set to show that the player has lost his life. Then a black heart will be displayed.</i>
 *
 * @author Jan Krunčík
 * @since 25.09.2020 0:17
 */
function useLivesImage() {

    const classes = useStyles()

    const [livesImage, setLivesImage] = useState(LIVES_FULL)

    /**
     * Returns an image representing whether or not the player has a life, or an animation representing that the player answered the question incorrectly and lost his life.
     *
     * @param index an index representing a specific image in the array
     * @returns {JSX.Element} one of the images described above (animation of loss of life, red heart representing life or black heart that the player lost a life)
     */
    function getLivesImageToDisplay(index) {
        if (livesImage === LIVES_FULL) {
            return <FavoriteIcon key={index} color={"secondary"}/>
        } else if (livesImage === LIVES_EMPTY) {
            return <FavoriteIcon key={index}/>
        } else if (livesImage === LIVES_BREAKAGE) {
            return <img key={index} src={HeartBreakage} alt={"lives-breakage"} className={classes.image}/>
        }
    }

    // The default is a red heart representing life. If a 'heart break' animation is set, it will be displayed and after a while a black heart representing the loss of life will be display
    useEffect(() => {
        let interval = null
        if (livesImage === LIVES_BREAKAGE) {
            interval = setInterval(() => {
                setLivesImage(LIVES_EMPTY)
            }, 1480)
        }
        return () => clearInterval(interval)
    }, [livesImage])

    return {
        setLivesImage,
        getLivesImageToDisplay
    }
}

export default useLivesImage
