import React from 'react';
import PlayerTable from "./table/PlayerTable";
import ExportPlayers from "./export/ExportPlayers";
import {useTranslation} from "react-i18next";

/**
 * Management of players who played the game / quiz and stored their data (email and chosen username) in the database.
 *
 * @author Jan Krunčík
 * @since 15.09.2019 11:36
 */

function Players() {

    const {t} = useTranslation()

    return (
        <>
            <h1>{t("players-title")}</h1>

            <ExportPlayers/>

            <PlayerTable/>
        </>
    )
}

export default Players;
