import {useEffect} from 'react'

/**
 * The constant "removes" warnings caused by missing envy in the second parameter of the useEffect function.
 * <br/>
 * If the useEffect function is used only for calls when rendering the page for the first time, an empty field must be specified as the second parameter. However, this will cause messages to be displayed in the console due to missing dependencies in the mentioned field.
 * <br/>
 * Therefore, this constant has been written to "wrap" the useEffect function so that it will not require any surrounding dependencies and will not print any warnings to the console.
 * <br/>
 * https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once
 *
 * @author Jan Krunčík
 * @since 18.09.2020 19:05
 */
export const useMountEffect = fn => useEffect(fn, [])
