import React from 'react';
import TextField from "@material-ui/core/TextField";

/**
 * The component serves as a text field to which useFormInput is passed through the props, from which the necessary values are taken.
 *
 * @author Jan Krunčík
 * @since 25.08.2019 20:42
 */

const TextFieldForm = props => {

    return (
        <TextField
            autoFocus={props.use.autoFocus}
            margin={props.use.margin}
            id={props.use.id}
            name={props.use.id}
            label={props.use.label}
            value={props.use.value}
            onChange={props.use.handleChange}
            type={props.use.type}
            fullWidth
            required={props.use.required}
            autoComplete={props.use.autoComplete}
            variant={props.use.variant}
            error={(props.use.value.trim() !== "") && !props.use.validate()}
        />
    )
};

export default TextFieldForm;