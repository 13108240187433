import {useContext, useState} from 'react';
import {QuizContext} from "../../../quiz/context/QuizContext";
import {WARNING} from "../../../snackbar/Variant";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../../snackbar/Settings";
import {ROLE_USER} from "../../question/content/form/roles";
import {useTranslation} from "react-i18next";

/**
 * Operations with roles / permissions of the user whose account is to be created.
 *
 * @author Jan Krunčík
 * @since 24.09.2019 20:50
 */

function useRole() {

    const [roles, setRoles] = useState([ROLE_USER]);
    const quizContext = useContext(QuizContext);

    const {t} = useTranslation()

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    function addRole(role) {
        if (checkUniqueRole(role)) {
            showSnackBarDialogWarning(t("user-role-role-already-set-message"));
            return false;
        }

        setRoles(oldRoles => [...oldRoles, role]);
        return true;
    }

    function checkUniqueRole(role) {
        return roles.some(r => r.name === role.name);
    }

    /**
     * Remove the role with text in the function parameter.
     *
     * <i>A role will only be removed if more than one role is selected.</i>*
     *
     * @param role the name of the role to remove under the above mentioned condition
     */
    function removeRole(role) {
        if (roles.length > 1) {
            setRoles([...roles.filter(r => r.name !== role.name)]);
        }
    }

    /**
     * Returns selected roles in the text so that they can map to objects on BE.
     *
     * @returns {*[]} roles in text form in capital letters
     */
    function getRolesInString() {
        return roles.map(role => {
            return role.name;
        });
    }

    return {
        roles,
        addRole,
        removeRole,
        getRolesInString
    }
}

export default useRole;
