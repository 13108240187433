import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import ShapeCustomChartTooltip from "./ShapeCustomChartTooltip";
import {scaleOrdinal} from 'd3-scale';
import {schemeCategory10} from 'd3-scale-chromatic';
import {useTranslation} from "react-i18next";

const colors = scaleOrdinal(schemeCategory10).range();

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const {fill, x, y, width, height} = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number
};

/**
 * Displays an overview of the number of records in the database in a bar graph.
 *
 * @param dashboardOverview obtained values for display in the graph
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 03.07.2020 10:25
 */
function ShapeColumnChart({dashboardOverview}) {

    const [data, setData] = useState([])

    const {t} = useTranslation()

    useEffect(() => {
        if (!dashboardOverview) {
            return
        }

        const tmpData = [
            {name: t("shape-column-chart-questions-given-by-text"), count: dashboardOverview.questionCountGivenByText},
            {
                name: t("shape-column-chart-questions-given-by-image"),
                count: dashboardOverview.questionCountGivenByImage
            },
            {name: t("shape-column-chart-valid-questions-count"), count: dashboardOverview.validQuestionCount},
            {name: t("shape-column-chart-invalid-questions-count"), count: dashboardOverview.invalidQuestionCount},
            {name: t("shape-column-chart-answers-given-by-text"), count: dashboardOverview.answerCountGivenByText},
            {name: t("shape-column-chart-answers-given-by-image"), count: dashboardOverview.answerCountGivenByImage},
            {name: t("shape-column-chart-registered-users"), count: dashboardOverview.userCount},
            {name: t("shape-column-chart-players"), count: dashboardOverview.playerCount},
            {name: t("shape-column-chart-played-games"), count: dashboardOverview.gameCount}
        ];

        setData(tmpData)
    }, [dashboardOverview])

    return (
        <ResponsiveContainer
            width="95%"
            height={400}
        >
            <BarChart
                data={data}
                margin={{top: 50}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip content={<ShapeCustomChartTooltip/>}/>
                <Bar dataKey="count" fill="#8884d8" shape={<TriangleBar/>} label={{position: 'top'}}>
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 20]}/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

ShapeColumnChart.propTypes = {
    dashboardOverview: PropTypes.shape({
        questionCountGivenByText: PropTypes.number,
        questionCountGivenByImage: PropTypes.number,
        validQuestionCount: PropTypes.number,
        invalidQuestionCount: PropTypes.number,
        answerCountGivenByText: PropTypes.number,
        answerCountGivenByImage: PropTypes.number,
        userCount: PropTypes.number,
        playerCount: PropTypes.number,
        gameCount: PropTypes.number
    })
};

export default ShapeColumnChart;
