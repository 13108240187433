import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {ASC, DESC} from "../../../quiz/playersscore/table/TableProperties";
import Call from "../../../server/Call";
import {FILTER_BY_USERNAME} from "../../../quiz/score/filterProperties";
import Paper from "@material-ui/core/Paper";
import {PlayerToolbarContext} from "../context/PlayerToolbarContext";
import TableContainer from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import PlayerTableHead from "./PlayerTableHead";
import {TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {getSecureText} from "../../../xss/xss";
import TableFooter from "@material-ui/core/TableFooter";
import PlayerTablePagination from "./PlayerTablePagination";
import PlayerTableToolbar from "./PlayerTableToolbar";
import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';
import Tooltip from '@material-ui/core/Tooltip';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '95%',
        margin: 'auto'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

/**
 * Display of players in the table.
 *
 * @author Jan Krunčík
 * @since 11.06.2020 22:02
 */

function PlayerTable() {

    const classes = useStyles(() => {
    });

    const {t} = useTranslation()

    const [order, setOrder] = React.useState(DESC);
    const [orderBy, setOrderBy] = React.useState(FILTER_BY_USERNAME);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dense, setDense] = React.useState(false);

    const {playerList} = Call();
    const [players, setPlayers] = useState([]);
    const [totalPlayersCount, setTotalPlayersCount] = useState(0);

    const [filter, setFilter] = useState('');
    const [filterBy, setFilterBy] = useState(FILTER_BY_USERNAME);

    const createPlayerListDtoIn = () => {
        return {
            filter,
            filterBy,
            order,
            orderBy,
            page,
            rowsPerPage
        }
    }

    const loadPlayerList = () => {
        const dtoIn = createPlayerListDtoIn();
        playerList(dtoIn, playerListDtoOut => {
            if (playerListDtoOut.players === 0 && page > 0) {
                setPage(0)
            }
            setPlayers(playerListDtoOut.players);
            setTotalPlayersCount(playerListDtoOut.count)
        })
    }

    useEffect(() => {
        loadPlayerList()
    }, [order, orderBy, page, rowsPerPage])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === ASC;
        setOrder(isAsc ? DESC : ASC);
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = event => {
        setDense(event.target.checked);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalPlayersCount - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Tooltip title={t("player-table-btn-reload-players")}>
                    <IconButton className={classes.button}
                                aria-label="reload"
                                onClick={loadPlayerList}
                    >
                        <CachedIcon/>
                    </IconButton>
                </Tooltip>
                <PlayerToolbarContext.Provider value={{filter, setFilter, filterBy, setFilterBy, loadPlayerList}}>
                    <PlayerTableToolbar/>
                </PlayerToolbarContext.Provider>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <PlayerTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {players.map((player, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}>
                                        <TableCell align="center">{getSecureText(player.username)}</TableCell>
                                        <TableCell align="center">{getSecureText(player.email)}</TableCell>
                                    </TableRow>
                                )
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                    <TableCell colSpan={2}/>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <PlayerTablePagination
                                    totalPlayersCount={totalPlayersCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                label={t("player-table-toggle-dense-padding")}
            />
        </div>
    )
}

export default PlayerTable;
