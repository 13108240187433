import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5)
    }
}));

/**
 * Component for paging settings - display the current number of items per page, arrows with page transitions.
 *
 * @author Jan Krunčík
 * @since 03.02.2020 22:12
 */
function TablePaginationActions(props) {

    const classes = useStyles(() => {
    });
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = event => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

/**
 * Render component with pagination for table.
 *
 * @param totalGamesCount total items (to go to last page)
 * @param rowsPerPage the number of items per page
 * @param page index of the page to display
 * @param handleChangePage page change feature
 * @param handleChangeRowsPerPage function to set the number of items per page
 * @param rowsPerPageOptions a one-dimensional array containing the number of items as individual values, which can be on a single page in a table
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 03.02.2020 22:12
 */

function PlayerGamesTablePagination({totalGamesCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions}) {

    const {t} = useTranslation()

    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            colSpan={4}
            count={totalGamesCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage={`${t("player-games-table-txt-rows-per-page")}:`}
        />
    )
}

export default PlayerGamesTablePagination;
