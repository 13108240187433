import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import QuestionOrAnswerTextOrImage from "../../../../quiz/image/QuestionOrAnswerTextOrImage";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    dialogContent: {
        textAlign: 'center'
    }
}));

/**
 * Dialog used to confirm deletion of one selected answer.
 *
 * @param isFormOpen true if the dialog is to be opened, otherwise false
 * @param closeForm function to close the dialog
 * @param answer the answer to be deleted is passed here only to display it's text or image
 * @param deleteAnswer function to delete the answer
 * @returns {*} confirmation dialog for deleting the selected answer
 * @constructor
 *
 * @author Jan Krunčík
 * @since 14.01.2020 22:44
 */
function ConfirmDeleteAnswerForm({isFormOpen, closeForm, answer, deleteAnswer}) {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <Dialog
            open={isFormOpen}
            aria-labelledby="confirm-delete-answer-dialog-title"
            aria-describedby="confirm-delete-answer-dialog-description"
        >
            <DialogTitle
                id="confirm-delete-answer-dialog-title">{t("confirm-delete-answer-title")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-delete-answer-dialog-description" className={classes.dialogContent}>
                    <QuestionOrAnswerTextOrImage
                        questionOrAnswer={answer}
                        isQuestion={false}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeForm} color="primary">
                    {t("confirm-delete-answer-btn-cancel")}
                </Button>
                <Button onClick={deleteAnswer} color="primary" autoFocus>
                    {t("confirm-delete-answer-btn-delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteAnswerForm;
