import React from 'react';
import ScoreTable from "./ScoreTable";
import {useTranslation} from "react-i18next";

/**
 * Displays a table with an overview (/ score) of all games played.
 *
 * @author Jan Krunčík
 * @since 09.02.2020 19:59
 */

function Score() {

    const {t} = useTranslation()

    return (
        <>
            <h1>{t("score-title")}</h1>
            <ScoreTable/>
        </>
    )
}

export default Score;
