import React, {useContext} from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {makeStyles} from "@material-ui/core";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../../../snackbar/Settings";
import {QuizContext} from "../../../../quiz/context/QuizContext";
import {WARNING} from "../../../../snackbar/Variant";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1)
    },
    input: {
        display: 'none'
    }
}));

const SUPPORTED_IMG_EXTENSIONS = ["image/jpeg", "image/png", "image/svg+xml"];
const SUPPORTED_IMG_EXTENSIONS_FOR_WARNING_MESSAGE = ["jpeg", "jpeg", "png", "svg"];

const MAX_FILE_SIZE = 3000000;

/**
 * Upload an image from user.
 *
 * @param function to which the uploaded file (image) is passed to its parameter
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 20.11.2019 17:08
 */

function ImageUploaderComponent({imageCallback}) {

    const classes = useStyles();
    const quizContext = useContext(QuizContext);

    const {t} = useTranslation()

    /**
     * Retrieves the uploaded file, tests it. Whether the file size does not exceed the maximum size allowed, the file extension, whether it is an allowed format image. If so, it will be uploaded, if not, a notification will appear to the user.
     *
     * @param event event with uploaded file
     */
    function uploadImage(event) {
        const file = event.target.files[0];

        if (file.size > MAX_FILE_SIZE) {
            showSnackBarDialogWarning(t("image-upload-component-max-file-size"));
            return;
        }

        // fixme zjistit, zdali ta pripona nema byt pod tou podminkou ?, jestli neco neni na GL v komentari?
        if (!SUPPORTED_IMG_EXTENSIONS.includes(file.type.toLowerCase())) {
            const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
            const message = `${t("image-upload-component-text-supported-formats")} '${SUPPORTED_IMG_EXTENSIONS_FOR_WARNING_MESSAGE}', ${t("image-upload-component-text-no")} '${fileExtension}'.`;
            showSnackBarDialogWarning(message);
            return;
        }

        imageCallback(file);
    }

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    return (
        <label>
            <Tooltip title={t("image-upload-component-btn-upload-image-tooltip")}>
                <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    component="span"
                    className={classes.fab}>
                    <CloudUploadIcon/>
                </Fab>
            </Tooltip>
            <input
                id={"input-image"}
                accept={".jpg, .jpeg, .png, .svg"}
                className={classes.input}
                type={"file"}
                multiple={false}
                onChange={uploadImage}
            />
        </label>
    )
}

export default ImageUploaderComponent;
