import React, {useEffect, useState} from 'react'
import SpectatorsDefault from "./image/spectators-default.gif"
import SpectatorsCorrectAnswer from "./image/spectators-correct-answer.gif"
import SpectatorsCorrectAnswerLastFrame from "./image/spectators-correct-answer-last-frame.png"
import {SPECTATOR_CORRECT_ANSWER, SPECTATOR_CORRECT_ANSWER_STATIC, SPECTATOR_DEFAULT} from "./spectatorImageEnum"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: "70%"
    }
}))

/**
 * Hook to display image (/ animation) of spectators (/ viewers).
 * <br/>
 * <i>The default image will be displayed. If the player chooses the correct answer to the question, the corresponding animation will be played and then the original image will displayed back.</i>
 *
 * @author Jan Krunčík
 * @since 24.09.2020 15:50
 */
function useSpectatorImage() {

    const classes = useStyles()

    const [spectatorImage, setSpectatorImage] = useState(SPECTATOR_DEFAULT)

    /**
     * Return the image for display.
     * <br/>
     * <i>Either the default image will be returned. If the player answered the question correctly, an animation showing the action will be returned.</i>
     *
     * @returns {JSX.Element} one of the images described above, either the default or an animation showing the correctly answered question
     */
    function getSpectatorImageToDisplay() {
        let src = SpectatorsDefault
        let alt = "spectators-default"

        if (spectatorImage === SPECTATOR_CORRECT_ANSWER) {
            src = SpectatorsCorrectAnswer
            alt = "spectators-correct-answer"
        } else if (spectatorImage === SPECTATOR_CORRECT_ANSWER_STATIC) {
            src = SpectatorsCorrectAnswerLastFrame
            alt = "spectators-correct-answer-static"
        }

        return <img src={src} alt={alt} className={classes.image}/>
    }

    // If an animation showing a correctly answered question is displayed, the original image will be displayed again after a certain time (playing the animation)
    useEffect(() => {
        let interval = null

        // When the animation display is set for a new question, a static image (not an animation) will be displayed after it is played, so that there is a smooth transition to the next (default) animation
        if (spectatorImage === SPECTATOR_CORRECT_ANSWER) {
            interval = setInterval(() => setSpectatorImage(SPECTATOR_CORRECT_ANSWER_STATIC), 3000)
        } else if (spectatorImage === SPECTATOR_CORRECT_ANSWER_STATIC) {
            interval = setInterval(() => setSpectatorImage(SPECTATOR_DEFAULT), 100)
        }

        return () => clearInterval(interval)
    }, [spectatorImage])

    return {
        setSpectatorImage,
        getSpectatorImageToDisplay
    }
}

export default useSpectatorImage
