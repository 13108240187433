import React from 'react';
import {
    FILTER_BY_EQUAL_TO_SCORE,
    FILTER_BY_GREATER_THAN_OR_EQUAL_TO_SCORE,
    FILTER_BY_LESS_THAN_OR_EQUAL_TO_SCORE,
    FILTER_BY_USERNAME
} from "./filterProperties";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FaceIcon from "@material-ui/icons/Face";
import {MDBIcon} from "mdbreact";
import {useTranslation} from "react-i18next";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

export const USERNAME_ICON = <FaceIcon/>;
const EQUAL_ICON = <MDBIcon icon="equals"/>;
const LESS_OR_EQUAL_ICON = <MDBIcon icon="less-than-equal"/>;
const GREATER_OR_EQUAL_ICON = <MDBIcon icon="greater-than-equal"/>;

/**
 * Drop-down menu that contains items for setting the column according to which records / items (/ games) in the table will be filtered.
 *
 * @param anchorEl settings for the menu, whether it should be open or not
 * @param closeMenu close menu function
 * @param handleFilterBy function to set values for filtering items
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 10.02.2020 0:45
 */

function FilterMenu({anchorEl, closeMenu, handleFilterBy}) {

    const {t} = useTranslation()

    const TXT_FILTER_BY_USERNAME = t("score-filter-menu-txt-filter-by-username");
    const TXT_FILTER_BY_EQUAL_TO_SCORE = t("score-filter-menu-txt-filter-by-equal-to-score");
    const TXT_FILTER_BY_GREATER_THAN_OR_EQUAL_TO_SCORE = t("score-filter-menu-txt-filter-by-greater-than-or-equal-to-score");
    const TXT_FILTER_BY_LESS_THAN_OR_EQUAL_TO_SCORE = t("score-filter-menu-txt-filter-by-less-than-or-equal-to-score");

    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
        >
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_USERNAME, TXT_FILTER_BY_USERNAME, USERNAME_ICON)}>
                <ListItemIcon>
                    {USERNAME_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_USERNAME}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_EQUAL_TO_SCORE, TXT_FILTER_BY_EQUAL_TO_SCORE, EQUAL_ICON)}>
                <ListItemIcon>
                    {EQUAL_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_EQUAL_TO_SCORE}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_LESS_THAN_OR_EQUAL_TO_SCORE, TXT_FILTER_BY_LESS_THAN_OR_EQUAL_TO_SCORE, LESS_OR_EQUAL_ICON)}>
                <ListItemIcon>
                    {LESS_OR_EQUAL_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_LESS_THAN_OR_EQUAL_TO_SCORE}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_GREATER_THAN_OR_EQUAL_TO_SCORE, TXT_FILTER_BY_GREATER_THAN_OR_EQUAL_TO_SCORE, GREATER_OR_EQUAL_ICON)}>
                <ListItemIcon>
                    {GREATER_OR_EQUAL_ICON}
                </ListItemIcon>
                <ListItemText primary={TXT_FILTER_BY_GREATER_THAN_OR_EQUAL_TO_SCORE}/>
            </StyledMenuItem>
        </StyledMenu>
    )
}

export default FilterMenu;
