import React from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {getQuestionOrAnswerImageUrl} from "../../../../server/ServerCall";
import {getSecureText} from "../../../../xss/xss";
import {useTranslation} from "react-i18next";
import clsx from "clsx"

const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: 5,
        border: '5px solid black',
        padding: 5,
        '@media (max-width:599px)': {
            padding: 0
        }
    },
    spanTextBg: {
        backgroundColor: "black"
    },
    text: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: "bold",
        color: "white",
        fontSize: 20,
        '@media (max-width:959px)': {
            fontSize: 18
        },
        '@media (max-width:599px)': {
            fontSize: 13
        }
    },
    image: {
        width: '100%'
    },
    bgColorBlack: {
        backgroundColor: "black"
    }
}));

/**
 * Displays the question the user will guess the answer to.
 *
 * @param question the question to display (its text or image)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 23.07.2020 15:24
 */
function Question({question}) {

    const classes = useStyles()

    const {t} = useTranslation()

    /**
     * Get the value to render the question.
     * <br/>
     * <i>If the question is given by an image, its image will be drawn. If it is given with text, its text will be drawn.</i>
     *
     * @returns {*} text or image questions to display
     */
    function getTextOrImage() {
        if (question.givenByImage) {
            return (
                <img src={getQuestionOrAnswerImageUrl(question.imgId)}
                     alt={t("quiz-question-img-alt")}
                     className={classes.image}/>
            )
        }
        return (
            <span className={classes.spanTextBg}>
                <span className={classes.text}>
                    {getSecureText(question.text)}
                </span>
            </span>
        )
    }

    return (
        <Paper className={clsx({
            [classes.paper]: true,
            [classes.bgColorBlack]: !question.givenByImage
        })}>
            {getTextOrImage()}
        </Paper>
    )
}

Question.defaultProps = {
    question: {}
}

Question.propTypes = {
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string,
        imgId: PropTypes.number,
        givenByImage: PropTypes.bool.isRequired,
        category: PropTypes.oneOf(["EASY", "MEDIUM", "HARD"]).isRequired,
        answers: PropTypes.array
    }).isRequired
}

export default Question;
