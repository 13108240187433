import React, {useState} from 'react';
import ShapeColumnChart from "./shape/ShapeColumnChart";
import DashboardPieChart from "./pie/DashboardPieChart";
import Call from "../../../../server/Call";
import MixColumnChart from "./column/MixColumnChart";
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useMountEffect} from "../../../../mount/useMountEffect";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1)
    }
}));

/**
 * Overview of data in the database shown in graphs.
 *
 * @author Jan Krunčík
 * @since 03.07.2020 13:31
 */

function DashboardChartOverview() {

    const {getDashboardOverview} = Call();
    const [dashboardOverview, setDashboardOverview] = useState();

    const classes = useStyles();

    const {t} = useTranslation()

    useMountEffect(() => {
        loadOverview()
    });

    function loadOverview() {
        getDashboardOverview(dtoOut => {
            setDashboardOverview(dtoOut)
        })
    }

    return (
        <>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<RefreshIcon/>}
                    onClick={loadOverview}
                >
                    {t("refresh")}
                </Button>
            </div>

            <MixColumnChart dashboardOverview={dashboardOverview}/>
            <ShapeColumnChart dashboardOverview={dashboardOverview}/>
            <DashboardPieChart dashboardOverview={dashboardOverview}/>
        </>
    )
}

export default DashboardChartOverview;
