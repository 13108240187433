import React from 'react'
import PropTypes from "prop-types"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Typography from "@material-ui/core/Typography"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import {useTranslation} from "react-i18next"

/**
 * Dialog asking the user if he wants to save the currently playing game.
 *
 * @param open true if the dialog is to be displayed, otherwise false
 * <br/>
 * @param fceConfirm function that will be called when confirming the dialog, so the user wants to save the game
 * <br/>
 * @param fceCancel function that will be called in case of rejection, so the user does not want to save the game (he wants to go to the desired page and leave the game without saving it)
 * <br/>
 * @returns {JSX.Element} dialog with the above-described question to the user if he wants to save the currently playing game
 * @constructor
 *
 * @author Jan Krunčík
 * @since 29.11.2020 22:47
 */
function SaveGameAskDialog({open, fceConfirm, fceCancel}) {

    const {t} = useTranslation()

    return (
        <Dialog
            aria-labelledby="save-game-ask-dialog-title"
            open={open}
        >
            <DialogTitle id="save-game-ask-dialog-title">
                {t("save-game-ask-dialog-title")}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {t("save-game-ask-dialog-question-to-save-game")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={fceCancel}
                    color="primary"
                >
                    {t("save-game-ask-dialog-btn-do-not-save-game")}
                </Button>
                <Button
                    onClick={fceConfirm}
                    color="primary"
                >
                    {t("save-game-ask-dialog-btn-save-game")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SaveGameAskDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    fceConfirm: PropTypes.func.isRequired,
    fceCancel: PropTypes.func.isRequired
}

export default SaveGameAskDialog
