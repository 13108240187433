import {useEffect, useState} from 'react';

// TODO Load the default value (/ bonus time) for example when user start the game (load time as a configuration)
export const INITIAL_START_VALUE = 30;

/**
 * It is used to count down the set time, according to which points (/ bonuses) are added to the user (if he answers the question quickly enough - before the time runs out).
 *
 * <i>When the game starts, it is set only once to isActive = true</i>
 * <br/>
 * <i>Thereafter, each time the question is confirmed, only the time (the countdown value) is reset</i>
 * <br/>
 * <i>The countdown will end when the game is over</i>
 *
 * @author Jan Krunčík
 * @since 11.10.2019 20:55
 */

function CountdownTimer() {

    const [countdownValue, setCountdownValue] = useState(INITIAL_START_VALUE);
    const [isActive, setIsActive] = useState(false);

    function startCountdown() {
        setIsActive(true);
    }

    function stopCountdown() {
        setIsActive(false);
    }

    function resetCountdown() {
        setCountdownValue(INITIAL_START_VALUE);
    }

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                if (countdownValue > 0) {
                    setCountdownValue(countdownValue - 1);
                }
            }, 1000)
        }
        return () => clearInterval(interval);
    }, [isActive, countdownValue]);

    return {
        countdownValue,
        isActive,
        startCountdown,
        stopCountdown,
        resetCountdown
    }
}

export default CountdownTimer;
