import React from 'react';
import PropTypes from "prop-types"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import PlayerGames from "../playersscore/PlayerGames";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {SCORE} from "../../route/url";

/**
 * View the results of the game played to the user.
 *
 * @param isFormOpen true if this dialog is to be opened, otherwise false if it is to be closed
 * @param closeForm a function that closes this dialog
 * @param displayResult a function that closes this dialog and displays the results of the game played, where the player sees correctly and incorrectly answered questions
 * @param time total time played
 * @param lastPlayedGame data about the last played game (database entity)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 25.08.2019 23:13
 */
function GameOverForm({isFormOpen, closeForm, displayResult, time, lastPlayedGame}) {

    const {t} = useTranslation()

    const history = useHistory()

    return (
        <Dialog
            open={isFormOpen}
            aria-labelledby="game-over-dialog-title"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle id="game-over-dialog-title">
                {t("game-over-form-title")}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {t("game-over-form-txt-score")}: {lastPlayedGame.score}
                </Typography>
                <Typography gutterBottom>
                    {t("game-over-form-txt-game-time")}: {time}
                </Typography>
                <PlayerGames lastPlayedGame={lastPlayedGame}/>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => history.push(SCORE)}
                >
                    {t("game-over-form-btn-go-to-score-page")}
                </Button>
                <Button onClick={displayResult} color="primary">
                    {t("game-over-form-txt-show-selected-answers")}
                </Button>
                <Button onClick={closeForm} color="primary">
                    {t("game-over-form-btn-ok-close-dialog")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

GameOverForm.propTypes = {
    isFormOpen: PropTypes.bool.isRequired,
    closeForm: PropTypes.func.isRequired,
    displayResult: PropTypes.func.isRequired,
    time: PropTypes.string.isRequired,
    lastPlayedGame: PropTypes.shape({
        id: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
        lives: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired
    }).isRequired
}

export default GameOverForm;
