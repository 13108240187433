import React from 'react'
import {useTranslation} from "react-i18next"
import DeleteAllGamesBtn from "./delete-all-games/DeleteAllGamesBtn"

/**
 * Management of all games played.
 *
 * @author Jan Krunčík
 * @since 25.09.2020 23:57
 */
function Games() {

    const {t} = useTranslation()

    return (
        <>
            <h1>{t("games-title")}</h1>

            <DeleteAllGamesBtn/>
        </>
    )
}

export default Games
