import {useState} from 'react';

/**
 * To save and work with the user's lives.
 *
 * @author Jan Krunčík
 * @since 25.08.2019 19:28
 */

function Live(initialValue) {

    const [value, setValue] = useState(initialValue);

    function setLives(lives) {
        setValue(lives);
    }

    function decreaseLife() {
        setValue(value - 1);
    }

    return {
        value,
        setLives,
        decreaseLife
    };
}

export default Live;