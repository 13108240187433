import React, {useContext, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {GameContext} from "../../../context/GameContext";
import {DEFAULT_LIVES} from "../../../property/Properties";
import {useTranslation} from "react-i18next";
import useLivesImage from "../../image/lives/useLivesImage";
import {LIVES_BREAKAGE, LIVES_FULL} from "../../image/lives/livesImageEnum";
import BonusProgressBar from "../bonus/BonusProgressBar"
import Button from "@material-ui/core/Button";
import StopIcon from "@material-ui/icons/Stop";
import {getQuestionCategoryByName} from "../../../../auth/question/content/constant/category";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "black",
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 5
    },
    scoreText: {
        color: "white",
        fontFamily: "Bangers, cursive",
        fontSize: 20,
        marginRight: 10
    },
    scoreGridItem: {
        // Vertically to the center:
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    scoreValueBorder: {
        borderRadius: 10,
        minWidth: 200,
        height: 60,
        border: '5px solid #e6005c',
        backgroundColor: "white"
    },
    scoreValue: {
        fontFamily: "Bangers, cursive",
        fontWeight: "bold",
        fontSize: 35,
    },
    questionNumberTextGrid: {
        color: "white",
        fontFamily: "Bangers, cursive",
        fontSize: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    livesGrid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    progressBarGrid: {
        marginTop: 15,
        marginBottom: 15
    },
    btnStopGridItem: {
        // Vertically to the center:
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnStop: {
        minWidth: 35,
        minHeight: 35,
        maxWidth: 35,
        maxHeight: 35
    },
    stopIcon: {
        color: "black"
    }
}))

const ColorButton = withStyles(tmpTheme => ({
    root: {
        color: tmpTheme.palette.getContrastText('#ffffff'),
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    }
}))(Button)

/**
 * View information about scores, lives, and current (/ played) questions (its order - number).
 *
 * @author Jan Krunčík
 * @since 17.07.2020 17:26
 */

function ScoreGrid() {

    const classes = useStyles()

    const {t} = useTranslation()

    const gameContext = useContext(GameContext)

    // The number of user lives for which the display will 'switch' to represent whether or not the user has a life
    // The number of lives in the array must be the same, which represents the number of lives in the variable 'DEFAULT_LIVES'
    const HEARTS = [
        useLivesImage(),
        useLivesImage(),
        useLivesImage()
    ]

    // Reactions to changing lives
    // If lives are set to the default number (value in variable 'DEFAULT_LIVES'), all hearts representing lives are set
    // If a player loses a life, an animation showing the loss of life is displayed, followed by an empty heart
    useEffect(() => {
        // Current number of player lives
        const lives = gameContext.lives.value
        // A new game has been launched, the user will be set the full number of lives (shown)
        if (lives === DEFAULT_LIVES) {
            HEARTS.map(heart => heart.setLivesImage(LIVES_FULL))
        } else if (lives >= 0 && lives < DEFAULT_LIVES) {
            // The player lost a life (answered incorrectly), an animation of the loss of life will be displayed and an 'empty / black heart' will be displayed -> representing the loss of life
            HEARTS[gameContext.lives.value].setLivesImage(LIVES_BREAKAGE)
        }
    }, [gameContext.lives.value])

    /**
     * Obtaining information (/ text) showing the index of the current question and its complexity in the form: 'category question current_question/all_quiz_questions_count'.
     *
     * @returns {string} the above text with information about the difficulty and order of the guess question if the user is playing the game, otherwise just index the question with zeros that the game is not playing and no question is guessed
     */
    function getQuestionNumberInfo() {
        const questionNumber = `${t("quiz-score-grid-question-number")} ${gameContext.getQuestionState()}`
        return gameContext.isGameRunning ? `${getQuestionCategory(gameContext.getQuestionToAsk())} ${questionNumber}` : questionNumber
    }

    /**
     * Get the name of the category (/ complexity) of the question in the appropriate language (/ text) to display on the page.
     *
     * @param question the question for which the name of its category is to be obtained in a form suitable for display on the page
     * @returns {string} the name of the question category in the form for display, if the question is not specified, empty text will be returned
     */
    function getQuestionCategory(question) {
        if (!question) {
            return ""
        }

        const questionCategory = getQuestionCategoryByName(question.category);
        return t(questionCategory.text)
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            spacing={1}
            className={classes.root}
        >
            <Grid item xs={12} sm={4} zeroMinWidth className={classes.questionNumberTextGrid}>
                {getQuestionNumberInfo()}
            </Grid>

            <Grid item xs={12} sm={4} zeroMinWidth className={classes.scoreGridItem}>
                <span className={classes.scoreText}>
                    {t("quiz-score-grid-txt-score")}
                </span>
                <span className={classes.scoreValueBorder}>
                    <span className={classes.scoreValue}>
                        {gameContext.score.value}
                    </span>
                </span>
            </Grid>

            <Grid item xs={12} sm={4} zeroMinWidth className={classes.livesGrid}>
                {HEARTS.map((heart, index) => heart.getLivesImageToDisplay(index))}
            </Grid>

            <Grid
                container
                direction="row"
                justify="center"
                spacing={1}
            >
                <Grid item xs={10} zeroMinWidth>
                    <div className={classes.progressBarGrid}>
                        <BonusProgressBar/>
                    </div>
                </Grid>
                <Grid xs={12} sm={1} md={1} lg={1} xl={1}
                      item
                      zeroMinWidth
                      className={classes.btnStopGridItem}
                >
                    <ColorButton
                        variant="contained"
                        color="primary"
                        className={classes.btnStop}
                        onClick={gameContext.isGameRunning ? gameContext.stopGame : undefined}
                    >
                        <StopIcon
                            fontSize="large"
                            className={classes.stopIcon}
                        />
                    </ColorButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ScoreGrid;
