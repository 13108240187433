import React from 'react';
import {useTranslation} from "react-i18next";

/**
 * A component is displayed when a user is trying to access a page / component that does not have sufficient permissions.
 *
 * @author Jan Krunčík
 * @since 10.10.2019 21:55
 */

function InsufficientPermissions() {

    const {t} = useTranslation()

    return (
        <>
            <h2>{t("insufficient-permissions-h2")}</h2>
            <div>{window.location.href}</div>
        </>
    )
}

export default InsufficientPermissions;
