import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import ScoreTableHead from "./ScoreTableHead";
import ScoreTableToolbar from "./ScoreTableToolbar";
import {ASC, DESC} from "../playersscore/table/TableProperties";
import {getGameTime} from "../timer/TimeSupport";
import Call from "../../server/Call";
import {ScoreToolbarContext} from "./context/ScoreToolbarContext";
import {FILTER_BY_USERNAME} from "./filterProperties";
import PlayerGamesTablePagination from "../playersscore/table/PlayerGamesTablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import {getSecureText} from "../../xss/xss";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '95%',
        margin: 'auto'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    }
}));

// Styles for highlighting an even / odd row in a table
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow)

/**
 * Scoreboard overview.
 * <br/>
 * Table source: https://material-ui.com/components/tables/#sorting-amp-selecting
 *
 * @author Jan Krunčík
 * @since 09.02.2020 20:06
 */
function ScoreTable() {

    const classes = useStyles(() => {
    });

    const {t} = useTranslation()

    const [order, setOrder] = React.useState(DESC);
    const [orderBy, setOrderBy] = React.useState('score');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const {scoreList} = Call();
    const [games, setGames] = useState([]);
    const [totalGamesCount, setTotalGamesCount] = useState(0);

    const [filter, setFilter] = useState('');
    const [filterBy, setFilterBy] = useState(FILTER_BY_USERNAME);

    // true if only students are to be filtered (players who have chosen to be students), otherwise false (all players will be filtered)
    const [filterStudents, setFilterStudents] = useState(false)

    useEffect(() => loadScoreList(), [order, orderBy, page, rowsPerPage, filterStudents])

    const createScoreListDtoIn = () => {
        return {
            filterStudents,
            filter,
            filterBy,
            order,
            orderBy,
            page,
            rowsPerPage
        }
    };

    const loadScoreList = () => {
        const dtoIn = createScoreListDtoIn();
        scoreList(dtoIn, scoreListDtoOut => {
            // If no content is found (empty) on the page, there is need to reduce the page (preferably to zero), otherwise there would be a paging error that the page index is out of range
            if (scoreListDtoOut.scoreList.length === 0 && page > 0) {
                setPage(0)
            }
            setGames(scoreListDtoOut.scoreList);
            setTotalGamesCount(scoreListDtoOut.count);
        })
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === ASC;
        setOrder(isAsc ? DESC : ASC);
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalGamesCount - page * rowsPerPage);

    function createCheckboxFilterOnlyStudents() {
        return (
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterStudents}
                            onChange={() => setFilterStudents(!filterStudents)}
                            color="primary"
                        />
                    }
                    label={t("score-table-chcb-filter-only-students-text")}
                />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {createCheckboxFilterOnlyStudents()}
                <ScoreToolbarContext.Provider value={{filter, setFilter, filterBy, setFilterBy, loadScoreList}}>
                    <ScoreTableToolbar/>
                </ScoreToolbarContext.Provider>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="enhanced table"
                    >
                        <ScoreTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {games.map((game, index) => {
                                const labelId = `games-score-${index}`;
                                return (
                                    <StyledTableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}>
                                        <StyledTableCell align="left">{game.rank}.</StyledTableCell>
                                        <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                            {getSecureText(game.username)}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{game.score}</StyledTableCell>
                                        <StyledTableCell align="right">{getGameTime(game.time)}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 33 * emptyRows}}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <PlayerGamesTablePagination
                                    totalGamesCount={totalGamesCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default ScoreTable;
