import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from '@material-ui/core/TableFooter';
import EnhancedTableHead from "../table/EnhancedTableHead";
import TableToolbar from "../table/TableToolbar";
import {makeStyles} from "@material-ui/core/styles";
import Call from "../../../server/Call";
import PlayerGamesTablePagination from "./PlayerGamesTablePagination";
import {ASC, DESC} from "./TableProperties";
import {getGameTime} from "../../timer/TimeSupport";
import Button from '@material-ui/core/Button';
import {getSecureText} from "../../../xss/xss";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        margin: 'auto'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    table: {
        maxWidth: 750,
        margin: "auto"
    },
    showLastGameDiv: {
        textAlign: 'center'
    }
}));

/**
 * A table to display game data for a specific player.
 *
 * @param lastPlayedGame data on the last played game of the respective player, which the user can display in the table
 * @returns {JSX.Element} the table of games played described above
 * @constructor
 *
 * @author Jan Krunčík
 * @since 02.02.2020 0:14
 */
function PlayerGamesTable({lastPlayedGame}) {

    const classes = useStyles();

    const {t} = useTranslation()

    const [order, setOrder] = React.useState(DESC);
    const [orderBy, setOrderBy] = React.useState('score');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);

    const {scoreListForPlayer} = Call();
    const [games, setGames] = useState([]);
    const [totalGamesCount, setTotalGamesCount] = useState(0);
    // Index of the page where the last game played (the one displayed in the GameOver dialog) is displayed
    const [lastGamePlayedPageIndex, setLastGamePlayedPageIndex] = useState(-1);

    const [firstLoadGames, setFirstLoadGames] = useState(true);

    const createScoreListForPlayerDtoIn = () => {
        return {
            order: order.toUpperCase(),
            orderBy,
            page,
            rowsPerPage,
            lastGamePlayedId: lastPlayedGame.id
        }
    };

    /**
     * Retrieval of games played by a particular player from the database according to set criteria (pages, sorting, number of items per page).
     */
    function loadScoreListForPlayer() {
        const dtoIn = createScoreListForPlayerDtoIn();
        scoreListForPlayer(dtoIn, scoreListForPlayerDtoOut => {
            setGames(scoreListForPlayerDtoOut.scoreList);
            setTotalGamesCount(scoreListForPlayerDtoOut.count);
            setLastGamePlayedPageIndex(scoreListForPlayerDtoOut.lastGamePlayedPageIndex)

            // Only the first time the results are displayed, the game played on a particular page is highlighted, after which the player can sort the results as needed
            if (firstLoadGames && scoreListForPlayerDtoOut.lastGamePlayedPageIndex >= 0) {
                setFirstLoadGames(false)
                setPage(scoreListForPlayerDtoOut.lastGamePlayedPageIndex)
            }
        })
    }

    /**
     * When player change the sorting of items by column, sorting in descending or ascending order, changing the page or the number of items on the page, the items are retrieved from the database, the items are then displayed.
     */
    useEffect(() => {
        loadScoreListForPlayer()
    }, [order, orderBy, page, rowsPerPage])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === ASC;
        setOrder(isAsc ? DESC : ASC);
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**
     * When there is no data in the table, the table remains as high as if there were a defined number of rows per page (number of records).
     *
     * @type {number} number of empty rows in the table (to maintain the table size according to the set number of items per page)
     */
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalGamesCount - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableToolbar/>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size='small'
                        aria-label="player games table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {games.map((game, index) => {
                                const labelId = `player-games-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                        selected={game.id === lastPlayedGame.id}
                                    >
                                        <TableCell align="left">{game.rank}.</TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none">
                                            {getSecureText(game.username)}
                                        </TableCell>
                                        <TableCell align="right">{game.score}</TableCell>
                                        <TableCell align="right">{getGameTime(game.time)}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 33 * emptyRows}}>
                                    <TableCell colSpan={4}/>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <PlayerGamesTablePagination
                                    totalGamesCount={totalGamesCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[3, 5, 10]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
            {lastGamePlayedPageIndex > -1 &&
            <div className={classes.showLastGameDiv}>
                <Button variant="contained" onClick={() => setPage(lastGamePlayedPageIndex)}>
                    {t("player-games-table-btn-show-last-game")}
                </Button>
            </div>}
        </div>
    )
}

PlayerGamesTable.propTypes = {
    lastPlayedGame: PropTypes.shape({
        id: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
        lives: PropTypes.number.isRequired,
        score: PropTypes.number.isRequired
    }).isRequired
}

export default PlayerGamesTable;
