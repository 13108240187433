import {useState} from 'react';

/**
 * Swapping values for a text field. Validation of value according to regular expression.
 *
 * @param initialState the default value for the text field
 * @param initialRegEx regular expression to validate the value in the text field
 * @returns {{handleChange: *, setEmptyValue: *, value: *, validate: *}}
 *
 * @author Jan Krunčík
 * @since 17.09.2019 20:29
 */
function useTextField(initialState, initialRegEx) {

    const [value, setValue] = useState(initialState);
    const [regEx] = useState(initialRegEx);

    function handleChange(e) {
        setValue(e.target.value);
    }

    function validate() {
        // If no regular expression is given, the text is returned valid, otherwise a text field with an invalid value would be displayed
        if (regEx === undefined) {
            return true;
        }

        if (value !== null && value.trim() === "") {
            return true;
        }

        return regEx.test(value);
    }

    function setEmptyValue() {
        setValue("");
    }

    function setTextValue(newValue) {
        setValue(newValue);
    }

    return {
        value,
        handleChange,
        validate,
        setEmptyValue,
        setTextValue
    }
}

export default useTextField;
