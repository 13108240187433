import React from 'react';
import {getQuestionOrAnswerImageUrl} from "../../server/ServerCall";
import {makeStyles} from "@material-ui/core/styles";
import {getSecureText} from "../../xss/xss";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        maxWidth: 600,
        maxHeight: 330
    }
}));

/**
 * Get alternate text to display if a question or answer image is not available.
 *
 * @param getQuestionAlt true if is needed to get alternate text for the missing image of the question, otherwise get text for the missing image of the answer
 * @param t function for obtaining text in the desired language
 * @returns {string} the alternative text described above for the missing question or answer image
 */
export function getImageAlt(getQuestionAlt, t) {
    return getQuestionAlt ? t("question-or-answer-text-or-image-alt-for-question") : t("question-or-answer-text-or-image-alt-for-answer");
}

/**
 * If the question or answer is given by text, the question or answer text is rendered. If the question or answer is given by image, the image is displayed.
 *
 * @param questionOrAnswer the question or answer whose text or image is to be displayed
 * @param isQuestion true if it is a question, otherwise false (answer)
 * @returns {*} render an image or text of a question or answer
 * @constructor
 *
 * @author Jan Krunčík
 * @since 10.01.2020 15:50
 */

function QuestionOrAnswerTextOrImage({questionOrAnswer, isQuestion}) {

    const classes = useStyles();

    const {t} = useTranslation()

    function getTextOrImage() {
        if (!questionOrAnswer) {
            return <span>{t("question-or-answer-text-or-image-question-or-answer-not-passed")}</span>
        }

        if (questionOrAnswer.givenByImage) {
            return <img src={getQuestionOrAnswerImageUrl(questionOrAnswer.imgId)}
                        alt={getImageAlt(isQuestion, t)}
                        className={classes.image}/>
        }
        return <span>{getSecureText(questionOrAnswer.text)}</span>
    }

    return getTextOrImage()
}

export default QuestionOrAnswerTextOrImage;
