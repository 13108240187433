import React, {useEffect, useState} from 'react';
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';
import PropTypes from "prop-types";
import {scaleOrdinal} from "d3-scale";
import {schemeDark2} from "d3-scale-chromatic";
import Tooltip from "recharts/lib/component/Tooltip";
import {useTranslation} from "react-i18next";

const colors = scaleOrdinal(schemeDark2).range();

/**
 * Displays an overview of the number of records in the database in a pie chart.
 *
 * @param dashboardOverview obtained values for display in the graph
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 03.07.2020 10:26
 */

function DashboardPieChart({dashboardOverview}) {

    const [data, setData] = useState([]);

    const {t} = useTranslation()

    useEffect(() => {
        if (!dashboardOverview) {
            return
        }

        const tmpData = [
            {name: t("pie-chart-column-questions-given-by-text"), value: dashboardOverview.questionCountGivenByText},
            {name: t("pie-chart-column-questions-given-by-image"), value: dashboardOverview.questionCountGivenByImage},
            {name: t("pie-chart-column-valid-questions-count"), value: dashboardOverview.validQuestionCount},
            {name: t("pie-chart-column-invalid-questions-count"), value: dashboardOverview.invalidQuestionCount},
            {name: t("pie-chart-column-answers-given-by-text"), value: dashboardOverview.answerCountGivenByText},
            {name: t("pie-chart-column-answers-given-by-image"), value: dashboardOverview.answerCountGivenByImage},
            {name: t("pie-chart-column-registered-users"), value: dashboardOverview.userCount},
            {name: t("pie-chart-column-players"), value: dashboardOverview.playerCount},
            {name: t("pie-chart-column-played-games"), value: dashboardOverview.gameCount},
        ]

        setData(tmpData);
    }, [dashboardOverview])

    return (
        <ResponsiveContainer
            width="95%"
            height={400}
        >
            <PieChart>
                <Tooltip/>
                <Legend
                    align="center"
                />
                <Pie
                    data={data}
                    labelLine={false}
                    label
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {
                        data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % 20]}/>)
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

DashboardPieChart.propTypes = {
    dashboardOverview: PropTypes.shape({
        questionCountGivenByText: PropTypes.number,
        questionCountGivenByImage: PropTypes.number,
        validQuestionCount: PropTypes.number,
        invalidQuestionCount: PropTypes.number,
        answerCountGivenByText: PropTypes.number,
        answerCountGivenByImage: PropTypes.number,
        userCount: PropTypes.number,
        playerCount: PropTypes.number,
        gameCount: PropTypes.number
    })
};

export default DashboardPieChart;
