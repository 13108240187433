import React from 'react';
import {Redirect, Route} from "react-router-dom";
import AuthService from "../server/auth/AuthService";
import {SIGN_IN} from "./url";
import InsufficientPermissions from "./InsufficientPermissions";

const {isUserLoggedIn} = AuthService();

/**
 * It is used to redirect to pages / display components only if the signed-in user has the authorizations that are needed to access that page.
 * <br/>
 * <i>If the user is not signed in, it will be redirected to the login page.</i>
 * <br/>
 * <i>If the signed in user does not have sufficient privileges, a component will be displayed that "tells / shows" this information to the user.</i>
 *
 * @author Jan Krunčík
 * @since 10.10.2019 18:58
 */

function RoleRoute(props) {

    const {getUserRoles} = AuthService()

    /**
     * Obtaining a rendering component based on the signed in user's role / permissions.
     *
     * @returns {*} component to render
     */
    function getComponent() {
        if (isUserLoggedIn() && userHasAllowedRoles()) {
            // The user is signed in and has sufficient permissions
            return <Route {...props}/>
        }
        if (isUserLoggedIn()) {
            // The user is signed in but does not have sufficient permissions
            return <InsufficientPermissions/>
        }
        // User is not signed in
        return <Redirect to={SIGN_IN}/>
    }

    /**
     * The signed in user's roles are retrieved from SessionStorage, to determine whether they are in the list of allowed roles, based on which it is possible to render the required component.
     *
     * @returns {boolean} true if the signed in user has permissions / roles to display the required components, otherwise false
     */
    function userHasAllowedRoles() {
        const {allowedRoles} = props;
        const userRoles = getUserRoles();

        if (!userRoles || userRoles.length === 0 || !allowedRoles || allowedRoles.length === 0) {
            return false;
        }

        // Whether sessionStorage (the role of the signed in user) contains the role(s) required to render / display the desired component
        const matchRolesAllowed = allowedRoles.filter(allowedRole => userRoles.includes(allowedRole))
        return matchRolesAllowed.length > 0
    }

    return (
        <>
            {getComponent()}
        </>
    )
}

export default RoleRoute;
