import React, {useState} from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import {FILTER_BY_USERNAME} from "./filterProperties";
import {REG_EX_FILTER_USERNAME} from "../property/Properties";
import {ScoreToolbarContext} from "./context/ScoreToolbarContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import FilterMenu, {USERNAME_ICON} from "./FilterMenu";
import {useTranslation} from "react-i18next";

const useSearchTextFieldStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: '0'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    }
}));

/**
 * Toolbar with text box to filter certain values (/ games).
 * <br/>
 * Toolbar source: https://material-ui.com/components/tables/#sorting-amp-selecting
 * <br/>
 * Search text field source: https://material-ui.com/components/text-fields/#customized-inputs
 * <br/>
 * Pictures / Icons with arrows from bootstrap:
 * - https://www.npmjs.com/package/mdbreact
 * - https://mdbootstrap.com/docs/react/content/icons-list/
 *
 * @author Jan Krunčík
 * @since 09.02.2020 20:12
 */
function ScoreTableToolbar() {

    const classes = useSearchTextFieldStyles(() => {
    });

    const scoreToolbarContext = React.useContext(ScoreToolbarContext);

    const {t} = useTranslation()

    // An icon representing the column by which the data will be filtered
    const [filterByIcon, setFilterByIcon] = useState(USERNAME_ICON);
    const [filterByText, setFilterByText] = useState(t("score-filter-menu-txt-filter-by-username"));

    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleOpenMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleCloseMenu() {
        setAnchorEl(null);
    }

    /**
     * Set values for filtering by a specific column.
     * <br/>
     * <i>For example, a user chose to filter items that contain a username, so sets the appropriate icon next to the filter text field, a placeholder that informs the user what value to filter, closes the menu (user has selected a filter), and empties text field for re-entering the filtering value.</i>
     *
     * @param filterBy the column by which the values will be filtered
     * @param selectedFilterByText text for placeholder text box
     * @param selectedFilterByIcon icon representing the selected item in the filter menu
     */
    function handleFilterBy(filterBy, selectedFilterByText, selectedFilterByIcon) {
        scoreToolbarContext.setFilterBy(filterBy);
        scoreToolbarContext.setFilter("");
        setFilterByIcon(selectedFilterByIcon);
        setFilterByText(selectedFilterByText);
        setAnchorEl(null);
    }

    /**
     * Set a value in the text box with the value by which items (/ games) will be filtered.
     * <br/>
     * <i>User will only be able to enter a value in the text field if it meets the syntax of the regular expression. For example, for filtering by score, only a positive number will be entered, for a username it will only be possible to enter a syntax that meets the requirements for a valid username name, etc.</i>
     *
     * @param event the event of pressing a key (entering a value) in the text field, to get the entered value
     */
    function handleChange(event) {
        const {value} = event.target;

        if (scoreToolbarContext.filterBy === FILTER_BY_USERNAME) {
            if (checkEmptyValue(value) || REG_EX_FILTER_USERNAME.test(value)) {
                scoreToolbarContext.setFilter(value);
            }
        } else {
            if (checkEmptyValue(value) || /^(\d+|\d+\.|\d+\.\d+)$/.test(value)) {
                scoreToolbarContext.setFilter(value)
            }
        }
    }

    function checkEmptyValue(text) {
        return text.replace(/\s/, '') === ""
    }

    return (
        <Toolbar className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="menu" onClick={handleOpenMenu}>
                {filterByIcon}
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder={filterByText}
                inputProps={{'aria-label': filterByText}}
                value={scoreToolbarContext.filter}
                onChange={handleChange}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search"
                        onClick={scoreToolbarContext.loadScoreList}>
                <SearchIcon/>
            </IconButton>

            <FilterMenu anchorEl={anchorEl}
                        closeMenu={handleCloseMenu}
                        handleFilterBy={handleFilterBy}/>
        </Toolbar>
    )
}

export default ScoreTableToolbar;
