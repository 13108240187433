import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {QuizContext} from "../../../../quiz/context/QuizContext";
import useTextField from "./useTextField";
import {WARNING} from "../../../../snackbar/Variant";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../../../snackbar/Settings";
import TextField from "@material-ui/core/TextField";
import ImageRenderComponent from "../image/ImageRenderComponent";
import ImageUploaderComponent from "../image/ImageUploaderComponent";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const REG_EX_ANSWER_TEXT = /^[\da-zA-ZÁ-ž,'.\s„"()*=;~+]{1,255}$/

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue
        marginTop: theme.spacing(1)
    }
}));

/**
 * Form is used to create a new answer and edit an existing answer.
 *
 * @param isFormOpen true if the form is to be opened, otherwise false
 * @param closeForm close form function
 * @param formTitle the title of the form, for example, that it is about creating a new answer or editing an existing one
 * @param answerForEdit the answer that the use has chosen to edit, the values are 'saved' in the form without retention of references and are further worked on
 * @param createAnswer function for creating a new answer - sending a request to the database
 * @param editAnswer function for saving modified answer, ie. execution operation change answer passed in answerForEdit parameter
 * @param questionId id of the question to which the answer belongs or should belong.
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 19.01.2020 19:28
 */
function CreateEditAnswerForm({isFormOpen, closeForm, formTitle, answerForEdit, createAnswer, editAnswer, questionId}) {

    const classes = useStyles();

    const quizContext = useContext(QuizContext);

    const answerTextField = useTextField('', REG_EX_ANSWER_TEXT);

    const {t} = useTranslation()

    // Set the image for the answer
    const [isAnswerGivenByImage, setIsAnswerGivenByImage] = useState(false);
    const [answerImage, setAnswerImage] = useState(undefined);

    useEffect(() => {
        if (isFormOpen && answerForEdit !== undefined) {
            answerTextField.setTextValue(answerForEdit.text === null ? "" : answerForEdit.text);
            setIsAnswerGivenByImage(answerForEdit.givenByImage);
        }
    }, [isFormOpen]);

    function createNewAnswer() {
        if (validateFormValues()) {
            createAnswer(createAnswerObject(), clearFields);
        }
    }

    function editExistingAnswer() {
        if (validateFormValues()) {
            editAnswer(createAnswerObject(), clearFields);
        }
    }

    function validateFormValues() {
        if (isAnswerGivenByImage) {
            // The answer is entered by the image, either the newly uploaded image or the id of the existing image must be present for the edited answer
            if (answerImage === undefined) {
                // There is no uploaded image, there must be an existing image id, this is only possible with an existing (edited) answer
                if (!answerForEdit || answerForEdit.imgId === null) {
                    showSnackBarDialogWarning(t("create-edit-answer-form-no-image-uploaded"));
                    return false;
                }
                return true;
            }
            // A newly uploaded image is present
            return true;
        }

        if (answerTextField.value.replace(/\s*/, "") === "") {
            showSnackBarDialogWarning(t("create-edit-answer-form-answer-not-specified"));
            return false;
        }
        if (!answerTextField.validate()) {
            showSnackBarDialogWarning(t("create-edit-answer-form-answer-is-not-valid"));
            return false;
        }

        return true;
    }

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    /**
     * Create an object to send to the controller.
     * <br/>
     * <i>The answer has to be sent via "FormData", because it allows sending the file (image).</i>
     * <br/>
     * The object will contain the necessary data entered by the user into the form and the id of answerForEdit in case it is editing an existing answer.
     *
     * @returns {FormData} data that will be sent to the controller for saving answer to the database, it is necessary to use "FormData" because it contains the possibility to send the uploaded file
     */
    function createAnswerObject() {
        const formData = new FormData();

        formData.append("id", !answerForEdit ? "" : answerForEdit.id);
        formData.append("questionId", questionId);
        formData.append("text", answerTextField.value);
        formData.append("imgId", getImgId());
        formData.append("givenByImage", isAnswerGivenByImage.toString());
        formData.append("correct", answerForEdit !== undefined ? answerForEdit.correct : false.toString());

        if (isAnswerGivenByImage && answerImage !== undefined) {
            formData.append("mfImage", answerImage);
        }

        return formData;
    }

    function getImgId() {
        return answerForEdit !== undefined && answerForEdit.imgId !== null ? answerForEdit.imgId : "";
    }

    function clearFields() {
        answerTextField.setEmptyValue();
        setIsAnswerGivenByImage(false);
        setAnswerImage(undefined);
    }

    /**
     * Obtaining a component for enter the answer.
     * <br/>
     * <i>If the form indicates that the answer should be entered by an image, the image upload component will return. Otherwise, the text field returns.</i>
     *
     * @returns {*} text field or image uploader component to enter a answer
     */
    function getComponentForEnterAnswer() {
        if (!isAnswerGivenByImage) {
            return <TextField
                variant="outlined"
                required
                fullWidth
                id="answer-text"
                label={t("create-edit-answer-form-txt-label")}
                name="answer"
                autoComplete="answer"
                multiline
                rows={3}
                error={!answerTextField.validate()}
                value={answerTextField.value}
                onChange={answerTextField.handleChange}
            />
        }

        // Display an image representing a answer and a delete button if the image is uploaded
        // Otherwise the text will show that no image is uploaded and a button to upload it
        return <>
            <ImageRenderComponent
                image={answerImage}
                removeImage={removeAnswerImage}
                imgId={answerForEdit && answerForEdit.givenByImage ? answerForEdit.imgId : undefined}
                isQuestion={false}
            />
            {!answerImage && <ImageUploaderComponent
                imageCallback={uploadAnswerImage}/>}
        </>
    }

    function uploadAnswerImage(image) {
        setAnswerImage(image);
    }

    function removeAnswerImage() {
        setAnswerImage(undefined);
    }

    return (
        <Dialog open={isFormOpen}>
            <DialogContent>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <QuestionAnswerIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {formTitle}
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Tooltip title={t("create-edit-answer-form-answer-given-by-image-switch-tooltip")}>
                                        <Switch checked={isAnswerGivenByImage}
                                                onChange={() => setIsAnswerGivenByImage(!isAnswerGivenByImage)}/>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                    {getComponentForEnterAnswer()}
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => closeForm(clearFields)} color="primary">
                    {t("create-edit-answer-form-btn-cancel")}
                </Button>
                <Button onClick={answerForEdit === undefined ? createNewAnswer : editExistingAnswer}
                        color="primary" autoFocus>
                    {t("create-edit-answer-form-btn-save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateEditAnswerForm;
