import React from 'react'
import PropTypes from "prop-types"
import ClipLoader from 'react-spinners/HashLoader'

const style = {
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '30em',
    height: '18em',
    marginTop: '-9em', /*set to a negative number 1/2 of your height*/
    marginLeft: '-15em' /*set to a negative number 1/2 of your width*/
}

/**
 * Render an animation / image to indicate the processing of an operation.
 * <p>
 * https://reactjsexample.com/a-collection-of-loading-spinner-components-for-react/
 * <p>
 * https://stackoverflow.com/questions/5012111/how-to-position-a-div-in-the-middle-of-the-screen-when-the-page-is-bigger-than-t
 *
 * @param isSpinning true if the set spinner is to be animated (/ 'rotated'), otherwise false
 * @returns {JSX.Element} spinner representing the processing of an operation
 * @constructor
 *
 * @author Jan Krunčík
 * @since 06.09.2019 17:04
 */
function Spinner({isSpinning}) {

    const loaderContainer = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    }

    const loaderDiv = {
        left: "50%",
        top: "50%",
        zIndex: 1000,
        position: "absolute"
    }

    return (
        <div style={loaderContainer}>
            <div style={loaderDiv}>
                <ClipLoader
                    css={style}
                    sizeUnit={"px"}
                    size={150}
                    color={'#123abc'}
                    loading={isSpinning}
                />
            </div>
        </div>
    )
}

Spinner.propTypes = {
    isSpinning: PropTypes.bool.isRequired
}

export default Spinner
