import React, {useState} from 'react';
import Quiz from "../quiz/Quiz";

/**
 * Main / Home page of the application.
 *
 * @author Jan Krunčík
 * @since 14.09.2019 14:12
 */

function AppContent() {

    const [content, setContent] = useState()

    function getContentToRender() {
        if (content !== undefined) {
            return content
        }
        return <Quiz setContent={setContent}/>
    }

    return (
        <>
            {getContentToRender()}
        </>
    )
}

export default AppContent;
