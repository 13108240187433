import moment from "moment";

const GAME_TIME_FORMAT = 'HH:mm:ss:SSS';

/**
 * Gaining the total time (playing the game) in text form that is suitable for displaying to the user.
 *
 * @param gameTime the time of the game in the form of a number (epochs) to display in a specific time format
 * @returns {string} game time in text format
 */
export const getGameTime = (gameTime) => {
    const tmpGameTime = new Date(gameTime);
    tmpGameTime.setHours(tmpGameTime.getHours() - 1);
    return formatDate(tmpGameTime);
};

const formatDate = (date) => {
    return moment(date).format(GAME_TIME_FORMAT);
};