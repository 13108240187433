import React, {useContext} from 'react'
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import {makeStyles} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import {QuizContext} from "../../quiz/context/QuizContext"
import {WARNING} from "../../snackbar/Variant"
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../snackbar/Settings"
import PropTypes from "prop-types"
import {KIND_OF_SOUND} from "./properties"

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1)
    },
    input: {
        display: 'none'
    }
}))

const SUPPORTED_AUDIO_EXTENSIONS = ["audio/mpeg", "audio/mp3", "audio/wav", "audio/ogg"]
const SUPPORTED_AUDIO_EXTENSIONS_WARNING_MESSAGE = ["mp3", "wav", "ogg"]

// 10 MB
const MAX_FILE_SIZE = 10000000

const REG_EXP_FILE_NAME = /^[A-zÁ-ž\d_.]{1,255}$/

/**
 * Component (button) that is used to set the file that the user wants to upload to the application.
 *
 * @param uploadSound function for uploading the selected audio file to the database (replacing the original one)
 * <br/>
 * @param revisionNumber the revision number of the current database record, which was obtained when retrieving the data for the respective audio, needs to be passed to the audio file update function to see if anyone else has already modified the file
 * <br/>
 * @param kindOfSound the type of audio to replace if the user selects an audio file to replace
 * <br/>
 * @param callBack function, which is called after the successful upload of the audio file to the application, saves new (updated) information about the uploaded audio file to the state for further work
 * <br/>
 * @returns {JSX.Element} the button described above, which is used to select and upload an audio file on the device used by the user to the database
 * @constructor
 *
 * @author Jan Krunčík
 * @since 11.11.2020 22:40
 */
function SoundUploadComponent({uploadSound, revisionNumber, kindOfSound, callBack}) {

    const classes = useStyles()
    const quizContext = useContext(QuizContext)

    const {t} = useTranslation()

    /**
     * Validation of a recorded (audio) file.
     *
     * @param event information about the user-selected file that he wants to upload
     */
    function validateSound(event) {
        const file = event.target.files[0]

        if (!file) {
            return
        }

        // Check the maximum allowed file size
        if (file.size > MAX_FILE_SIZE) {
            showSnackBarDialogWarning(t("upload-sound-component-validate-sound-max-file-size"))
            return
        }

        // Check the allowed file extension
        if (!SUPPORTED_AUDIO_EXTENSIONS.includes(file.type.toLowerCase())) {
            const message = `${t("upload-sound-component-validate-sound-supported-formats")} '${SUPPORTED_AUDIO_EXTENSIONS_WARNING_MESSAGE}'.`
            showSnackBarDialogWarning(message)
            return
        }

        // Check the allowed syntax of the file name
        if (!REG_EXP_FILE_NAME.test(file.name)) {
            showSnackBarDialogWarning(t("upload-sound-component-validate-sound-file-name-invalid-syntax"))
            return
        }

        uploadSound(file, kindOfSound, revisionNumber, callBack)
    }

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION)
    }

    return (
        <label>
            <Tooltip title={t("upload-sound-btn-tooltip")}>
                <Fab
                    color="primary"
                    aria-label="upload"
                    size="small"
                    component="span"
                    className={classes.fab}
                >
                    <CloudUploadIcon/>
                </Fab>
            </Tooltip>
            <input
                id={"input-sound"}
                accept={".mp3, .wav, .ogg"}
                className={classes.input}
                type={"file"}
                multiple={false}
                onChange={validateSound}
            />
        </label>
    )
}

SoundUploadComponent.propTypes = {
    uploadSound: PropTypes.func.isRequired,
    revisionNumber: PropTypes.number.isRequired,
    kindOfSound: PropTypes.oneOf(Object.getOwnPropertyNames(KIND_OF_SOUND)),
    callBack: PropTypes.func.isRequired
}

export default SoundUploadComponent
