import React from 'react';
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {ASC, DESC} from "../playersscore/table/TableProperties";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const RANK_ID = 'rank';

const HEAD_CELLS = [
    {id: RANK_ID, numeric: false, disablePadding: true, labelTranslationText: 'score-score-table-head-label-rank'},
    {
        id: 'player.username',
        numeric: false,
        disablePadding: true,
        labelTranslationText: 'score-score-table-head-label-username'
    },
    {id: 'score', numeric: true, disablePadding: false, labelTranslationText: 'score-score-table-head-label-score'},
    {
        id: 'time',
        numeric: true,
        disablePadding: false,
        labelTranslationText: 'score-score-table-head-label-total-game-time'
    }
];

/**
 * Header (s) of the table with the score of the games played.
 * <br/>
 * Table (header) source: https://material-ui.com/components/tables/#sorting-amp-selecting
 *
 * @author Jan Krunčík
 * @since 09.02.2020 20:10
 */
function ScoreTableHead(props) {

    const classes = useStyles();

    const {t} = useTranslation()

    const {order, orderBy, onRequestSort} = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {HEAD_CELLS.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id !== RANK_ID ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : ASC}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {t(headCell.labelTranslationText)}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                  {order === DESC ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                            : t(headCell.labelTranslationText)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

ScoreTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf([ASC, DESC]).isRequired,
    orderBy: PropTypes.string.isRequired
};

export default ScoreTableHead;
