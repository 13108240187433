import React, {useState} from 'react'
import PropTypes from "prop-types"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import SoundUploadComponent from "./SoundUploadComponent"
import {makeStyles} from "@material-ui/core/styles"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import {useTranslation} from "react-i18next"
import Call from "../../server/Call"
import {useMountEffect} from "../../mount/useMountEffect"
import CircularProgress from '@material-ui/core/CircularProgress'
import {getSoundUrl} from "../../server/ServerCall"

const useStyles = makeStyles(theme => ({
    text: {
        textAlign: 'center'
    },
    fab: {
        margin: theme.spacing(1)
    },
    spinnerDiv: {
        margin: theme.spacing(3)
    },
    soundInfoDiv: {
        margin: theme.spacing(2)
    },
    audioDiv: {
        margin: theme.spacing(2)
    }
}))

/**
 * View information about one specific audio file that is used in the game.
 * <br/>
 * Options are available to play the audio file, download it, and upload a new audio file (for replacement).
 *
 * @param uploadSound function for upload a new audio file
 * <br/>
 * @param kindOfSound the type of sound that the data in this component is displayed on is also the type of sound that will be edited or downloaded
 * <br/>
 * @param text the text that will be displayed on the drop-down tab, ie information for the user to know what type of sound it is
 * <br/>
 * @param icon the icon that will be displayed on the drop-down tab represents (to some extent) the type of sound (in the game)
 * <br/>
 * @returns {JSX.Element} the drop-down menu described above with information about a specific audio file and the possibility of editing and downloading it, or a spinner if the data is loaded
 * @constructor
 *
 * @author Jan Krunčík
 * @since 11.11.2020 22:53
 */
function SoundPanelComponent({uploadSound, kindOfSound, text, icon}) {

    const classes = useStyles()

    const {t} = useTranslation()

    const {soundDetail, downloadSound} = Call()

    // The spinner will be displayed until the sound (info / detail) is loaded
    const [soundLoaded, setSoundLoaded] = useState(false)
    const [sound, setSound] = useState({})

    const [open, setOpen] = useState(false)
    const handleClick = () => setOpen(!open)

    useMountEffect(() => loadSound())

    /**
     * Retrieve audio file data from a database and save to state.
     */
    function loadSound() {
        soundDetail(kindOfSound, dtoOut => {
            setSound(dtoOut)
            setSoundLoaded(true)
        }, () => setSoundLoaded(true))
    }

    function getSoundInfo() {
        return isSoundUploaded() ? <span>{sound.fileName}</span> :
            <span>{t("sound-panel-component-span-sound-not-available")}</span>
    }

    /**
     * true if the sound was successfully uploaded to the database, otherwise false (default - empty configuration in the database) or whether it is in the database and not a default / empty configuration.
     *
     * @returns {null|""|*|boolean} true if the audio file was successfully uploaded to the database, otherwise false
     */
    const isSoundUploaded = () =>
        sound.fileName
        && sound.fileName.replace(/\s/g, '').length > 0
        && sound.contentType
        && sound.contentType.replace(/\s/g, '').length > 0
        && sound.data
        && sound.data.replace(/\s/g, '').length > 0

    /**
     * If the information about the respective audio file is loading, the spinner will be displayed (returned), otherwise the component for setting the audio file.
     *
     * @returns {JSX.Element} the spinner described above, if the information about the respective sound file is loading, otherwise the settings for the respective sound
     */
    function getSoundSettingsOrSpinner() {
        if (!soundLoaded) {
            return (
                <div className={classes.spinnerDiv}>
                    <CircularProgress/>
                </div>
            )
        }

        return (
            <div>
                <div className={classes.soundInfoDiv}>
                    {getSoundInfo()}
                    {isSoundUploaded() && (
                        <div className={classes.audioDiv}>
                            <audio src={getSoundUrl(kindOfSound)} preload="auto" controls/>
                        </div>
                    )}
                </div>
                <SoundUploadComponent
                    uploadSound={uploadSound}
                    revisionNumber={sound.revisionNumber}
                    kindOfSound={kindOfSound}
                    callBack={dtoOut => setSound(dtoOut)}
                />
                <Tooltip title={t("download-sound-btn-tooltip")}>
                    <Fab
                        disabled={!isSoundUploaded()}
                        color="primary"
                        aria-label="download"
                        size="small"
                        component="span"
                        className={classes.fab}
                        onClick={() => downloadSound(kindOfSound, sound.fileName)}
                    >
                        <CloudDownloadIcon/>
                    </Fab>
                </Tooltip>
            </div>
        )
    }

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    className={classes.text}
                    primary={text}
                />
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                {getSoundSettingsOrSpinner()}
            </Collapse>
        </>
    )
}

SoundPanelComponent.propTypes = {
    text: PropTypes.string.isRequired,
    uploadSound: PropTypes.func.isRequired,
    kindOfSound: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired
}

export default SoundPanelComponent
