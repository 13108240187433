import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AppContent from "../app/AppContent";
import SignInForm from "../auth/login/SignInForm";
import UnknownUrl from "./UnknownUrl";
import SignUpForm from "../auth/register/SignUpForm";
import Dashboard from "../auth/dashboard/Dashboard";
import {DASHBOARD, HOME, SCORE, SIGN_IN, SIGN_UP} from "./url";
import SignInOrDashboardRoute from "./SignInOrDashboardRoute";
import MenuRoute from "./MenuRoute";
import RoleRoute from "./RoleRoute";
import {ROLE_ADMIN, ROLE_USER} from "../server/auth/userRoles";
import Score from "../quiz/score/Score";
import {CookiesProvider} from 'react-cookie';

/**
 * Used to redirect to all possible components (/ pages) in the application.
 *
 * @author Jan Krunčík
 * @since 14.09.2019 14:04
 */

const MainRoute = () => {

    return (
        <CookiesProvider>
            <Router>
                <MenuRoute/>

                <Switch>
                    <Route path={HOME} exact component={AppContent}/>
                    <SignInOrDashboardRoute path={SIGN_IN} exact component={SignInForm}/>
                    <RoleRoute path={DASHBOARD} component={Dashboard} allowedRoles={[ROLE_USER, ROLE_ADMIN]}/>
                    <RoleRoute path={SIGN_UP} exact component={SignUpForm} allowedRoles={[ROLE_ADMIN]}/>
                    <Route path={SCORE} exact component={Score}/>

                    <Route component={UnknownUrl}/>
                </Switch>
            </Router>
        </CookiesProvider>
    )
}

export default MainRoute;
