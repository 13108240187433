import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import QuestionAndAnswersGrid from "./question/QuestionAndAnswersGrid";
import {GameContext} from "../../context/GameContext";
import Button from "@material-ui/core/Button";
import Call from "../../../server/Call";
import {DEFAULT_ID} from "../../property/Properties";
import {EASY, HARD, MEDIUM} from "../../../auth/question/content/constant/category";
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";
import useSpectatorImage from "../image/spectator/useSpectatorImage";
import {SPECTATOR_CORRECT_ANSWER, SPECTATOR_DEFAULT} from "../image/spectator/spectatorImageEnum";
import useGuestImage from "../image/guest/useGuestImage";
import useModeratorImage from "../image/moderator/useModeratorImage";
import {
    GUEST_CORRECT_ANSWER,
    GUEST_DEFAULT,
    GUEST_NEW_QUESTION,
    GUEST_WRONG_ANSWER
} from "../image/guest/guestImageEnum";
import {
    MODERATOR_CORRECT_ANSWER,
    MODERATOR_DEFAULT,
    MODERATOR_NEW_QUESTION,
    MODERATOR_WRONG_ANSWER
} from "../image/moderator/moderatorImageEnum";
import Hidden from "@material-ui/core/Hidden";
import {QUESTION_STATUS_LOADING} from "./properties";
import {QuizContext} from "../../context/QuizContext";

const useStyles = makeStyles(themeStyle => ({
    temptingText: {
        fontSize: 20,
        fontFamily: "Bangers, cursive",
        '@media (max-width:959px)': {
            fontSize: 18
        },
        '@media (max-width:599px)': {
            fontSize: 15
        }
    },
    button: {
        margin: themeStyle.spacing(1)
    },
    contactSupportAlert: {
        display: "flex",
        justifyContent: "center"
    },
    contactSupportSpanMessage: {
        fontSize: 18,
        fontWeight: "bold"
    }
}));

const ColorButton = withStyles((tmpTheme) => ({
    root: {
        color: tmpTheme.palette.getContrastText('#000000'),
        backgroundColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000'
        }
    }
}))(Button)

/**
 * Render the content of the introductory page with the game.
 * <br/>
 * <i>Thus, components with a guessed question and its answers, components with animated images, a component displaying score information, etc. are displayed here.
 *
 * @author Jan Krunčík
 * @since 17.07.2020 16:04
 */

function QuizGameContent() {

    const classes = useStyles()

    const quizContext = useContext(QuizContext)

    const {t} = useTranslation()

    const gameContext = useContext(GameContext)

    const [selectedAnswerId, setSelectedAnswerId] = useState(DEFAULT_ID)
    const [correctAnswerId, setCorrectAnswerId] = useState(DEFAULT_ID)
    // Start / Stop the countdown to highlight the player's marked answer
    const [selectedAnswerCountdownActive, setSelectedAnswerCountdownActive] = useState(false)
    // Start / Stop countdown to highlight the correct answer
    const [showCorrectAnswerCountDownActive, setShowCorrectAnswerCountDownActive] = useState(false)

    const {getCorrectAnswerId} = Call()

    // Hooks to display animation of the guest, moderator and spectators. The animation will be displayed according to the event (new question, right / wrong answer, etc.)
    const {setGuestImage, getGuestImageToDisplay} = useGuestImage()
    const {setModeratorImage, getModeratorImageToDisplay} = useModeratorImage()
    const {setSpectatorImage, getSpectatorImageToDisplay} = useSpectatorImage()

    // After selecting the answer, it will be highlighted for a while
    // When the player marks the answer, a countdown starts, after which the correct answer will be shown
    useEffect(() => {
        let interval = null
        if (selectedAnswerCountdownActive) {
            interval = setInterval(() => {
                // It is necessary to set this value so that in case the lives run out, the dialog with information about the played game is not displayed, but so that the second interval with the display of the correct answer is completed and only then the dialog about the played game is displayed
                gameContext.setAnswerWasDisplayed(false)

                // Retrieve the id of the correct answer
                getCorrectAnswerId(gameContext.getQuestionToAsk().id, correctAnswerIdResponse => {
                    if (!correctAnswerIdResponse) {
                        return
                    }

                    // Set (id) the correct answer for highlighting
                    setCorrectAnswerId(correctAnswerIdResponse)

                    // Add information about the selected answer so that it appears in the game results
                    gameContext.addSelectedAnswerIdInfo(correctAnswerIdResponse, selectedAnswerId)

                    // If the user marked the correct answer, his score will be increased, otherwise his life will be deducted
                    if (selectedAnswerId === correctAnswerIdResponse) {
                        const currentScore = gameContext.score.value
                        const scoreWithBonus = currentScore + getBonus(gameContext.questionCountdown.countdownValue)
                        gameContext.score.setScoreValue(scoreWithBonus)

                        // Display animations representing the correct answer
                        setGuestImage(GUEST_CORRECT_ANSWER)
                        setSpectatorImage(SPECTATOR_CORRECT_ANSWER)
                        setModeratorImage(MODERATOR_CORRECT_ANSWER)

                        // Play random sound for a correctly answered question
                        playRandomCorrectAnswerSound()
                        // Play sound to increase score
                        quizContext.useAddScoreSound.play()
                    } else {
                        wrongAnswer()
                    }
                })

                // Turn off the countdown to highlight the selected answer
                setSelectedAnswerCountdownActive(false)
                // Turn on countdown to highlight the correct answer and possibly an incorrect (marked) answer
                setShowCorrectAnswerCountDownActive(true)
            }, 1000)
        }
        return () => clearInterval(interval)
    }, [selectedAnswerCountdownActive, selectedAnswerId])

    /**
     * If the (bonus) time expires, the player did not have time to answer the question in a sufficiently fast time, it is a situation similar to how he answered the question incorrectly.
     * <br/>
     * Thus, one life will be removed, the sound is played, and animations representing the wrong answer are displayed. Furthermore, only the correct answer will be added to the list of answered questions.
     * <br/>
     * If the game is no longer running, it means that the user intentionally ended the game (just before loading the next question, for example), so there is no need to generate another question or take a life, etc. Because the dialog with the end of the game will be displayed.
     */
    useEffect(() => {
        // If the timer has run out but the game is no longer running, the attributes for generating the next question will not be set
        if (gameContext.questionCountdown.countdownValue === 0 && gameContext.isGameRunning) {
            // In this case, player have run out of lives, there is need to set this variable in order to display a dialog with information about the end of the game
            gameContext.setAnswerWasDisplayed(false)

            // Retrieve the id of the correct answer
            getCorrectAnswerId(gameContext.getQuestionToAsk().id, correctAnswerIdResponse => {
                if (correctAnswerIdResponse) {
                    // Set (id) the correct answer for highlighting
                    setCorrectAnswerId(correctAnswerIdResponse)
                    // Add information about the correct answer to the question, but that the player did not choose any answer (/ did not make it)
                    gameContext.addSelectedAnswerIdInfo(correctAnswerIdResponse, DEFAULT_ID)
                }
            })

            wrongAnswer()
            // Turn on countdown to highlight the correct answer and possibly an incorrect (marked) answer
            setShowCorrectAnswerCountDownActive(true)
        }
    }, [gameContext.questionCountdown.countdownValue])

    // Time for which the correct answer will be displayed (and possibly an incorrectly marked answer by the user if the correct answer differs from the marked one)
    // After the time for displaying the selected answer has elapsed, the correct answer will be displayed in this interval, after this time another question for guessing / answering will be displayed
    useEffect(() => {
        let interval = null
        if (showCorrectAnswerCountDownActive) {
            interval = setInterval(() => {
                // Preparing to display the next question
                setSelectedAnswerId(DEFAULT_ID)
                setCorrectAnswerId(DEFAULT_ID)
                setShowCorrectAnswerCountDownActive(false)

                // If the player has run out of lives, it is set that the correct answer has already been displayed (this interval has expired) and it is possible to display the result of the game played, the correct answer has already been displayed
                gameContext.setAnswerWasDisplayed(true)

                // For example, if the question time has expired and the players have run out of lives, it means the end of the game, so here it is necessary to test whether the player has a life, if not, the timer must not be restarted because the game is over
                if (gameContext.lives.value > 0) {
                    // Start and reset the timer to countdown bonus points for the next question if the user answers it quickly
                    gameContext.questionCountdown.startCountdown()
                    gameContext.questionCountdown.resetCountdown()

                    // Generate (/ display) another (/ next) question
                    gameContext.nextQuestion()

                    // Displays animations representing the newly entered (/ generated) question to evaluate
                    displayAnimationsForNewQuestion()
                }
            }, 2400)
        }
        return () => clearInterval(interval)
    }, [showCorrectAnswerCountDownActive])

    /**
     * If the status value of 'gameContext.isGameRunning' changes to true, it means that the game has started. Animations should respond to the start of the game, so they will start here.
     * <br/>
     * If the status of the specified variable is false, it means that the game has ended, then it is necessary to set the display of default animations.
     * <i>Because, for example, when the player's last question runs out of time, an animation for incorrect answer is displayed followed by a static image, but a new question is no longer displayed, so only a static (png) image remains displayed, which is unwanted.</i>
     */
    useEffect(() => {
        if (gameContext.isGameRunning) {
            displayAnimationsForNewQuestion()
        } else {
            // After the game, some animations may have a static (png) image displayed, there is need to set the default animation
            setGuestImage(GUEST_DEFAULT)
            setModeratorImage(MODERATOR_DEFAULT)
            setSpectatorImage(SPECTATOR_DEFAULT)

            // The game is over, so there is need to set attributes that indicate that no answer is marked
            setSelectedAnswerId(DEFAULT_ID)
            setCorrectAnswerId(DEFAULT_ID)
            // The game is over, so there is need to set the attribute so that the correct answer is not displayed, because the dialog with the end of the game will be displayed
            setShowCorrectAnswerCountDownActive(false)
            // The game is over, so there is no need to display the marked answer, a dialog with information about the played game will be displayed
            setSelectedAnswerCountdownActive(false)
        }
    }, [gameContext.isGameRunning])

    /**
     * Play music for a successfully answered question.
     * <br/>
     * <i>The sound played for a correctly answered question will be randomly generated from multiple options.</i>
     */
    function playRandomCorrectAnswerSound() {
        const randomNumber = getRandomIntInclusive(1, 2)

        if (randomNumber === 1) {
            quizContext.useCorrectAnswer1Sound.play()
        } else {
            quizContext.useCorrectAnswer2Sound.play()
        }
    }

    /**
     * Sets the animations of the required images to display animations representing that a new question has been entered.
     */
    function displayAnimationsForNewQuestion() {
        setGuestImage(GUEST_NEW_QUESTION)
        setModeratorImage(MODERATOR_NEW_QUESTION)
    }

    /**
     * Getting a random integer between two values, inclusive.
     *
     * @param min minimum possible generated value (lower limit)
     * @param max maximum possible generated value (upper limit)
     * @returns {number} a randomly generated number in the specified range
     */
    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
    }

    /**
     * Perform operations (/ steps) to show a wrong answer.
     * <br/>
     * The player chose the wrong answer to the question or did not have time to answer it quickly enough.
     */
    function wrongAnswer() {
        gameContext.lives.decreaseLife()
        // Play sound for an incorrectly answered question
        quizContext.useWrongAnswerSound.play()

        // Display animations representing an incorrect answer
        setGuestImage(GUEST_WRONG_ANSWER)
        setModeratorImage(MODERATOR_WRONG_ANSWER)
    }

    /**
     * Setting of the player's chosen answer (/ its id).
     * <br/>
     * <i>After marking (/ saving the id of the answer to the state) the answer will be highlighted for a certain time and after the time the correct answer will be displayed.</i>
     *
     * @param answerId id of the answer marked by the player
     */
    function chooseAnswer(answerId) {
        if (selectedAnswerId === DEFAULT_ID) {
            setSelectedAnswerId(answerId)
            // Starting the countdown, during which the selected answer will be highlighted, after the time has elapsed, the correct answer will be highlighted
            setSelectedAnswerCountdownActive(true)
            // Stop timer of bonus points for quick answers
            gameContext.questionCountdown.stopCountdown()
            // Play sound for the selected / marked answer
            quizContext.useAnswerSelectionSound.play()
        }
    }

    /**
     * Calculation of bonus points.
     * <br/>
     * <i>If the player answered the question before the timer for bonus points have passed, the coefficient according to the complexity of the question will be multiplied by the remaining time.</i>
     *
     * @param coefficient the remaining time from the timer for bonus points
     * @returns {number|*} total bonus points for the answered question (to be added to score)
     */
    function getBonus(coefficient) {
        if (coefficient === 0) {
            // Default (no bonus)
            return 0
        }

        const currentQuestion = gameContext.getQuestionToAsk()
        if (currentQuestion.category === EASY.name) {
            return coefficient * 0.5
        }
        if (currentQuestion.category === MEDIUM.name) {
            return coefficient
        }
        if (currentQuestion.category === HARD.name) {
            return coefficient * 1.5
        }

        // Default value
        return 1
    }

    /**
     * Get a button to start a game or components to play it.
     * <br/>
     * <i>If the questions in the database do not match the requirements for playing the game (for example, insufficient number of questions), information will be displayed to contact technical support.</i>
     * <br/>
     * <i>If the game is running, the game component will be returned (questions and answers).</i>
     * <br/>
     * <i>If the game is not running, a button to start it will be displayed.</i>
     *
     * @returns {*} button to start the game if the game is not running, otherwise components to play the game
     */
    function getStartButtonOrGameComponents() {
        if (gameContext.isQuestionsStatusReady === QUESTION_STATUS_LOADING) {
            return <></>
        } else if (!gameContext.isQuestionsStatusReady && gameContext.isQuestionsStatusReady !== QUESTION_STATUS_LOADING) {
            return (
                <div className={classes.contactSupportAlert}>
                    <Alert severity="error">
                        <span className={classes.contactSupportSpanMessage}>
                            {t("quiz-questions-not-meet-requirements-for-game")}
                        </span>
                    </Alert>
                </div>
            )
        } else if (gameContext.isGameRunning && gameContext.getQuestionToAsk().id > -1) {
            return <QuestionAndAnswersGrid
                question={gameContext.getQuestionToAsk()}
                chooseAnswer={chooseAnswer}
                selectedAnswerId={selectedAnswerId}
                correctAnswerId={correctAnswerId}
            />
        }

        return (
            <ColorButton
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={gameContext.checkUsernameAndEmailBeforeGameStartAndThenStartGame}
            >
                {t("quiz-btn-start-game")}
            </ColorButton>
        )
    }

    return (
        <>
            {!gameContext.isGameRunning &&
            <div className={classes.temptingText}>
                <div>{t("quiz-div-tempting-text-1")}</div>
                <div>{t("quiz-div-tempting-text-2")}</div>
            </div>}

            {getStartButtonOrGameComponents()}

            <Grid
                container
                direction="row"
                justify="center"
            >
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} zeroMinWidth>
                    {getGuestImageToDisplay()}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} zeroMinWidth>
                    {getModeratorImageToDisplay()}
                </Grid>
            </Grid>

            <Hidden xsDown>
                <Grid
                    container
                    direction="row"
                    justify="center"
                >
                    <Grid item xs zeroMinWidth>
                        {getSpectatorImageToDisplay()}
                    </Grid>
                </Grid>
            </Hidden>
        </>
    )
}

export default QuizGameContent;
