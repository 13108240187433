import React from 'react'
import {useTranslation} from "react-i18next"
import SoundPanelComponent from "./SoundPanelComponent"
import Call from "../../server/Call"
import {KIND_OF_SOUND} from "./properties"
import SurroundSoundIcon from '@material-ui/icons/SurroundSound'
import AddIcon from '@material-ui/icons/Add'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'

/**
 * Component displaying data on available sounds for playing the game (their management).
 *
 * @author Jan Krunčík
 * @since 11.11.2020 21:57
 */
function Sounds() {

    const {t} = useTranslation()

    const {updateSound} = Call()

    /**
     * Update the audio file in the database.
     *
     * @param file a user-selected (/ uploaded) new audio file to be stored in the database for further use by the game
     * @param kindOfSound the type of sound that the user wants to update (/ replace), according to this value, a specific sound file (database record) will be identified for replacement
     * @param revisionNumber the revision number of the database record so that the user does not inadvertently overwrite another record modified by another user
     * @param callBack a function that will be called if the audio recording in the database is successfully updated
     */
    function uploadSound(file, kindOfSound, revisionNumber, callBack) {
        updateSound(createUploadSoundDtoIn(file, kindOfSound, revisionNumber), callBack)
    }

    /**
     * Creating an input object for updating (/ uploading) a new audio file to the database.
     *
     * @param file a user-selected (/ uploaded) new audio file to be stored in the database for further use by the game
     * @param kindOfSound the type of sound that the user wants to update (/ replace), according to this value, a specific sound file (database record) will be identified for replacement
     * @param revisionNumber the revision number of the database record so that the user does not inadvertently overwrite another record modified by another user
     * @returns {FormData} an input object for passing an API call to a method for updating an audio file in a database
     */
    function createUploadSoundDtoIn(file, kindOfSound, revisionNumber) {
        const formData = new FormData()
        formData.append("sound", file)
        formData.append("kindOfSound", kindOfSound)
        formData.append("revisionNumber", revisionNumber)
        return formData
    }

    return (
        <>
            <h1>{t("sounds-page-title")}</h1>

            <br/>

            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-background")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.BACKGROUND}
                icon={<SurroundSoundIcon/>}
            />
            <br/>
            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-add-score")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.ADD_SCORE}
                icon={<AddIcon/>}
            />
            <br/>
            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-answer-selection")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.ANSWER_SELECTION}
                icon={<SelectAllIcon/>}
            />
            <br/>
            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-ask-question")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.ASK_QUESTION}
                icon={<QuestionAnswerIcon/>}
            />
            <br/>
            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-wrong-answer")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.WRONG_ANSWER}
                icon={<CloseIcon/>}
            />
            <br/>
            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-correct-answer-1")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.CORRECT_ANSWER_1}
                icon={<DoneIcon/>}
            />
            <br/>
            <SoundPanelComponent
                text={t("sounds-page-pnl-sound-correct-answer-2")}
                uploadSound={uploadSound}
                kindOfSound={KIND_OF_SOUND.CORRECT_ANSWER_2}
                icon={<DoneIcon/>}
            />
        </>
    )
}

export default Sounds
