import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {getQuestionOrAnswerImageUrl} from "../../../../server/ServerCall";
import {getSecureText} from "../../../../xss/xss";
import {DEFAULT_ID} from "../../../property/Properties";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    image: {
        width: '100%'
    },
    spanBorder: {
        padding: 10,
        '@media (max-width:959px)': {
            padding: 5
        },
        '@media (max-width:599px)': {
            padding: 0
        }
    },
    spanText: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 18,
        fontWeight: "bold",
        cursor: "pointer",
        '@media (max-width:959px)': {
            fontSize: 16
        },
        '@media (max-width:599px)': {
            fontSize: 13
        }
    }
}))

// Colors to color the background of the answers according to whether they were marked (by player) as an ('possibly') correct answer or evaluated (correct or incorrect answer to the question)
const BG_CLR_ANSWER = "#ffffff"
const BG_CLR_SELECTED_ANSWER = "#ffa100"
const BG_CLR_CORRECT_ANSWER = "#00ff00"
const BG_CLR_WRONG_ANSWER = "#ff0000"

/**
 * View a single answer. Answer text as needed or load and display an image.
 *
 * @param answer the answer that appears, either its text or is retrieved and displays its image
 * @param chooseAnswer function that sets (/ saves) the id of the selected answer, which will be evaluated, according to the selected answer, the background of the answer will be highlighted
 * @param selectedAnswerId id of the answer marked by the player
 * @param correctAnswerId id the correct answer to the question
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 23.07.2020 15:05
 */

function Answer({answer, chooseAnswer, selectedAnswerId, correctAnswerId}) {

    const classes = useStyles();

    const {t} = useTranslation()

    const [mouseOver, setMouseOver] = useState(false)

    useEffect(() => {
        // When a new answer is displayed, it must be set that there is no cursor on it (even if it is), otherwise, the answer would still be marked on the next question, even if there was no mouse cursor on it
        setMouseOver(false)
    }, [answer])

    /**
     * Obtaining a style for displaying (component) of the answer, the background color will be determined by the correctness of the answer.
     * <br/>
     * <i>The background colors of the answer differ depending on whether the player marked the answer, whether the marked answer is correct or not, and highlighting the correct answer (after evaluatio of results).</i>
     *
     * @returns {{border: string, cursor: string, padding: number, backgroundColor: string, fontFamily: string, borderRadius: number, fontSize: number}}
     */
    function getPaperStyle() {
        let bgColor = BG_CLR_ANSWER

        // Highlighting the answer, either as if it was marked but not evaluated (orange), evaluated correctly (green), evaluated incorrectly (red)
        if (correctAnswerId > DEFAULT_ID) {
            // Here the answer was evaluated
            if (answer.id === correctAnswerId) {
                // Marked correct answer
                bgColor = BG_CLR_CORRECT_ANSWER
            } else if (answer.id === selectedAnswerId) {
                // This is a marked answer, but it is not correct, so the player marked the wrong answer
                bgColor = BG_CLR_WRONG_ANSWER
            }
        } else if (answer.id === selectedAnswerId) {
            // Here the answer is only marked, but not yet evaluated
            bgColor = BG_CLR_SELECTED_ANSWER
        } else if (mouseOver) {
            // Here the answer is not selected yet and the user has moved the mouse over the answer, it will only be highlighted to see which answer user would choose by clicking
            bgColor = "#E4E4E4"
        }

        return {
            borderRadius: 5,
            border: '5px solid black',
            cursor: "pointer",
            backgroundColor: bgColor
        }
    }

    function getTextOrImage() {
        if (answer.givenByImage) {
            return <img src={getQuestionOrAnswerImageUrl(answer.imgId)}
                        alt={t("quiz-answer-img-alt")}
                        className={classes.image}
                        onClick={chooseAnswer}
                        onMouseOver={() => setMouseOver(true)}
                        onMouseLeave={() => setMouseOver(false)}
            />
        }
        return (
            <div
                className={classes.spanBorder}
                onMouseOver={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
            >
                <span
                    onClick={chooseAnswer}
                    className={classes.spanText}
                >
                    {getSecureText(answer.text)}
                </span>
            </div>
        )
    }

    return (
        <Paper style={getPaperStyle()} onClick={() => chooseAnswer(answer.id)}>
            {getTextOrImage()}
        </Paper>
    )
}

Answer.defaultProps = {
    answer: {},
    selectedAnswerId: DEFAULT_ID,
    correctAnswerId: DEFAULT_ID
}

Answer.propTypes = {
    answer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        questionId: PropTypes.number.isRequired,
        text: PropTypes.string,
        imgId: PropTypes.number,
        givenByImage: PropTypes.bool.isRequired
    }).isRequired,
    chooseAnswer: PropTypes.func.isRequired,
    selectedAnswerId: PropTypes.number.isRequired,
    correctAnswerId: PropTypes.number.isRequired
}

export default Answer;
