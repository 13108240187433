import React from 'react';
import DashboardChartOverview from "./chart/DashboardChartOverview";
import {useTranslation} from "react-i18next";

/**
 * Default information for the user who signs in to the application.
 *
 * This page / component is the default that will be displayed when the user sings on.
 *
 * @author Jan Krunčík
 * @since 15.09.2019 13:45
 */

function DashboardContent() {

    const {t} = useTranslation()

    return (
        <>
            <h1>{t("dashboard-title")}</h1>

            <DashboardChartOverview/>
        </>
    )
}

export default DashboardContent;
