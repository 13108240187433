import React from 'react';
import {Route} from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import {
    DASHBOARD,
    GAMES,
    HOME,
    PLAYERS,
    PROFILE,
    QUESTIONS,
    QUIZ_CONFIGURATION,
    SIGN_UP,
    SOUNDS,
    USERS
} from "../../route/url";
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import GamesIcon from '@material-ui/icons/Games';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonIcon from '@material-ui/icons/Person';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {useTranslation} from "react-i18next";
import MusicNoteIcon from '@material-ui/icons/MusicNote';

/**
 * Dashboard menu items.
 *
 * @author Jan Krunčík
 * @since 14.09.2019 12:24
 */

function DrawerMenuItems() {

    const {t} = useTranslation()

    function createRouteItem(url, text, icon) {
        return <Route render={({history}) => (
            <ListItem button onClick={() => {
                history.push(url);
            }}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text}/>
            </ListItem>
        )}/>
    }

    return (
        <>
            {createRouteItem(HOME, t("menu-item-home"), <HomeIcon/>)}
            {createRouteItem(DASHBOARD, t("menu-item-dashboard"), <DashboardIcon/>)}
            {createRouteItem(USERS, t("menu-item-users"), <SupervisedUserCircleIcon/>)}
            {createRouteItem(PLAYERS, t("menu-item-players"), <PeopleIcon/>)}
            {createRouteItem(GAMES, t("menu-item-games"), <GamesIcon/>)}
            {createRouteItem(QUESTIONS, t("menu-item-questions"), <QuestionAnswerIcon/>)}
            {createRouteItem(QUIZ_CONFIGURATION, t("menu-item-settings"), <SettingsApplicationsIcon/>)}
            {createRouteItem(SIGN_UP, t("menu-item-sign-up"), <PersonAddIcon/>)}
            {createRouteItem(PROFILE, t("menu-item-profile"), <PersonIcon/>)}
            {createRouteItem(SOUNDS, t("menu-item-sounds"), <MusicNoteIcon/>)}
        </>
    )
}

export default DrawerMenuItems;
