import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Call from "../../server/Call";
import QuestionsOverview from "./content/QuestionsOverview";
import useForm from "../../form/useForm";
import ConfirmDeleteQuestionForm from "./content/form/ConfirmDeleteQuestionForm";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import CreateEditQuestionForm from "./content/form/CreateEditQuestionForm";
import ConfirmDeleteAllQuestionsForm from './content/form/ConfirmDeleteAllQuestionsForm';
import Tooltip from '@material-ui/core/Tooltip';
import {QuestionPaginationContext} from "./content/pagination/QuestionPaginationContext";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from "react-i18next";
import {useMountEffect} from "../../mount/useMountEffect";

const DEFAULT_CURRENT_PAGE = 0;
const DEFAULT_QUESTIONS_PER_PAGE = 10;

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

/**
 * Management of questions and their answers that are generated into the game / quiz.
 *
 * <i>Here it is possible to perform CRUD operations on questions and their answers.</i>
 *
 * @author Jan Krunčík
 * @since 15.09.2019 11:37
 */

function Questions() {

    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState(DEFAULT_CURRENT_PAGE);
    const [questionsPerPage, setQuestionsPerPage] = useState(DEFAULT_QUESTIONS_PER_PAGE);

    const useCreateEditQuestionForm = useForm();
    const [formTitle, setFormTitle] = useState('');
    const [questionForEdit, setQuestionForEdit] = useState(undefined);

    const useDeleteQuestionConfirmForm = useForm();
    const useDeleteAllQuestionConfirmForm = useForm();
    // The question to be deleted after clicking on the delete button in the GUI is saved here, the change value is recognized and a dialog is opened where its text is displayed
    const [questionToDelete, setQuestionToDelete] = useState(undefined);

    const {getQuestionList, addQuestion, updateQuestion, deleteQuestionById, deleteAllQuestions, getQuestionsStatus} = Call();

    const [questions, setQuestions] = useState([]);
    // Total number of questions in the database (due to paging)
    const [questionCount, setQuestionCount] = useState(0);

    const [questionsStatusDtoOut, setQuestionsStatusDtoOut] = useState(undefined);
    const [showQuestionsStatusValidInfo, setShowQuestionsStatusValidInfo] = useState(true);

    const {t} = useTranslation()

    /**
     * Retrieve questions from the database to fill the page.
     */
    function loadQuestionList() {
        const dtoIn = {
            page: currentPage,
            size: questionsPerPage
        };

        getQuestionList(dtoIn, (questionListFromResponse, totalQuestionsCount) => {
            setQuestions(questionListFromResponse);
            setQuestionCount(totalQuestionsCount);
            setQuestionsStatusInfo();
        })
    }

    /**
     * The first time this component is loaded, the status of the questions about whether they match the requirements for playing the game is retrieved.
     */
    useMountEffect(() => {
        setQuestionsStatusInfo();
    });

    useEffect(() => {
        loadQuestionList()
    }, [currentPage, questionsPerPage]);

    useEffect(() => {
        if (questionToDelete) {
            useDeleteQuestionConfirmForm.openForm();
        }
    }, [questionToDelete]);

    // Setting the variable, changing its value is recognized and a confirmation dialog opens
    function deleteQuestionFormManipulation(question) {
        setQuestionToDelete(question);
    }

    function closeFormAndClearTmpVariable() {
        setQuestionToDelete(undefined);
        useDeleteQuestionConfirmForm.closeForm();
    }

    /**
     * Create a new question in the database.
     * <br/>
     * <i>After the question has been created, it is necessary to check the (validation) status of the questions - so that the administrator is notified in case the game cannot be played.</i>
     * <br/>
     * <i>If the page user is viewing is not yet reached the maximum number of questions (items), the newly created question is added without loading the entire page.<i/>
     * <br/>
     * <i>If the new question no longer fits on the page, the entire page will be reloaded, then the newly created ones may be displayed according to the sorted questions.<i/>
     *
     * @param question to be created
     * @param clearFields the function, which is called when the query is successfully created in the database, is used to empty the text fields in the question create / edit dialog so that the dialog with the original values does not appear when the dialog is reopened
     */
    function createQuestion(question, clearFields) {
        addQuestion(question, questionResponse => {
            setQuestionsStatusInfo();

            if (questions.length < questionsPerPage) {
                setQuestions(oldQuestions => [...oldQuestions, questionResponse]);
                setQuestionCount(questionCount + 1);
            } else {
                loadQuestionList();
            }
            closeCreateEditForm(clearFields)
        })
    }

    /**
     * Edit an existing question in the database.
     *
     * @param question to be edited
     * @param clearFields the function, which is called when the query is successfully created in the database, is used to empty the text fields in the question create / edit dialog so that the dialog with the original values does not appear when the dialog is reopened
     */
    function editQuestion(question, clearFields) {
        updateQuestion(question, questionResponse => {
            const questionIndex = questions.findIndex(q => q.id === questionResponse.id);
            questions[questionIndex] = questionResponse;

            closeCreateEditForm(clearFields);
            setQuestionsStatusInfo();
        })
    }

    function deleteAllQuestionsFromDatabase() {
        deleteAllQuestions(() => {
            setQuestions([]);
            setCurrentPage(0);
            setQuestionCount(0);
            useDeleteAllQuestionConfirmForm.closeForm();
            setQuestionsStatusInfo();
        })
    }

    /**
     * Delete the selected question.
     * <br/>
     * <i>After deleting the question, the (validation) status of the questions is retrieved in the database to inform the administrator if the game cannot be started due to insufficient number of questions etc.</i>
     * <br/>
     * If there is only one question on the current page, there is need to lower the page (go to the previous one - if possible), otherwise it would attempt to load a page that can no longer be loaded due to insufficient number of questions.
     * <br/>
     * If the number of questions per page is less than the number of items that can be displayed on the page, only one deleted question will be removed, the entire page will not load because this is unnecessary when one question is removed. when the current one is not fully filled.
     * <br/>
     * The last case is to delete a question on a full page, so there is need to retread the entire page to retrieve current questions.
     */
    function deleteQuestion() {
        deleteQuestionById(questionToDelete.id, () => {
            const questionsLength = questions.length;
            if (questionsLength === 1 && currentPage > 0) {
                setCurrentPage(currentPage - 1);
            } else if (questionsLength < questionsPerPage) {
                setQuestions(questions.filter(question => {
                    return question.id !== questionToDelete.id
                }));
                setQuestionCount(questionCount - 1)
            } else {
                loadQuestionList();
            }
            setQuestionsStatusInfo();
        });

        useDeleteQuestionConfirmForm.closeForm();
    }

    function openCreateQuestionForm() {
        setQuestionForEdit(undefined);
        setFormTitle(t("questions-create-question-form-title"));
        useCreateEditQuestionForm.openForm();
    }

    function openEditQuestionForm(question) {
        setQuestionForEdit(question);
        setFormTitle(t("questions-edit-question-form-title"));
        useCreateEditQuestionForm.openForm();
    }

    function closeCreateEditForm(clearFields) {
        clearFields();
        setQuestionForEdit(undefined);
        useCreateEditQuestionForm.closeForm();
    }

    /**
     * Determining the status of questions. Thus, whether they correspond to the number and valid status, etc., so that they can be used to play the game.
     */
    function setQuestionsStatusInfo() {
        getQuestionsStatus(dtoOut => {
            setShowQuestionsStatusValidInfo(true);
            setQuestionsStatusDtoOut(dtoOut);
        })
    }

    /**
     * Obtaining a component with information about the (validation) status of questions in the database. Thus, whether the status of the questions corresponds to the requirements for playing the game.
     *
     * @returns {*} Alert component with information for administrators regarding the status of questions in the database
     */
    function getQuestionsStatusAlertComponent() {
        // No information has yet been received regarding the validation status of the questions in the database
        if (!questionsStatusDtoOut) {
            return getQuestionsStatusAlert("info", t("questions-edit-info-no-questions-validation-status"))
        }

        // Questions are not ready to be generated into the game
        if (!questionsStatusDtoOut.ready) {
            return getQuestionsStatusAlert("error", questionsStatusDtoOut.message)
        }

        // Questions are ready to be generated into the game
        return (
            <div>
                <Collapse in={showQuestionsStatusValidInfo} addEndListener={() => {
                }}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setShowQuestionsStatusValidInfo(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }>
                        {questionsStatusDtoOut.message}
                    </Alert>
                </Collapse>
            </div>
        )
    }

    function getQuestionsStatusAlert(severity, message) {
        return (
            <div>
                <Alert severity={severity}>{message}</Alert>
            </div>
        )
    }

    return (
        <>
            <h1>{t("questions-title")}</h1>

            {getQuestionsStatusAlertComponent()}

            <div>
                <Tooltip title={t("questions-btn-tooltip-create-new-question")}>
                    <Fab color="primary"
                         aria-label="create question"
                         size="small"
                         className={classes.fab}
                         onClick={openCreateQuestionForm}>
                        <AddIcon/>
                    </Fab>
                </Tooltip>
                <Tooltip title={t("questions-btn-tooltip-reload-all-questions")}>
                    <IconButton className={classes.button}
                                aria-label="refresh questions"
                                color="primary"
                                onClick={loadQuestionList}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
                {questions.length !== 0 && (
                    <Tooltip title={t("questions-btn-tooltip-delete-all-questions")}>
                        <Fab color="secondary"
                             aria-label="delete question"
                             size="small"
                             className={classes.fab}
                             onClick={useDeleteAllQuestionConfirmForm.openForm}
                        >
                            <DeleteSweepIcon/>
                        </Fab>
                    </Tooltip>
                )}
            </div>

            <QuestionPaginationContext.Provider
                value={{currentPage, setCurrentPage, questionsPerPage, setQuestionsPerPage}}>
                <QuestionsOverview
                    questions={questions}
                    questionCount={questionCount}
                    deleteQuestion={deleteQuestionFormManipulation}
                    openEditQuestionForm={openEditQuestionForm}
                    setQuestionsStatusInfo={setQuestionsStatusInfo}/>
            </QuestionPaginationContext.Provider>

            <ConfirmDeleteQuestionForm
                isFormOpen={useDeleteQuestionConfirmForm.isFormOpen}
                closeForm={closeFormAndClearTmpVariable}
                question={questionToDelete}
                deleteQuestion={deleteQuestion}
            />

            <ConfirmDeleteAllQuestionsForm
                isFormOpen={useDeleteAllQuestionConfirmForm.isFormOpen}
                closeForm={useDeleteAllQuestionConfirmForm.closeForm}
                deleteAllQuestions={deleteAllQuestionsFromDatabase}
            />

            <CreateEditQuestionForm
                isFormOpen={useCreateEditQuestionForm.isFormOpen}
                closeForm={closeCreateEditForm}
                formTitle={formTitle}
                questionForEdit={questionForEdit}
                createQuestion={createQuestion}
                editQuestion={editQuestion}
            />
        </>
    )
}

export default Questions;
