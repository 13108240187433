import React from 'react';
import TextField from "@material-ui/core/TextField";

/**
 * A text field that is used to edit the signed-in user's profile data in the appropriate forms.
 * <br/>
 * <i>This is a simplification of the code so that it is not necessary to repeat the code for this text field all the time, but only the selected data is passed.</i>
 *
 * @author Jan Krunčík
 * @since 04.02.2020 21:03
 */

const ProfileTextField = props => {

    return (
        <TextField
            autoComplete={props.use.autoComplete}
            autoFocus={props.use.autoFocus}
            error={props.use.error}
            fullWidth
            helperText={props.use.helperText}
            id={props.use.id}
            label={props.use.label}
            type={props.use.type}
            margin="normal"
            onChange={props.use.handleChange}
            placeholder={props.use.placeholder}
            required={true}
            value={props.use.value}
            variant={"filled"}
            disabled={props.use.disabled}
        />
    )
};

export default ProfileTextField;
