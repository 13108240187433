import React from 'react';
import UserTable from "./table/UserTable";
import {useTranslation} from "react-i18next";

/**
 * Management of users registered in the application.
 *
 * @author Jan Krunčík
 * @since 15.09.2019 11:34
 */

function Users() {

    const {t} = useTranslation()

    return (
        <>
            <h1>{t("users-title")}</h1>

            <UserTable/>
        </>
    )
}

export default Users;
