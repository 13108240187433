import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import QuestionOrAnswerTextOrImage from "../../../../quiz/image/QuestionOrAnswerTextOrImage";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    dialogContent: {
        textAlign: 'center'
    }
}));

/**
 * Dialog used to confirm deletion of a question (including answers).
 *
 * @param isFormOpen true if the dialog is to be opened, otherwise false
 * <br>
 * @param closeForm function to close the dialog
 * <br>
 * @param question the question to be deleted is passed here only to display it's text or image
 * <br>
 * @param deleteQuestion function to delete the question
 * <br>
 * @returns {*} confirmation dialog for deleting the selected question
 * <br>
 * @constructor
 *
 * @author Jan Krunčík
 * @since 16.09.2019 23:22
 */
function ConfirmDeleteQuestionForm({isFormOpen, closeForm, question, deleteQuestion}) {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <Dialog
            open={isFormOpen}
            aria-labelledby="confirm-delete-question-dialog-title"
            aria-describedby="confirm-delete-question-dialog-description"
        >
            <DialogTitle
                id="confirm-delete-question-dialog-title">{t("confirm-delete-question-title")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-delete-question-dialog-description" className={classes.dialogContent}>
                    <QuestionOrAnswerTextOrImage
                        questionOrAnswer={question}
                        isQuestion={true}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeForm} color="primary">
                    {t("confirm-delete-question-btn-cancel")}
                </Button>
                <Button onClick={deleteQuestion} color="primary" autoFocus>
                    {t("confirm-delete-question-btn-delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteQuestionForm;
