import axios from 'axios';
import {DELETE, GET, POST, PUT} from "./method";
import AuthService from "./auth/AuthService";
import Cookies from 'js-cookie';
import {XSRF_TOKEN} from "./auth/properties";

const {getAccessToken} = AuthService();

/**
 * Get an url to load an image that indicates a question or answer (image instead of text).
 *
 * @param imgId image (/ record) id in the database
 * @returns {string} the url address described above, which is used to load an image from the database for display
 */
export function getQuestionOrAnswerImageUrl(imgId) {
    return process.env.REACT_APP_API_URL + "question-generator/question-answer-image/" + imgId;
}

/**
 * Get the url address to get the sound (for playback).
 *
 * @param kindOfSound the type of sound for which the address is to be obtained
 * @returns {string} address for obtaining the above sound (for playback)
 */
export function getSoundUrl(kindOfSound) {
    return process.env.REACT_APP_API_URL + "sound/" + kindOfSound
}

const APPLICATION_JSON_CONTENT_TYPE = "application/json; charset=utf-8";
const MULTIPART_FORM_DATA_CONTENT_TYPE = "multipart/form-data";

const ServerCall = {

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    async callApi(method, url, dtoIn, contentType) {
        return axios({
            method: method,
            url: url,
            headers: {
                "Content-Type": contentType,
                "Accept": "application/json",
                "authorization": getAccessToken(),
                "X-XSRF-TOKEN": Cookies.get(XSRF_TOKEN)
            },
            data: dtoIn,
            withCredentials: true
        })
    },

    async call(method, url, dtoIn) {
        return this.callApi(method, url, dtoIn, APPLICATION_JSON_CONTENT_TYPE);
    },

    async callMultipartFormData(method, url, dtoIn) {
        return this.callApi(method, url, dtoIn, MULTIPART_FORM_DATA_CONTENT_TYPE);
    },

    getBasicUrl: function () {
        return process.env.REACT_APP_API_URL
    },

    getAuthUrl: function (useCase) {
        return this.getBasicUrl() + "auth/" + useCase
    },

    getPlayerUrl: function (useCase) {
        return this.getBasicUrl() + "player/" + useCase;
    },

    getGameUrl: function (useCase) {
        return this.getBasicUrl() + "game/" + useCase;
    },

    getQuestionUrl: function (useCase) {
        return this.getBasicUrl() + "question/" + useCase;
    },

    getAnswerUrl: function (useCase) {
        return this.getBasicUrl() + "answer/" + useCase;
    },

    getQuestionGeneratorUrl: function (useCase) {
        return this.getBasicUrl() + "question-generator/" + useCase;
    },

    getUserUrl: function (useCase) {
        return this.getBasicUrl() + "user/" + useCase;
    },

    getDashboardUrl: function (useCase) {
        return this.getBasicUrl() + "dashboard/" + useCase;
    },

    getConfigurationUrl: function (useCase) {
        return this.getBasicUrl() + "configuration/" + useCase;
    },

    getSoundUrl: function (useCase) {
        return this.getBasicUrl() + "sound/" + useCase;
    },

    async addPlayer(player) {
        const url = this.getPlayerUrl("").slice(0, -1)
        return await ServerCall.call(POST, url, player);
    },

    async existPlayer(playerExistenceRequest) {
        const url = this.getPlayerUrl("exist");
        return await ServerCall.call(POST, url, playerExistenceRequest);
    },

    async playerGamesList(playerGamesListDtoIn) {
        const url = this.getPlayerUrl("player-games-list");
        return await ServerCall.call(POST, url, playerGamesListDtoIn);
    },

    async scoreListForPlayer(scoreListForPlayerDtoIn) {
        const url = this.getPlayerUrl("score-list-for-player");
        return await ServerCall.call(POST, url, scoreListForPlayerDtoIn);
    },

    async playerList(playerListDtoIn) {
        const url = this.getPlayerUrl("player-list");
        return await ServerCall.call(POST, url, playerListDtoIn);
    },

    async exportAllPlayersToCsv() {
        const url = this.getPlayerUrl("export-all-players-to-csv");
        return axios({
            method: GET,
            url: url,
            responseType: "text/csv; charset=utf-8",
            headers: {
                "Content-Type": APPLICATION_JSON_CONTENT_TYPE,
                "Accept": "text/csv; charset=utf-8",
                "authorization": getAccessToken(),
                "X-XSRF-TOKEN": Cookies.get(XSRF_TOKEN)
            }
        });
    },

    async exportAllPlayersToExcel() {
        const url = this.getPlayerUrl("export-all-players-to-excel")
        return this.callApiToDownloadFile(url)
    },

    async callApiToDownloadFile(url) {
        return axios({
            method: GET,
            url: url,
            responseType: "blob",
            headers: {
                "authorization": getAccessToken(),
                "X-XSRF-TOKEN": Cookies.get(XSRF_TOKEN)
            }
        })
    },

    async addGame(addGameDtoIn) {
        const url = this.getGameUrl("").slice(0, -1);
        return await ServerCall.call(POST, url, addGameDtoIn);
    },

    async deleteAllGames() {
        const url = this.getGameUrl("delete-all");
        return await ServerCall.call(DELETE, url, null);
    },

    async scoreList(playerGamesListDtoIn) {
        const url = this.getPlayerUrl("score-list");
        return await ServerCall.call(POST, url, playerGamesListDtoIn);
    },

    async getCorrectAnswerId(questionId) {
        const url = this.getQuestionGeneratorUrl(questionId);
        return await ServerCall.call(GET, url, null);
    },

    async generateQuestions() {
        const url = this.getQuestionGeneratorUrl("");
        return await ServerCall.call(GET, url, null);
    },

    async signIn(loginRequest) {
        const url = this.getAuthUrl("sign-in");
        return await ServerCall.call(POST, url, loginRequest);
    },

    async signUp(registrationRequest) {
        const url = this.getAuthUrl("sign-up");
        return await ServerCall.call(POST, url, registrationRequest);
    },

    async getQuestionList(dtoIn) {
        const url = this.getQuestionUrl("get-list");
        return await ServerCall.call(POST, url, dtoIn);
    },

    async deleteQuestionById(id) {
        const url = this.getQuestionUrl(id);
        return await ServerCall.call(DELETE, url, null);
    },

    async deleteAllQuestions() {
        const url = this.getQuestionUrl("delete-all");
        return await ServerCall.call(DELETE, url, null);
    },

    async deleteQuestionAnswers(questionId) {
        const url = this.getQuestionUrl("delete-question-answers/" + questionId);
        return await ServerCall.call(DELETE, url, null);
    },

    async getQuestionAnswerValidationInformation(dtoIn) {
        const url = this.getQuestionUrl("validation-status");
        return await ServerCall.call(POST, url, dtoIn);
    },

    async addQuestion(question) {
        const url = this.getQuestionUrl("");
        return await ServerCall.callMultipartFormData(POST, url, question);
    },

    async updateQuestion(question) {
        const url = this.getQuestionUrl("");
        return await ServerCall.callMultipartFormData(PUT, url, question);
    },

    async addAnswer(answer) {
        const url = this.getAnswerUrl("");
        return await ServerCall.callMultipartFormData(POST, url, answer);
    },

    async updateAnswer(answer) {
        const url = this.getAnswerUrl("");
        return await ServerCall.callMultipartFormData(PUT, url, answer);
    },

    async deleteAnswerById(id) {
        const url = this.getAnswerUrl(id);
        return await ServerCall.call(DELETE, url, null);
    },

    async setCorrectAnswer(correctAnswer) {
        const url = this.getAnswerUrl("set-correct-answer");
        return await ServerCall.call(POST, url, correctAnswer);
    },

    async getProfileData() {
        const url = this.getUserUrl("profile");
        return await ServerCall.call(GET, url, null);
    },

    async checkCorrectPassword(checkCorrectPasswordDtoIn) {
        const url = this.getUserUrl("check-correct-password");
        return await ServerCall.call(POST, url, checkCorrectPasswordDtoIn);
    },

    async changeProfile(changeProfileDtoIn) {
        const url = this.getUserUrl("change-profile");
        return await ServerCall.call(POST, url, changeProfileDtoIn);
    },

    async changePassword(changePasswordDtoIn) {
        const url = this.getUserUrl("change-password");
        return await ServerCall.call(POST, url, changePasswordDtoIn);
    },

    async userList(userListDtoIn) {
        const url = this.getUserUrl("list");
        return await ServerCall.call(POST, url, userListDtoIn);
    },

    async getQuestionsStatus() {
        const url = this.getQuestionUrl("questions-status");
        return await ServerCall.call(GET, url, null);
    },

    async getDashboardOverview() {
        const url = this.getDashboardUrl("data-overview");
        return await ServerCall.call(GET, url, null);
    },

    async getConfiguration(dtoIn) {
        const url = this.getConfigurationUrl("get")
        return await ServerCall.call(POST, url, dtoIn)
    },

    async updateConfiguration(updateConfigurationDtoIn) {
        const url = this.getConfigurationUrl("")
        return await ServerCall.call(PUT, url, updateConfigurationDtoIn)
    },

    async getNumberOfQuestionCategories() {
        const url = this.getQuestionUrl("get-number-of-categories")
        return await ServerCall.call(GET, url, null)
    },

    async soundDetail(kindOfSound) {
        const url = this.getSoundUrl("detail/" + kindOfSound)
        return await ServerCall.call(GET, url, null)
    },

    async updateSound(dtoIn) {
        const url = this.getSoundUrl("")
        return await ServerCall.call(PUT, url, dtoIn)
    },

    async downloadSound(kindOfSound) {
        const url = this.getSoundUrl("download/" + kindOfSound)
        return this.callApiToDownloadFile(url)
    },

    async downloadPdfFile(fileName) {
        const url = this.getBasicUrl() + "file/download-pdf/" + fileName
        return this.callApiToDownloadFile(url)
    }
}

export default ServerCall;
