import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {ASC, DESC} from "../../../quiz/playersscore/table/TableProperties";
import {FILTER_BY_EMAIL, FILTER_BY_FIRST_NAME, FILTER_BY_LAST_NAME, FILTER_BY_USERNAME} from "./filterProperties";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const HEAD_CELLS = [
    {
        id: FILTER_BY_FIRST_NAME,
        numeric: false,
        disablePadding: false,
        labelTranslationKey: 'users-table-head-first-name'
    },
    {id: FILTER_BY_LAST_NAME, numeric: false, disablePadding: false, labelTranslationKey: 'users-table-head-last-name'},
    {id: FILTER_BY_USERNAME, numeric: false, disablePadding: false, labelTranslationKey: 'users-table-head-username'},
    {id: FILTER_BY_EMAIL, numeric: false, disablePadding: false, labelTranslationKey: 'users-table-head-email'},
    {id: 'roles', numeric: false, disablePadding: false, labelTranslationKey: 'users-table-head-roles'}
];

/**
 * Header (s) of the table with the users overview.
 * <br/>
 * Table (header) source: https://material-ui.com/components/tables/#sorting-amp-selecting
 *
 * @author Jan Krunčík
 * @since 16.07.2020 23:28
 */

function UserTableHead(props) {

    const classes = useStyles();

    const {t} = useTranslation()

    const {order, orderBy, onRequestSort} = props;

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow>
                {HEAD_CELLS.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id !== 'roles' ? <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : ASC}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.labelTranslationKey)}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                              {order === DESC ? 'sorted descending' : 'sorted ascending'}
                            </span>
                            ) : null}
                        </TableSortLabel> : t(headCell.labelTranslationKey)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

UserTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf([ASC, DESC]).isRequired,
    orderBy: PropTypes.string.isRequired
}

export default UserTableHead;
