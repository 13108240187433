// User role (permission to perform / call certain operations on BE)

export const ROLE_USER = {
    name: "ROLE_USER",
    text: "role-text-user"
};

export const ROLE_ADMIN = {
    name: "ROLE_ADMIN",
    text: "role-text-admin"
};
