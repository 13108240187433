import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from '@material-ui/core/styles';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
}));

function MySnackBarContentWrapper(props) {
    const classes = useStyles();
    const {className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
        </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>
            ]}
            {...other}
        />
    );
}

MySnackBarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

/**
 * Display message to the user. For example, some error or successful execution / completion of an operation, etc.
 *
 * https://material-ui.com/components/snackbars/#customized-snackbars
 *
 * @author Jan Krunčík
 * @since 03.09.2019 23:43
 */

const SnackBarDialog = ({snackBar}) => {

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        snackBar.closeSnackBar();
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: snackBar.verticalPosition,
                horizontal: snackBar.horizontalPosition
            }}
            open={snackBar.isSnackBarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <MySnackBarContentWrapper
                onClose={handleClose}
                variant={snackBar.snackBarVariant}
                message={snackBar.snackBarMessage}
            />
        </Snackbar>
    );
};

export default SnackBarDialog;