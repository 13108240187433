/**
 * Hook to support working with values in sessionStorage.
 * <br/>
 * When any value is to be inserted (/ save) into sessionStorage, the value is first encrypted.
 * <br/>
 * When it is need to retrieve the value from sessionStorage, the value is decrypted and returned.
 *
 * @returns {{getItem: (function(*=, *=): (any|undefined)), setItem: setItem}} functions for storing and retrieving values from sessionStorage
 *
 * @author Jan Krunčík
 * @since 08.02.2021 13:24
 */
function useSessionStorage() {

    const encryptor = require('simple-encryptor')(process.env.REACT_APP_ENCRYPTOR_KEY)

    function setItem(key, value = undefined) {
        if (key && value) {
            const encryptedValue = encryptor.encrypt(value)
            sessionStorage.setItem(key, encryptedValue)
        }
    }

    function getItem(key, defaultValue = undefined) {
        if (key) {
            const encryptedValue = sessionStorage.getItem(key)
            const decryptedValue = encryptor.decrypt(encryptedValue)
            return decryptedValue ? decryptedValue : defaultValue
        }

        return defaultValue
    }

    return {
        setItem,
        getItem
    }
}

export default useSessionStorage
