import React from 'react';
import {getQuestionOrAnswerImageUrl} from "../../../../server/ServerCall";
import {makeStyles} from "@material-ui/core/styles";
import {getSecureText} from "../../../../xss/xss";
import {useTranslation} from "react-i18next";
import {getQuestionCategoryByName} from "../constant/category";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        maxWidth: 600,
        maxHeight: 330
    },
    categorySpan: {
        marginLeft: 1,
        fontSize: 13,
        color: "gray"
    },
    marginBelowImage: {
        marginTop: 10
    }
}));

export function getImageAlt(getQuestionAlt) {
    return getQuestionAlt ? "Image of question" : "Image of answer"
}

/**
 * If the question or answer is given by text, the question or answer text is rendered. If the question or answer is given by image, the image is displayed.
 * <br/>
 * <i>The difference from component 'frontend/src/quiz/image/QuestionOrAnswerTextOrImage.js' is that it repaints every repaint of the parent components.</i>
 * <br/>
 * <i>The point is that if a user only changes the image for a question or answer, nothing will change in the state, because the image id remains the same. Returned response (updated question or answer) is set in the field and redraws the component, and this happens here, but the browser caches the loaded images, so the url address is added a time code, which will load the image (call again and reload with the changed image).</i>
 * <br/>
 * <i>Caching the image in the browser: https://stackoverflow.com/questions/47922687/force-react-to-reload-an-image-file/47923087</i>
 *
 * @param questionOrAnswer the question or answer whose text or image is to be displayed
 * @param isQuestion true if it is a question, otherwise false (answer)
 *
 * @param questionOrAnswer
 * @param isQuestion
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 25.02.2020 12:00
 */

function QuestionOrAnswerTextOrImage({questionOrAnswer, isQuestion}) {

    const classes = useStyles();

    const {t} = useTranslation()

    function getTextOrImage() {
        if (!questionOrAnswer) {
            return <span>{t("question-or-answer-text-or-image-question-or-answer-not-passed")}</span>
        }

        if (questionOrAnswer.givenByImage) {
            return (
                <div>
                    <img src={`${getQuestionOrAnswerImageUrl(questionOrAnswer.imgId)}?${new Date().getTime()}`}
                         alt={getImageAlt(isQuestion)}
                         className={classes.image}
                    />
                    <br/>
                    {isQuestion && (
                        <div className={clsx(classes.categorySpan, classes.marginBelowImage)}>
                            ({t(getQuestionCategoryByName(questionOrAnswer.category).text)})
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div>
                <span>
                    {getSecureText(questionOrAnswer.text)}
                </span>
                {isQuestion && (
                    <span className={classes.categorySpan}>
                        ({t(getQuestionCategoryByName(questionOrAnswer.category).text)})
                    </span>
                )}
            </div>
        )
    }

    return getTextOrImage()
}

export default QuestionOrAnswerTextOrImage;
