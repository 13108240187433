export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const SIGN_IN = "/sign-in";
export const SIGN_UP = "/sign-up";
export const SCORE = "/score";
export const USERS = DASHBOARD + "/users";
export const PLAYERS = DASHBOARD + "/players";
export const GAMES = DASHBOARD + "/games";
export const QUESTIONS = DASHBOARD + "/questions";
export const QUIZ_CONFIGURATION = DASHBOARD + "/quiz-configuration";
export const PROFILE = DASHBOARD + "/profile";
export const SOUNDS = DASHBOARD + "/sounds";
