import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {ASC, DESC} from "../../../quiz/playersscore/table/TableProperties";
import Call from "../../../server/Call";
import {FILTER_BY_EMAIL} from "./filterProperties";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CachedIcon from "@material-ui/icons/Cached";
import TableContainer from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import {TableBody} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {getSecureText} from "../../../xss/xss";
import TableFooter from "@material-ui/core/TableFooter";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import UserTableHead from "./UserTableHead";
import UserTablePagination from "./UserTablePagination";
import {UserToolbarContext} from "../context/UserToolbarContext";
import UserTableToolbar from "./UserTableToolbar";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '95%',
        margin: 'auto'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}));

/**
 * Displays a table with a list of registered users.
 *
 * @author Jan Krunčík
 * @since 16.07.2020 23:08
 */

function UserTable() {

    const classes = useStyles(() => {
    });

    const {t} = useTranslation()

    const [order, setOrder] = React.useState(DESC)
    const [orderBy, setOrderBy] = React.useState(FILTER_BY_EMAIL)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [dense, setDense] = React.useState(false)

    const {userList} = Call()
    const [users, setUsers] = useState([])
    const [totalUsersCount, setTotalUsersCount] = useState(0)

    const [filter, setFilter] = useState('')
    const [filterBy, setFilterBy] = useState(FILTER_BY_EMAIL)

    useEffect(() => {
        loadUserList()
    }, [order, orderBy, page, rowsPerPage])

    const loadUserList = () => {
        const dtoIn = createUserListDtoIn();
        userList(dtoIn, dtoOut => {
            if ((!dtoOut.users || dtoOut.users === 0) && page > 0) {
                setPage(0)
            }
            setUsers(dtoOut.users)
            setTotalUsersCount(dtoOut.count)
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === ASC
        setOrder(isAsc ? DESC : ASC)
        setOrderBy(property)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    const handleChangeDense = event => {
        setDense(event.target.checked)
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalUsersCount - page * rowsPerPage)

    const createUserListDtoIn = () => {
        return {
            filter,
            filterBy,
            order,
            orderBy,
            page,
            rowsPerPage
        }
    }

    const removeRoleTextFromRoleName = roles => {
        return roles.map(role => role.substring(5))
    }

    const lowerCaseAllWordsExceptFirstLetters = roles => {
        return roles.map(role => role.charAt(0) + role.slice(1).toLowerCase())
    }

    const getRolesForRender = roles => {
        const roleNamesOnly = removeRoleTextFromRoleName(roles)
        const camelCaseRole = lowerCaseAllWordsExceptFirstLetters(roleNamesOnly)
        return camelCaseRole.join(", ")
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Tooltip title="Reload users">
                    <IconButton className={classes.button}
                                aria-label="reload"
                                onClick={loadUserList}
                    >
                        <CachedIcon/>
                    </IconButton>
                </Tooltip>
                <UserToolbarContext.Provider value={{filter, setFilter, filterBy, setFilterBy, loadUserList}}>
                    <UserTableToolbar/>
                </UserToolbarContext.Provider>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <UserTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {users.map((user, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}>
                                        <TableCell align="center">{getSecureText(user.firstName)}</TableCell>
                                        <TableCell align="center">{getSecureText(user.lastName)}</TableCell>
                                        <TableCell align="center">{getSecureText(user.username)}</TableCell>
                                        <TableCell align="center">{getSecureText(user.email)}</TableCell>
                                        <TableCell
                                            align="center">{getSecureText(getRolesForRender(user.roles))}</TableCell>
                                    </TableRow>
                                )
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                                    <TableCell colSpan={2}/>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <UserTablePagination
                                    totalUsersCount={totalUsersCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense}/>}
                label={t("user-table-padding")}
            />
        </div>
    )
}

export default UserTable;
