import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import toRenderProps from 'recompose/toRenderProps';
import withState from 'recompose/withState';
import Call from "../../../server/Call";
import {useTranslation} from "react-i18next";

const WithState = toRenderProps(withState('anchorEl', 'updateAnchorEl', null));

/**
 * To export the player list to a file (CSV or Excel).
 *
 * @author Jan Krunčík
 * @since 26.06.2020 4:18
 */

function ExportPlayers() {

    const {exportAllPlayersToCsv, exportAllPlayersToExcel} = Call();
    const {t} = useTranslation()

    return (
        <div>
            <WithState>
                {({anchorEl, updateAnchorEl}) => {
                    const open = Boolean(anchorEl);
                    const handleClose = exportFce => {
                        updateAnchorEl(null);
                        if (exportFce) {
                            exportFce();
                        }
                    }

                    return (
                        <>
                            <Button
                                aria-owns={open ? 'render-props-menu' : undefined}
                                aria-haspopup="true"
                                onClick={event => {
                                    updateAnchorEl(event.currentTarget);
                                }}
                            >
                                {t("export-players-btn-export")}
                            </Button>
                            <Menu id="render-props-menu" anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
                                <MenuItem onClick={() => handleClose(exportAllPlayersToCsv)}>csv</MenuItem>
                                <MenuItem onClick={() => handleClose(exportAllPlayersToExcel)}>excel</MenuItem>
                            </Menu>
                        </>
                    );
                }}
            </WithState>
        </div>
    )
}

export default ExportPlayers;
