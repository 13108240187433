import {useState} from 'react';
import {INFO} from "./Variant";
import {BOTTOM} from "./VerticalPosition";
import {LEFT} from "./HorizontalPosition";
import {useTranslation} from "react-i18next";

/**
 * Setting values for SnackBar manipulation. For example, its opening, which message to display and what variant (/ message type) it is, etc.
 *
 * @author Jan Krunčík
 * @since 03.09.2019 23:43
 */

function useSnackBar() {

    const {t} = useTranslation()

    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [snackBarVariant, setSnackBarVariant] = useState(INFO);
    const [snackBarMessage, setSnackBarMessage] = useState(t("use-snackbar-msg-not-specified"));
    const [verticalPosition, setVerticalPosition] = useState(BOTTOM);
    const [horizontalPosition, setHorizontalPosition] = useState(LEFT);

    function openSnackBar(sbVariant, sbMessage, vPosition, hPosition) {
        setSnackBarVariant(sbVariant);
        setSnackBarMessage(sbMessage);
        setVerticalPosition(vPosition);
        setHorizontalPosition(hPosition);
        setIsSnackBarOpen(true);
    }

    function closeSnackBar() {
        setIsSnackBarOpen(false);
    }

    return {
        isSnackBarOpen,
        snackBarVariant,
        snackBarMessage,
        verticalPosition,
        horizontalPosition,
        openSnackBar,
        closeSnackBar
    }
}

export default useSnackBar;
