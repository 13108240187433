import React from 'react';
import {useTranslation} from "react-i18next";

/**
 * This component is displayed when the user enters an unknown URL to the application.
 *
 * @author Jan Krunčík
 * @since 14.09.2019 14:38
 */

function UnknownUrl() {

    const {t} = useTranslation()

    return (
        <>
            <h2>{t("unknown-url-h2")}: {window.location.href}</h2>
        </>
    )
}

export default UnknownUrl;
