import React, {useContext, useState} from 'react';
import {Redirect} from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useTextField from "../../textfield/useTextField";
import TextFieldForm from "../../textfield/TextFieldForm";
import {QuizContext} from "../../quiz/context/QuizContext";
import {WARNING} from "../../snackbar/Variant";
import Call from "../../server/Call";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../snackbar/Settings";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import {ROLE_ADMIN, ROLE_USER} from "../question/content/form/roles";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import useRole from "./role/useRole";
import RolesList from "./role/RolesList";
import {REG_EX_EMAIL, REG_EX_FIRST_NAME, REG_EX_LAST_NAME, REG_EX_PASSWORD, REG_EX_USERNAME} from "../properties/form";
import {useTranslation} from "react-i18next";
import {SIGN_IN} from "../../route/url";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    formControl: {
        display: 'flex',
        textAlign: 'center'
    },
    fab: {
        margin: theme.spacing(1)
    },
    title: {
        margin: theme.spacing(1)
    }
}));

/**
 * New user registration form.
 *
 * https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/sign-up
 *
 * @author Jan Krunčík
 * @since 14.09.2019 11:52
 */

function SignUpForm() {

    const quizContext = useContext(QuizContext);

    const classes = useStyles();
    const {signUp} = Call();
    const [redirect, setRedirect] = useState(undefined);

    const {t} = useTranslation()

    const firstName = useTextField(
        'sign-up-form-txt-first-name',
        '',
        REG_EX_FIRST_NAME,
        true,
        "normal",
        t("sign-up-form-txt-label-first-name"),
        "text",
        true,
        "fname",
        "outlined");
    const lastName = useTextField(
        'sign-up-form-txt-last-name',
        '',
        REG_EX_LAST_NAME,
        false,
        "normal",
        t("sign-up-form-txt-label-last-name"),
        "text",
        true,
        "lname",
        "outlined");
    const username = useTextField(
        'sign-up-form-txt-username',
        '',
        REG_EX_USERNAME,
        false,
        "normal",
        t("sign-up-form-txt-label-username"),
        "text",
        true,
        "username",
        "outlined");
    const email = useTextField(
        'sign-up-form-txt-email',
        '',
        REG_EX_EMAIL,
        false,
        "normal",
        t("sign-up-form-txt-label-email"),
        "text",
        true,
        "email",
        "outlined");
    const password = useTextField(
        'sign-up-form-txt-password',
        '',
        REG_EX_PASSWORD,
        false,
        "normal",
        t("sign-up-form-txt-label-password"),
        "password",
        true,
        "current-password",
        "outlined");
    const passwordConfirmation = useTextField(
        'sign-up-form-txt-password-confirmation',
        '',
        REG_EX_PASSWORD,
        false,
        "normal",
        t("sign-up-form-txt-label-password-confirmation"),
        "password",
        true,
        "current-password",
        "outlined");

    const {roles, addRole, removeRole, getRolesInString} = useRole();
    const [selectedRole, setSelectedRole] = useState(ROLE_USER);
    // The width of the "space" for the Category heading for the drop-down menu (just enough to fit the text)
    const labelWidth = 43;

    function handleChangeRole(event) {
        setSelectedRole(event.target.value);
    }

    function handleSignUp() {
        if (!checkEmptyFields()) {
            return;
        }
        if (!validateValues()) {
            return;
        }
        if (!checkConfirmationPassword()) {
            return;
        }

        const registrationRequest = createRegistrationRequest();
        signUp(registrationRequest, () => {
            setRedirect(SIGN_IN);
        });
    }

    function createRegistrationRequest() {
        return {
            username: username.value,
            email: email.value,
            firstName: firstName.value,
            lastName: lastName.value,
            password: password.value,
            passwordConfirmation: passwordConfirmation.value,
            roles: getRolesInString()
        }
    }

    function validateValues() {
        if (!firstName.validate()) {
            openWarningSnackBar(t("sign-up-form-validation-first-name-not-valid"));
            return false;
        }
        if (!lastName.validate()) {
            openWarningSnackBar(t("sign-up-form-validation-last-name-not-valid"));
            return false;
        }
        if (!username.validate()) {
            openWarningSnackBar(t("sign-up-form-validation-username-not-valid"));
            return false;
        }
        if (!email.validate()) {
            openWarningSnackBar(t("sign-up-form-validation-email-not-valid"));
            return false;
        }
        if (!password.validate()) {
            openWarningSnackBar(t("sign-up-form-validation-password-not-valid"));
            return false;
        }
        if (!passwordConfirmation.validate()) {
            openWarningSnackBar(t("sign-up-form-validation-password-confirmation-not-valid"));
            return false;
        }
        return true;
    }

    function checkEmptyFields() {
        if (!checkEmptyField(firstName.value, t("sign-up-form-empty-field-first-name"))) {
            return false;
        }
        if (!checkEmptyField(lastName.value, t("sign-up-form-empty-field-last-name"))) {
            return false;
        }
        if (!checkEmptyField(username.value, t("sign-up-form-empty-field-username"))) {
            return false;
        }
        if (!checkEmptyField(email.value, t("sign-up-form-empty-field-email"))) {
            return false;
        }
        if (!checkEmptyField(password.value, t("sign-up-form-empty-field-password"))) {
            return false;
        }
        return checkEmptyField(passwordConfirmation.value, t("sign-up-form-empty-field-password-confirmation"));
    }

    function checkConfirmationPassword() {
        if (password.value !== passwordConfirmation.value) {
            openWarningSnackBar(t("sign-up-form-confirmation-password-is-different"));
            return false;
        }
        return true;
    }

    function checkEmptyField(fieldValue, message) {
        if (!fieldValue) {
            openWarningSnackBar(message);
            return false;
        }
        return true;
    }

    function openWarningSnackBar(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    function checkRedirect() {
        if (redirect) {
            return <Redirect to={redirect}/>
        }
    }

    return (
        <>
            {checkRedirect()}

            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {t("sign-up-form-title")}
                    </Typography>
                    <div className={classes.form}>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldForm use={firstName}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldForm use={lastName}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldForm use={username}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldForm use={email}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldForm use={password}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldForm use={passwordConfirmation}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="role-select" required>
                                            {t("sign-up-form-role-input-label")}
                                        </InputLabel>
                                        <Select
                                            value={selectedRole}
                                            onChange={handleChangeRole}
                                            autoWidth={true}
                                            input={
                                                <OutlinedInput
                                                    labelWidth={labelWidth}
                                                    name="role"
                                                    id="role-select"
                                                />}
                                        >
                                            <MenuItem value={ROLE_USER}>{t(ROLE_USER.text)}</MenuItem>
                                            <MenuItem value={ROLE_ADMIN}>{t(ROLE_ADMIN.text)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Fab color="primary"
                                         aria-label="add"
                                         size="small"
                                         className={classes.fab}
                                         onClick={() => addRole(selectedRole)}
                                    >
                                        <AddIcon/>
                                    </Fab>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.title}>
                                        {t("sign-up-form-assigned-roles")}
                                    </Typography>
                                    <RolesList
                                        roles={roles}
                                        removeRole={removeRole}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            value="allowExtraEmails"
                                            color="primary"/>}
                                        label={t("sign-up-form-label-marketing-info")}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSignUp}
                        >
                            {t("sign-up-form-btn-sign-up")}
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href={SIGN_IN} variant="body2">
                                    {t("sign-up-form-link-already-have-account-sign-in")}
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default SignUpForm;
