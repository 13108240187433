import React from 'react';
import PropTypes, {shape} from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Answers from "../answer/Answers";
import Question from "./Question";
import {DEFAULT_ID} from "../../../property/Properties";

/**
 * Rendering of the currently guided question and its answers.
 * <br/>
 * <i>It will also be a branch to indicate the correct answer, etc.
 *
 * @param question an object (/ question) containing a question to be answered by the player (/ which will be rendered as a question entry) and a list of possible answers
 * @param chooseAnswer function that sets (/ saves) the id of the selected answer, which will be evaluated, according to the selected answer, the background of the answer will be highlighted
 * @param selectedAnswerId id of the answer marked by the player
 * @param correctAnswerId id the correct answer to the question
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 17.07.2020 16:21
 */
function QuestionAndAnswersGrid({question, chooseAnswer, selectedAnswerId, correctAnswerId}) {

    return (
        <Grid
            container
            direction="row"
            justify="center"
        >
            <Grid
                container
                direction="row"
                justify="center"
                spacing={1}
            >
                <Grid item xs={12} zeroMinWidth>
                    <Question
                        question={question}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} zeroMinWidth>
                    <Answers
                        answers={question.answers}
                        chooseAnswer={chooseAnswer}
                        selectedAnswerId={selectedAnswerId}
                        correctAnswerId={correctAnswerId}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

QuestionAndAnswersGrid.defaultProps = {
    question: {},
    selectedAnswerId: DEFAULT_ID,
    correctAnswerId: DEFAULT_ID
}

QuestionAndAnswersGrid.propTypes = {
    question: PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string,
        imgId: PropTypes.number,
        givenByImage: PropTypes.bool.isRequired,
        category: PropTypes.oneOf(["EASY", "MEDIUM", "HARD"]).isRequired,
        answers: PropTypes.arrayOf(shape({
            id: PropTypes.number.isRequired,
            questionId: PropTypes.number.isRequired,
            text: PropTypes.string,
            imgId: PropTypes.number,
            givenByImage: PropTypes.bool.isRequired
        })).isRequired
    }).isRequired,
    chooseAnswer: PropTypes.func.isRequired,
    selectedAnswerId: PropTypes.number.isRequired,
    correctAnswerId: PropTypes.number.isRequired
}

export default QuestionAndAnswersGrid;
