import React from 'react'
import StickyFooter from "./footer/StickyFooter"
import {makeStyles} from "@material-ui/core"
import useSound from "./quiz/sound/useSound"
import AppContent from "./AppContent"
import {
    SRC_ADD_SCORE_SOUND,
    SRC_ANSWER_SELECTION_SOUND,
    SRC_ASK_QUESTION_SOUND,
    SRC_BACKGROUND_SOUND,
    SRC_CORRECT_ANSWER_1_SOUND,
    SRC_CORRECT_ANSWER_2_SOUND,
    SRC_WRONG_ANSWER_SOUND
} from "./quiz/sound/soundProperties"

const useStyles = makeStyles(() => ({
    app: {
        margin: "auto",
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: `calc(100vh - 36px)`
    }
}))

function App() {

    const classes = useStyles()

    // Sounds
    const useBgSound = useSound(SRC_BACKGROUND_SOUND, true)
    const useAskQuestionSound = useSound(SRC_ASK_QUESTION_SOUND, false)
    const useCorrectAnswer1Sound = useSound(SRC_CORRECT_ANSWER_1_SOUND, false)
    const useCorrectAnswer2Sound = useSound(SRC_CORRECT_ANSWER_2_SOUND, false)
    const useAddScoreSound = useSound(SRC_ADD_SCORE_SOUND, false)
    const useWrongAnswerSound = useSound(SRC_WRONG_ANSWER_SOUND, false)
    const useAnswerSelectionSound = useSound(SRC_ANSWER_SELECTION_SOUND, false)

    return (
        <>
            <div className={classes.app}>
                <AppContent
                    useBgSound={useBgSound}
                    useAskQuestionSound={useAskQuestionSound}
                    useCorrectAnswer1Sound={useCorrectAnswer1Sound}
                    useCorrectAnswer2Sound={useCorrectAnswer2Sound}
                    useAddScoreSound={useAddScoreSound}
                    useWrongAnswerSound={useWrongAnswerSound}
                    useAnswerSelectionSound={useAnswerSelectionSound}
                />
            </div>
            <StickyFooter/>
        </>
    )
}

export default App
