import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationCz from "./locales/cz/translation.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: true,
        lng: "cz",
        fallbackLng: "cz", // Use cz if detected lng is not available

        keySeparator: false, // We do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // React already safes from xss
        },

        resources: {
            cz: {
                translations: translationCz
            }
        },
        // Have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    })
    .then(() => {
    })

export default i18n;
