import React from 'react';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FaceIcon from "@material-ui/icons/Face";
import {MDBIcon} from "mdbreact";
import {FILTER_BY_USERNAME} from "../../../quiz/score/filterProperties";
import {FILTER_BY_EMAIL} from "./filterProperties";
import {useTranslation} from "react-i18next";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

export const USERNAME_ICON = <FaceIcon/>;
const EMAIL_ICON = <MDBIcon icon="at"/>;

/**
 * Drop-down menu with items for selecting the column according to which the items (/ players) are to be filtered / searched.
 *
 * @param anchorEl settings for the menu, whether it should be open or not
 * @param closeMenu close menu function
 * @param handleFilterBy function to set values for filtering items
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 12.06.2020 16:58
 */

function FilterMenu({anchorEl, closeMenu, handleFilterBy}) {

    const {t} = useTranslation()

    const filterByUsername = t("player-filter-menu-filter-by-username")
    const filterByEmail = t("player-filter-menu-filter-by-email")

    return (
        <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
        >
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_USERNAME, filterByUsername, USERNAME_ICON)}>
                <ListItemIcon>
                    {USERNAME_ICON}
                </ListItemIcon>
                <ListItemText primary={filterByUsername}/>
            </StyledMenuItem>
            <StyledMenuItem
                onClick={() => handleFilterBy(FILTER_BY_EMAIL, filterByEmail, EMAIL_ICON)}>
                <ListItemIcon>
                    {EMAIL_ICON}
                </ListItemIcon>
                <ListItemText primary={filterByEmail}/>
            </StyledMenuItem>
        </StyledMenu>
    )
}

export default FilterMenu;
