import React, {useEffect, useState} from 'react'
import {
    MODERATOR_CORRECT_ANSWER,
    MODERATOR_CORRECT_ANSWER_STATIC,
    MODERATOR_DEFAULT,
    MODERATOR_NEW_QUESTION,
    MODERATOR_NEW_QUESTION_STATIC,
    MODERATOR_WRONG_ANSWER,
    MODERATOR_WRONG_ANSWER_STATIC
} from "./moderatorImageEnum"
import {makeStyles} from "@material-ui/core/styles"
import ModeratorCorrectAnswer from "./image/moderator-correct-answer.gif"
import ModeratorCorrectAnswerLastFrame from "./image/moderator-correct-answer-last-frame.png"
import ModeratorDefault from "./image/moderator-default.gif"
import ModeratorNewQuestion from "./image/moderator-new-question.gif"
import ModeratorNewQuestionLastFrame from "./image/moderator-new-question-last-frame.png"
import ModeratorWrongAnswer from "./image/moderator-wrong-answer.gif"
import ModeratorWrongAnswerLastFrame from "./image/moderator-wrong-answer-last-frame.png"

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: "300px",
        width: "100%"
    }
}))

/**
 * Hook representing a image of a moderator.
 * <br/>
 * <i>Depending on the set value, the corresponding animation (/ image) representing a certain operation in the game will be displayed.</i>
 * <br/>
 * <i>For example, that the player answered incorrectly / correctly or a new generation / entry of a question, etc.</i>
 *
 * @author Jan Krunčík
 * @since 24.09.2020 23:26
 */
function useModeratorImage() {

    const classes = useStyles()

    const [moderatorImage, setModeratorImage] = useState(MODERATOR_DEFAULT)

    /**
     * Return the image to be displayed.
     * <br/>
     * <i>The image will represent the player's operation in the game, or its result.</i>
     * <br/>
     * <i>For example, that the player started the game, or a new question was generated, the question was answered correctly / incorrectly, etc.</i>
     *
     * @returns {JSX.Element} one of the images described above representing the player's operation in the game or its outcome
     */
    function getModeratorImageToDisplay() {
        let src = ModeratorDefault
        let alt = "moderator-default"

        if (moderatorImage === MODERATOR_NEW_QUESTION) {
            src = ModeratorNewQuestion
            alt = "moderator-new-question"
        } else if (moderatorImage === MODERATOR_NEW_QUESTION_STATIC) {
            src = ModeratorNewQuestionLastFrame
            alt = "moderator-new-question-static"
        } else if (moderatorImage === MODERATOR_CORRECT_ANSWER) {
            src = ModeratorCorrectAnswer
            alt = "moderator-correct-answer"
        } else if (moderatorImage === MODERATOR_CORRECT_ANSWER_STATIC) {
            src = ModeratorCorrectAnswerLastFrame
            alt = "moderator-correct-answer-static"
        } else if (moderatorImage === MODERATOR_WRONG_ANSWER) {
            src = ModeratorWrongAnswer
            alt = "moderator-wrong-answer"
        } else if (moderatorImage === MODERATOR_WRONG_ANSWER_STATIC) {
            src = ModeratorWrongAnswerLastFrame
            alt = "moderator-wrong-answer-static"
        }

        return <img src={src} alt={alt} className={classes.image}/>
    }

    // If the default image is not displayed, the currently displayed image will be displayed only for a certain time, after which the original (/ default) image will be displayed back
    useEffect(() => {
        let interval = null

        // When the animation display is set for a new question, a static image (not an animation) will be displayed after it is played, so that there is a smooth transition to the next (default) animation
        if (moderatorImage === MODERATOR_NEW_QUESTION) {
            interval = setInterval(() => setModeratorImage(MODERATOR_NEW_QUESTION_STATIC), 3000)
        } else if (moderatorImage === MODERATOR_NEW_QUESTION_STATIC) {
            interval = setInterval(() => setModeratorImage(MODERATOR_DEFAULT), 100)
        } else if (moderatorImage === MODERATOR_CORRECT_ANSWER) {
            interval = setInterval(() => setModeratorImage(MODERATOR_CORRECT_ANSWER_STATIC), 2100)
        } else if (moderatorImage === MODERATOR_WRONG_ANSWER) {
            interval = setInterval(() => setModeratorImage(MODERATOR_WRONG_ANSWER_STATIC), 2100)
        }

        return () => clearInterval(interval)
    }, [moderatorImage])

    return {
        setModeratorImage,
        getModeratorImageToDisplay
    }
}

export default useModeratorImage
