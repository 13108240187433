/**
 * Data identifying a PDF file containing GDPR terms and game rules.
 *
 * @type {{FILE_NAME: string, KEY: string}} the name of the PDF file to be downloaded and the key to identify the download file
 */
/**
 *
 * @type {{FILE_NAME: string, KEY: string}}
 */
export const GDPR_AND_COMPETITION_RULES_PDF = {
    KEY: "gdpr_and_competition_rules",
    FILE_NAME: "GDPR_a_Pravidla_souteze_Brian_kviz.pdf"
}
