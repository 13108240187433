import React from 'react';
import {Route, Switch} from "react-router-dom";
import {DASHBOARD, GAMES, PLAYERS, PROFILE, QUESTIONS, QUIZ_CONFIGURATION, SOUNDS, USERS} from "../../../route/url";
import Users from "../../user/Users";
import Players from "../../player/Players";
import DashboardContent from "../content/DashboardContent";
import Questions from "../../question/Questions";
import QuizConfiguration from "../../quiz/QuizConfiguration";
import Profile from "../../profile/Profile";
import UnknownUrl from "../../../route/UnknownUrl";
import RoleRoute from "../../../route/RoleRoute";
import {ROLE_ADMIN} from "../../../server/auth/userRoles";
import Games from "../../game/Games";
import Sounds from "../../sound/Sounds";

/**
 * Nested routing for components that are "part" of the Dashboard component. I.e. they are rendered within this component.
 *
 * @author Jan Krunčík
 * @since 04.10.2019 23:02
 */

const DashboardRoute = () => {

    return (
        <Switch>
            <RoleRoute path={DASHBOARD} exact component={DashboardContent} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={USERS} exact component={Users} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={PLAYERS} exact component={Players} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={GAMES} exact component={Games} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={QUESTIONS} exact component={Questions} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={QUIZ_CONFIGURATION} exact component={QuizConfiguration} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={PROFILE} exact component={Profile} allowedRoles={[ROLE_ADMIN]}/>
            <RoleRoute path={SOUNDS} exact component={Sounds} allowedRoles={[ROLE_ADMIN]}/>

            <Route component={UnknownUrl}/>
        </Switch>
    )
};

export default DashboardRoute;
