import React, {useEffect, useState} from 'react'
import {
    GUEST_CORRECT_ANSWER,
    GUEST_CORRECT_ANSWER_STATIC,
    GUEST_DEFAULT,
    GUEST_NEW_QUESTION,
    GUEST_NEW_QUESTION_STATIC,
    GUEST_WRONG_ANSWER,
    GUEST_WRONG_ANSWER_STATIC
} from "./guestImageEnum"
import {makeStyles} from "@material-ui/core/styles"
import GuestCorrectAnswer from "./image/guest-correct-answer.gif"
import GuestCorrectAnswerLastFrame from "./image/guest-correct-answer-last-frame.png"
import GuestDefault from "./image/guest-default.gif"
import GuestNewQuestion from "./image/guest-new-question.gif"
import GuestNewQuestionLastFrame from "./image/guest-new-question-last-frame.png"
import GuestWrongAnswer from "./image/guest-wrong-answer.gif"
import GuestWrongAnswerLastFrame from "./image/guest-wrong-answer-last-frame.png"

const useStyles = makeStyles(() => ({
    image: {
        maxWidth: "300px",
        width: "100%"
    }
}))

/**
 * Hook to display the image (/ animation) of the guest.
 * <br/>
 * <i>If a display other than the default image is displayed, the default image will be displayed again after a certain time.</i>
 *
 * @author Jan Krunčík
 * @since 24.09.2020 22:51
 */
function useGuestImage() {

    const classes = useStyles()

    const [guestImage, setGuestImage] = useState(GUEST_DEFAULT)

    /**
     * Return the image for display.
     * <br/>
     * <i>The returned image will represent the operation performed in the game, for example that the player answered right / wrong or that a new question was generated, etc.</i>
     *
     * @returns {JSX.Element} an image representing the response to a player's operation
     */
    function getGuestImageToDisplay() {
        let src = GuestDefault
        let alt = "guest-default"

        if (guestImage === GUEST_NEW_QUESTION) {
            src = GuestNewQuestion
            alt = "guest-new-question"
        } else if (guestImage === GUEST_NEW_QUESTION_STATIC) {
            src = GuestNewQuestionLastFrame
            alt = "guest-new-question-static"
        } else if (guestImage === GUEST_CORRECT_ANSWER) {
            src = GuestCorrectAnswer
            alt = "guest-correct-answer"
        } else if (guestImage === GUEST_CORRECT_ANSWER_STATIC) {
            src = GuestCorrectAnswerLastFrame
            alt = "guest-correct-answer-static"
        } else if (guestImage === GUEST_WRONG_ANSWER) {
            src = GuestWrongAnswer
            alt = "guest-wrong-answer"
        } else if (guestImage === GUEST_WRONG_ANSWER_STATIC) {
            src = GuestWrongAnswerLastFrame
            alt = "guest-wrong-answer-static"
        }

        return <img src={src} alt={alt} className={classes.image}/>
    }

    // If an image representing a new question is displayed, the default image will be displayed after a certain time
    // Similarly for other images, if one of them is displayed, after a certain time (representing the running of the animation) the default image will be displayed
    useEffect(() => {
        let interval = null

        // When the animation display is set for a new question, a static image (not an animation) will be displayed after it is played, so that there is a smooth transition to the next (default) animation
        if (guestImage === GUEST_NEW_QUESTION) {
            interval = setInterval(() => setGuestImage(GUEST_NEW_QUESTION_STATIC), 3000)
        } else if (guestImage === GUEST_NEW_QUESTION_STATIC) {
            interval = setInterval(() => setGuestImage(GUEST_DEFAULT), 100)
        } else if (guestImage === GUEST_CORRECT_ANSWER) {
            interval = setInterval(() => setGuestImage(GUEST_CORRECT_ANSWER_STATIC), 2100)
        } else if (guestImage === GUEST_WRONG_ANSWER) {
            interval = setInterval(() => setGuestImage(GUEST_WRONG_ANSWER_STATIC), 2100)
        }

        return () => clearInterval(interval)
    }, [guestImage])

    return {
        setGuestImage,
        getGuestImageToDisplay
    }
}

export default useGuestImage
