import React from 'react';
import {withRouter} from 'react-router-dom';
import {DASHBOARD} from "./url";
import AuthService from "../server/auth/AuthService";
import DefaultMenu from "../app/menu/DefaultMenu";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    menuDiv: {
        margin: "auto",
        marginBottom: 10,
        maxWidth: "95%"
    }
}))

/**
 * Draws a menu that corresponds to the "position" (URL) where the user is located and whether the user is signed in or not.
 *
 * https://stackoverflow.com/questions/51459919/how-to-detect-route-changes-with-react-router-v4
 *
 * @author Jan Krunčík
 * @since 26.09.2019 18:47
 */

function MenuRoute() {

    const classes = useStyles()

    const {isUserLoggedIn} = AuthService();

    function isOnDashboardPath() {
        const path = window.location.pathname;
        return path.includes(DASHBOARD);
    }

    /**
     * If the user is located at the address '/dashboard', no component will be drawn, a specific menu with options for controlling the side menu will be drawn at the appropriate address.
     * <br/>
     * In other cases, for example when the user is on the home page, the default menu will be drawn, where there is a school logo and navigation buttons.
     *
     * @returns {*} default menu for rendering or empty element
     */
    function getMenu() {
        if (!isOnDashboardPath()) {
            return (
                <div className={classes.menuDiv}>
                    <DefaultMenu isUserSignedIn={isUserLoggedIn()}/>
                </div>
            )
        }
        return <></>
    }

    return getMenu()
}

export default withRouter(props => <MenuRoute {...props}/>);
