import React from 'react';
import PropTypes from "prop-types";
import {ASC, DESC} from "../../../quiz/playersscore/table/TableProperties";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

const HEAD_CELLS = [
    {id: 'username', numeric: false, disablePadding: false, label: 'player-table-head-username'},
    {id: 'email', numeric: false, disablePadding: false, label: 'player-table-head-email'}
];

/**
 * Header (s) of the table with the players overview.
 * <br/>
 * Table (header) source: https://material-ui.com/components/tables/#sorting-amp-selecting
 *
 * @author Jan Krunčík
 * @since 11.06.2020 22:40
 */

function PlayerTableHead(props) {

    const classes = useStyles();

    const {order, orderBy, onRequestSort} = props;

    const {t} = useTranslation()

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {HEAD_CELLS.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : ASC}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === DESC ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

PlayerTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf([ASC, DESC]).isRequired,
    orderBy: PropTypes.string.isRequired
}

export default PlayerTableHead;
