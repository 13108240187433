import React from 'react';
import Fab from "@material-ui/core/Fab";
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {getQuestionOrAnswerImageUrl} from "../../../../server/ServerCall";
import {getImageAlt} from "../../../../quiz/image/QuestionOrAnswerTextOrImage";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1)
    },
    image: {
        width: "100%",
        maxWidth: 360,
        maxHeight: 300
    }
}));

/**
 * Display an uploaded image (representing a question or answer).
 * <br/>
 * <i>Either the image upload button will be rendered, or if the image is uploaded, the image with the remove button will be rendered.</i>
 *
 * @param image the image to display
 * @param removeImage a function that removes the image (so that it does not render)
 * @param imgId image id to be retrieved from the database and displayed (if the user has not uploaded a new image and a question or answer is associated with an image)
 * @param isQuestion true if the question image is displayed, otherwise false for the answer image
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 20.11.2019 21:35
 */
function ImageRenderComponent({image, removeImage, imgId, isQuestion}) {

    const classes = useStyles();

    const {t} = useTranslation()

    function getRemoveButton() {
        return <Tooltip title={t("question-or-answer-image-render-btn-remove-image-tooltip")}>
            <Fab
                color="secondary"
                aria-label="remove-image"
                size="small"
                component="span"
                className={classes.fab}
                onClick={removeImage}>
                <DeleteIcon/>
            </Fab>
        </Tooltip>
    }

    function getUploadedImageAlt() {
        return isQuestion ? t("question-or-answer-image-render-alt-uploaded-question") : t("question-or-answer-image-render-alt-uploaded-answer");
    }

    /**
     * Obtaining a display component.
     * <br/>
     * <i>By priority, the user-uploaded image (if any) is always loaded, then the existing question or answer image (if any) is displayed. Otherwise, no new image has been uploaded, and none exists, so this information is displayed to the user.</i>
     *
     * @returns {*} one of the components described above for rendering an image (or uploading information)
     */
    function getComponentsToRenderImage() {
        // View a new uploaded image from the user (takes precedence over existing image (if any))
        if (image) {
            return <>
                <div>
                    <img
                        src={URL.createObjectURL(image)}
                        className={classes.image}
                        alt={getUploadedImageAlt()}
                    />
                </div>

                {getRemoveButton()}
            </>
        }

        // There is no uploaded image, it will show an existing image by its id (it is an existing image associated with the question or answer)
        if (imgId) {
            return <img src={getQuestionOrAnswerImageUrl(imgId)}
                        alt={getImageAlt(isQuestion, t)}
                        className={classes.image}/>
        }

        // Here, the user has not uploaded any image and there is no one for the question or answer (it is not editing), so this information will be displayed to the user
        return <div>{t("question-or-answer-image-render-div-no-image-uploaded")}</div>
    }

    return getComponentsToRenderImage();
}

export default ImageRenderComponent;
