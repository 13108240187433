import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Call from "../../../server/Call";
import {useTranslation} from "react-i18next";
import {useMountEffect} from "../../../mount/useMountEffect";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 10
    },
    formControl: {
        margin: theme.spacing(1)
    },
    numberOfQuestionsSelect: {
        marginLeft: 5
    },
    saveButton: {
        marginTop: 15
    }
}))

/**
 * Set the number of questions to be generated for each category.
 * <br/>
 * <i>So when you start the game, questions will be generated. This component sets how many questions are to be generated for each category.</i>
 *
 * @author Jan Krunčík
 * @since 13.08.2020 15:33
 */

function QuestionConfiguration() {

    const classes = useStyles()

    // Question configuration (value from database)
    const [questionsPerCategoryConfiguration, setQuestionsPerCategoryConfiguration] = useState({})
    const [numberOfQuestionsPerCategory, setNumberOfQuestionsPerCategory] = useState("1")

    // Selected number of questions for each category (to save changes)
    const [numberOfCategories, setNumberOfCategories] = useState("1")

    const {getConfiguration, updateConfiguration, getNumberOfQuestionCategories} = Call()

    const {t} = useTranslation()

    // Load the current configuration for the number of questions per category
    useMountEffect(() => {
        const dtoIn = {
            key: "NUMBER_OF_QUESTIONS_PER_CATEGORY"
        }
        getConfiguration(dtoIn, dtoOut => {
            handleReceiveDtoOut(dtoOut)
        })
    })

    // Retrieve the total number of question categories
    useMountEffect(() => {
        getNumberOfQuestionCategories(numberOfQuestionCategories => {
            setNumberOfCategories(numberOfQuestionCategories)
        })
    })

    // Set values for this component after receive the configuration from the server
    const handleReceiveDtoOut = dtoOut => {
        setQuestionsPerCategoryConfiguration(dtoOut)
        setNumberOfQuestionsPerCategory(dtoOut.value.toString())
    }

    // Save the change to the currently selected value in the select
    const handleChange = event => {
        setNumberOfQuestionsPerCategory(event.target.value.toString())
    }

    // Send a request to save a new / changed value (number of questions per category) to the server for saving to the database
    const handleUpdateConfiguration = () => {
        const dtoIn = {...questionsPerCategoryConfiguration}
        dtoIn.value = numberOfQuestionsPerCategory

        updateConfiguration(dtoIn, dtoOut => {
            handleReceiveDtoOut(dtoOut)
        })
    }

    // Calculation of the total number of questions that will be generated within one game (/ quiz)
    const computeTotalNumberOfQuestions = () => numberOfCategories * numberOfQuestionsPerCategory

    // Create a 'select' component to allow the user to choose the number of questions for one category
    const getNumberOfQuestionsSelect = () => {
        return (
            <Select
                labelId="number-of-questions-select"
                id="number-of-questions-select"
                className={classes.numberOfQuestionsSelect}
                value={numberOfQuestionsPerCategory}
                onChange={handleChange}
            >
                {getNumberOfQuestionsNumbers().map(i => {
                    return <MenuItem key={i} value={i}>{i}</MenuItem>
                })}
            </Select>
        )
    }

    // Creating a field with values (number of questions) for one category
    const getNumberOfQuestionsNumbers = () => {
        const numbers = []

        for (let i = 1; i <= 100; i++) {
            numbers[i - 1] = i
        }

        return numbers
    }

    return (
        <div className={classes.root}>
            <span>{t("question-configuration-questions-per-category-text")} {getNumberOfQuestionsSelect()}, {t("question-configuration-total-text")} {computeTotalNumberOfQuestions()}.</span>

            {questionsPerCategoryConfiguration && questionsPerCategoryConfiguration.value !== numberOfQuestionsPerCategory &&
            (
                <div className={classes.saveButton}>
                    <Button variant="contained" color="primary" onClick={handleUpdateConfiguration}>
                        {t("question-configuration-btn-save")}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default QuestionConfiguration;
