import {useContext} from 'react'
import Cookies from "js-cookie"
import {SOUNDS_ON} from "./soundProperties"
import {QuizContext} from "../context/QuizContext"

/**
 * 'Hook' to set sound playback on / off.
 * <br/>
 * The logical value of whether sounds should be played is stored in cookies. The user can use the icon on the horizontal menu bar at the top of the window to set whether the sounds should be played or not. This will change the corresponding value in Cookies.
 *
 * @author Jan Krunčík
 * @since 31.10.2020 1:23
 */
function useSoundSupport() {

    const quizContext = useContext(QuizContext)

    /**
     * Determine whether or not sounds should be played while playing the game.
     * <br/>
     * It will be determined by obtaining the appropriate value from the Cookies.
     * <br/>
     * If the value is not obtained from the Cookies, it will be considered that the application is loaded in the relevant browser for the first time or the value was intentionally deleted, therefore the default value will be inserted into the Cookies.
     *
     * @returns {boolean} true if the sounds are to be played while playing the game, otherwise false
     */
    const isSoundOn = () => {
        const soundsOn = Cookies.get(SOUNDS_ON)
        if (soundsOn === undefined || soundsOn === null) {
            // Default value
            Cookies.set(SOUNDS_ON, "true")
            return true
        }
        return Boolean(JSON.parse(soundsOn))
    }

    /**
     * Turn sounds on / off.
     * <br/>
     * It is determined whether the sounds are currently on or off, the value is negated, thus either turning the sounds on or off (/ sets to the opposite state).
     * <br/>
     * The new state, whether the sounds have been switched off or on, will be stored in Cookies for further use and in the state that the sounds respond to immediate switch-off / switch-on when the user clicks on the appropriate button.
     */
    const toggleSoundOn = () => {
        const negativeValue = !isSoundOn()
        quizContext.setAreSoundsOn(negativeValue)
        Cookies.set(SOUNDS_ON, negativeValue)
    }

    return {
        isSoundOn,
        toggleSoundOn
    }
}

export default useSoundSupport
