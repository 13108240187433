// It is used for filtering, but the user enters empty text first, then adds characters (the number of characters needs to be adjusted)
export const REG_EX_FILTER_USERNAME = /^[a-zA-Zá-žÁ-Ž][\wá-žÁ-Ž]{0,100}$/;
// Default number of lives
export const DEFAULT_QUESTION_INDEX = 0;

// Default number of lives
export const DEFAULT_LIVES = 3;

// Default score
export const DEFAULT_SCORE = 0;

// Default value (id) for an unmarked value (answer)
export const DEFAULT_ID = -1;

// Username and email that the player enters when the game starts
export const PLAYER_USERNAME = "playerUsername";
export const PLAYER_EMAIL = "playerEmail";
