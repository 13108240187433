import React, {useContext, useState} from 'react'
import PropTypes from "prop-types"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Typography from "@material-ui/core/Typography"
import TextFieldForm from "../../textfield/TextFieldForm"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import {useTranslation} from "react-i18next"
import {QuizContext} from "../context/QuizContext"
import Call from "../../server/Call"
import useTextField from "../../textfield/useTextField"
import {REG_EX_EMAIL, REG_EX_USERNAME} from "../../auth/properties/form"
import {makeStyles} from "@material-ui/core/styles"
import {ERROR, WARNING} from "../../snackbar/Variant"
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../snackbar/Settings"
import {PLAYER_EMAIL, PLAYER_USERNAME} from "../property/Properties"
import useSessionStorage from "../../session-storage/useSessionStorage"
import {GDPR_AND_COMPETITION_RULES_PDF} from "../../server/file/constants"

const useStyles = makeStyles(() => ({
    divUserHasAccount: {
        textAlign: "center"
    },
    typographyWarningMsg: {
        flexGrow: 1,
        marginBottom: 10,
        textAlign: "center"
    },
    divConfirmRegistrationCheckboxes: {
        marginTop: 10
    },
    spanDownloadGdprAndCompetitionRulesPdf: {
        color: "blue",
        cursor: "pointer",
        textDecoration: "underline"
    }
}))

/**
 * Dialog used to sign-in and register a new player.
 * <br/>
 * At the beginning of the dialog, there is a Checkbox, which the user controls whether he wants to sign-in or register as a player.
 * <br/>
 * If he / she is not yet registered as a player, he / she must enter a username, email and agree to the terms of the GDPR. If user is a high school or grammar school student, he can also mark the checkbox designated for this purpose.
 * <br/>
 * If the user is already registered as a player, all he has to do is check the appropriate checkbox and he will only see the text fields for entering the combination of username and email, so he will not have to agree to the GDPR conditions again.
 * <br/>
 * After confirming the dialog, the existence of the player will be checked, or it will be created, the entered data will be stored in the sessionStorage for further use in the application, at the end the function in the handleConfirm parameter will be called.
 *
 * @param isOpen true if the dialog described above is to be opened, otherwise false
 * <br/>
 * @param closeDialog function used to close the dialog
 * <br/>
 * @param handleConfirm function that will be called after confirming the values in the dialog, ie creating a new or signing-in an existing player
 * @returns {JSX.Element} the dialog described above, which is used to sign-in an existing one and register a new player so that the user can play the game
 * @constructor
 *
 * @author Jan Krunčík
 * @since 31.01.2021 21:16
 */
function SignInAndRegisterPlayerDialog({isOpen, closeDialog, handleConfirm}) {

    const {t} = useTranslation()

    const classes = useStyles()

    const {setItem} = useSessionStorage()

    const quizContext = useContext(QuizContext)
    const showSnackBarDialogWarning = (message, variant = WARNING) => quizContext.snackBar.openSnackBar(variant, message, VERTICAL_POSITION, HORIZONTAL_POSITION)

    const {addPlayer, existPlayer, downloadPdfFile} = Call()

    const removeAllWhitespace = text => text.replace(/\s/g, '')

    const [userHasAccount, setUserHasAccount] = useState(false)
    const [agreeWithGdpr, setAgreeWithGdpr] = useState(false)
    const [userIsStudent, setUserIsStudent] = useState(false)

    const username = useTextField(
        'sign-in-and-register-player-dialog-txt-username',
        '',
        REG_EX_USERNAME,
        true,
        "dense",
        t("sign-in-and-register-player-dialog-form-txt-username"),
        "username",
        true,
        "username",
        "standard")

    const email = useTextField(
        'sign-in-and-register-player-dialog-txt-email',
        '',
        REG_EX_EMAIL,
        false,
        "dense",
        t("sign-in-and-register-player-dialog-form-txt-email"),
        "email",
        true,
        "email",
        "standard")

    function createCheckbox(label, checked, handleChecked) {
        return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChecked}
                        color="primary"
                    />
                }
                label={label}
            />
        )
    }

    /**
     * Confirm the dialog, ie check whether the player with the entered data exists (and save his data in the sessionStorage) or create (/ register) a new player with the entered data.
     * <br/>
     * First, the values in the text fields are validated.
     * <br/>
     * In the case of new player registrations, the terms of the GDPR must be agreed.
     * <br/>
     * If it is a sign-in of an existing player, it will be checked that the player with the entered data exists and if so, the entered data will be stored in the sessionStorage.
     * <br/>
     * If it is a registration of a new player, the data will be registered and subsequently stored in the sessionStorage.
     * <br/>
     * At the end, the passed function will be called, which is to be executed after confirming the dialog.
     */
    function handlePlayerSignInOrRegistration() {
        if (!validateTextFields()) {
            return
        }

        // When it comes to registration, whether the user agrees to the GDPR
        if (!userHasAccount && !validateAgreeWithGdpr()) {
            return
        }

        if (userHasAccount) {
            // Check whether the player with the entered data exists and, if necessary, save his data in the sessionStorage
            const dtoIn = createExistPlayerDtoIn()
            existPlayer(dtoIn, dtoOut => {
                if (dtoOut) {
                    // The player with the entered data exists
                    playerSignedInOrRegistered(username.value.trim(), email.value.trim())
                } else {
                    showSnackBarDialogWarning(t("sign-in-and-register-player-dialog-exist-player-player-not-found"), ERROR)
                }
            })
        } else {
            // New player registration
            const dtoIn = createAddPlayerDtoIn()
            addPlayer(dtoIn, dtoOut => playerSignedInOrRegistered(dtoOut.username, dtoOut.email))
        }
    }

    function validateTextFields() {
        if (removeAllWhitespace(username.value).length === 0) {
            showSnackBarDialogWarning(t("sign-in-and-register-player-dialog-validation-username-not-specified"))
            return false
        }
        if (!username.validate()) {
            showSnackBarDialogWarning(t("sign-in-and-register-player-dialog-validation-invalid-username"))
            return false
        }
        if (removeAllWhitespace(email.value).length === 0) {
            showSnackBarDialogWarning(t("sign-in-and-register-player-dialog-validation-email-not-specified"))
            return false
        }
        if (!email.validate()) {
            showSnackBarDialogWarning(t("sign-in-and-register-player-dialog-validation-invalid-email"))
            return false
        }
        return true
    }

    function validateAgreeWithGdpr() {
        if (!agreeWithGdpr) {
            showSnackBarDialogWarning(t("sign-in-and-register-player-dialog-validation-gdpr-not-accepted"))
            return false
        }
        return true
    }

    function createExistPlayerDtoIn() {
        return {
            username: username.value.trim(),
            email: email.value.trim()
        }
    }

    function createAddPlayerDtoIn() {
        return {
            username: username.value.trim(),
            email: email.value.trim(),
            gdpr: agreeWithGdpr,
            isStudent: userIsStudent
        }
    }

    function playerSignedInOrRegistered(usernameParam, emailParam) {
        setItem(PLAYER_USERNAME, usernameParam)
        setItem(PLAYER_EMAIL, emailParam)
        closeDialog()
        username.setValue("")
        email.setValue("")
        handleConfirm()
    }

    /**
     * Create a label that will be specified at the checkbox to agree to the terms of the GDPR and the rules of the game.
     *
     * @returns {JSX.Element} a label containing the text regarding the approval of the GDPR and a link to download a PDF file containing specific data.
     */
    function getGdprAndCompetitionRulesLabel() {
        const gdprAndRulesSpan = (
            <span
                className={classes.spanDownloadGdprAndCompetitionRulesPdf}
                onClick={() => downloadPdfFile(GDPR_AND_COMPETITION_RULES_PDF.KEY, GDPR_AND_COMPETITION_RULES_PDF.FILE_NAME)}
            >
                {t("sign-in-and-register-player-dialog-chcb-gdpr-text-2")}
            </span>
        )

        return (
            <label>
                {t("sign-in-and-register-player-dialog-chcb-gdpr-text-1")} {gdprAndRulesSpan} {t("sign-in-and-register-player-dialog-chcb-gdpr-text-3")}
            </label>
        )
    }

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="sign-in-and-register-player-dialog"
            disableBackdropClick
            onClose={closeDialog}
        >
            <DialogTitle id="sign-in-and-register-player-dialog-title">
                {userHasAccount ? t("sign-in-and-register-player-dialog-title-sign-in") : t("sign-in-and-register-player-dialog-title-registration")}
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.divUserHasAccount}>
                    {createCheckbox(
                        t("sign-in-and-register-player-dialog-chcb-user-has-account-text"),
                        userHasAccount,
                        () => setUserHasAccount(!userHasAccount)
                    )}
                </div>
                {!userHasAccount &&
                <Typography
                    variant="subtitle1"
                    color="secondary"
                    className={classes.typographyWarningMsg}
                >
                    {t("sign-in-and-register-player-dialog-remember-username-and-email-warning")}
                </Typography>}
                <TextFieldForm use={username}/>
                <TextFieldForm use={email}/>
                {!userHasAccount && (
                    <div className={classes.divConfirmRegistrationCheckboxes}>
                        {createCheckbox(
                            getGdprAndCompetitionRulesLabel(),
                            agreeWithGdpr,
                            () => setAgreeWithGdpr(!agreeWithGdpr)
                        )}
                        {createCheckbox(
                            t("sign-in-and-register-player-dialog-chcb-user-is-student-text"),
                            userIsStudent,
                            () => setUserIsStudent(!userIsStudent)
                        )}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                    color="primary"
                >
                    {t("sign-in-and-register-player-dialog-btn-cancel")}
                </Button>
                <Button
                    onClick={handlePlayerSignInOrRegistration}
                    color="primary"
                >
                    {userHasAccount ? t("sign-in-and-register-player-dialog-btn-submit-sign-in") : t("sign-in-and-register-player-dialog-btn-submit-register")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SignInAndRegisterPlayerDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired
}

export default SignInAndRegisterPlayerDialog
