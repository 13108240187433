import React from 'react';
import {Redirect, Route} from "react-router-dom";
import AuthService from "../server/auth/AuthService";
import {DASHBOARD} from "./url";

const {isUserLoggedIn} = AuthService();

/**
 * If the signed-in user enters an address or attempts to click on the login screen, it will be automatically redirected to the default page of the signed-in user (dashboard).
 *
 * <i>Only the unsigned user can access the login page.</i>
 *
 * @author Jan Krunčík
 * @since 14.09.2019 23:33
 */

const SignInOrDashboardRoute = props => {

    function getComponent() {
        if (isUserLoggedIn()) {
            return <Redirect to={DASHBOARD}/>
        }
        return <Route {...props}/>
    }

    return (
        <>
            {getComponent()}
        </>
    )
};

export default SignInOrDashboardRoute;