// Category of questions

export const EASY = {
    name: "EASY",
    text: "question-category-easy"
};

export const MEDIUM = {
    name: "MEDIUM",
    text: "question-category-medium"
};

export const HARD = {
    name: "HARD",
    text: "question-category-hard"
};

export function getQuestionCategoryByName(name) {
    switch (name) {
        case EASY.name:
            return EASY;
        case MEDIUM.name:
            return MEDIUM;
        case HARD.name:
            return HARD;
        default:
            return EASY;
    }
}
