import {useCallback, useEffect, useState} from 'react';
import {getGameTime} from "./TimeSupport";

/**
 * Representation of game time.
 * Time is handled in the form of epoch time, as well as stored in the database and loaded (if necessary). But it is always necessary to subtract one hour.
 *
 * @author Jan Krunčík
 * @since 02.09.2019 19:20
 */

function Timer() {

    const [start, setStart] = useState(undefined);
    const [end, setEnd] = useState(undefined);
    const [currentTimeInString, setCurrentTimeInString] = useState('00:00:00:000');
    const [isActive, setIsActive] = useState(false);

    function startTimer() {
        setStart(Date.now());
        setIsActive(true);
    }

    /**
     * Stopping a game (setting values to indicate the end of the game).
     *
     * The total game time is passed to the callBack function, which is then stored in a database, etc.
     *
     * @param callBack a function that is called with the total game time parameter
     */
    function stopTimer(callBack) {
        const endTime = Date.now();
        setEnd(endTime);
        setIsActive(false);

        if (callBack) {
            callBack(endTime - start);
        }
    }

    const getTimeDifferenceInString = useCallback(time => {
        return getGameTime(time - start);
    }, [start]);

    // Set current / total game time (how long the player has been playing), which is then displayed
    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setCurrentTimeInString(getTimeDifferenceInString(Date.now()));
            }, 1);
        }
        return () => clearInterval(interval);
    }, [isActive, getTimeDifferenceInString]);

    /**
     * Response / Watch the end of the game.
     *
     * When the necessary data is set (isActive, start and end of the game - times), it is evaluated as the end of the game.
     *
     * But there is need to set the text that is being rendered correctly. Due to the call asynchronicity, a different time than the one set in the stopTimer function could be displayed. Because, for example, the timer would run a little later than the time would have been set in that function.
     */
    useEffect(() => {
        if (!isActive && start && end) {
            setCurrentTimeInString(getTimeDifferenceInString(end));
        }
    }, [isActive, start, end, getTimeDifferenceInString]);

    return {
        startTimer,
        stopTimer,
        currentTimeInString
    }
}

export default Timer;
