import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import PropTypes from "prop-types";
import {V1, V2, V3, V4} from "./constant";
import MixColumnChartTooltip from "./MixColumnChartTooltip";
import {useTranslation} from "react-i18next";

/**
 * Bar graph showing an overview of values in the database.
 *
 * @param dashboardOverview obtained values for display in the graph
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 09.07.2020 21:15
 */

function MixColumnChart({dashboardOverview}) {

    const [data, setData] = useState([]);

    const {t} = useTranslation()

    useEffect(() => {
        if (!dashboardOverview) {
            return
        }

        const tmpData = [
            {
                name: t("questions"),
                v1: dashboardOverview.questionCountGivenByText,
                v2: dashboardOverview.questionCountGivenByImage,
                v3: dashboardOverview.validQuestionCount,
                v4: dashboardOverview.invalidQuestionCount
            },
            {
                name: t("answers"),
                v1: dashboardOverview.answerCountGivenByText,
                v3: dashboardOverview.answerCountGivenByImage
            },
            {name: t("users"), v1: dashboardOverview.playerCount, v3: dashboardOverview.userCount},
            {name: t("played-games"), v1: dashboardOverview.gameCount}
        ];

        setData(tmpData)
    }, [dashboardOverview])

    return (
        <ResponsiveContainer
            width="95%"
            height={400}
        >
            <BarChart
                data={data}
                margin={{top: 50}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>

                <Legend/>
                <Tooltip content={<MixColumnChartTooltip/>}/>

                <Bar dataKey={V1} stackId="a" fill="#8884d8"/>
                <Bar dataKey={V2} stackId="a" fill="#ffc658"/>
                <Bar dataKey={V3} stackId="b" fill="#82ca9d"/>
                <Bar dataKey={V4} stackId="b" fill="#aac658"/>
            </BarChart>
        </ResponsiveContainer>
    )
}

MixColumnChart.propTypes = {
    dashboardOverview: PropTypes.shape({
        questionCountGivenByText: PropTypes.number,
        questionCountGivenByImage: PropTypes.number,
        validQuestionCount: PropTypes.number,
        invalidQuestionCount: PropTypes.number,
        answerCountGivenByText: PropTypes.number,
        answerCountGivenByImage: PropTypes.number,
        userCount: PropTypes.number,
        playerCount: PropTypes.number,
        gameCount: PropTypes.number
    })
};

export default MixColumnChart;
