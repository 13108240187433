import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {UserToolbarContext} from "../context/UserToolbarContext";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import FilterMenu, {FIRST_NAME_ICON} from "./FilterMenu";
import {
    FILTER_BY_EMAIL,
    FILTER_BY_FIRST_NAME,
    FILTER_BY_LAST_NAME,
    FILTER_BY_USERNAME,
    REG_EX_FILTER_FIRST_NAME,
    REG_EX_FILTER_LAST_NAME
} from "./filterProperties";
import {REG_EX_FILTER_USERNAME} from "../../../quiz/property/Properties";
import {REG_EX_CHARACTERS_IN_EMAIL} from "../../player/table/filterProperties";
import {useTranslation} from "react-i18next";

const useSearchTextFieldStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: '0'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    }
}));

/**
 * Toolbar with text box to filter certain values (/ users).
 * <br/>
 * Toolbar source: https://material-ui.com/components/tables/#sorting-amp-selecting
 * <br/>
 * Search text field source: https://material-ui.com/components/text-fields/#customized-inputs
 * <br/>
 * Pictures / Icons with arrows from bootstrap:
 * - https://www.npmjs.com/package/mdbreact
 * - https://mdbootstrap.com/docs/react/content/icons-list/
 *
 * @author Jan Krunčík
 * @since 16.07.2020 23:36
 */

function UserTableToolbar() {

    const classes = useSearchTextFieldStyles(() => {
    });

    const userToolbarContext = React.useContext(UserToolbarContext)

    const {t} = useTranslation()

    // An icon representing the column by which the data will be filtered
    const [filterByIcon, setFilterByIcon] = useState(FIRST_NAME_ICON)
    const [filterByText, setFilterByText] = useState(t("users-table-filter-menu-txt-filter-by-first-name"))

    const [anchorEl, setAnchorEl] = React.useState(null)

    function handleOpenMenu(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleCloseMenu() {
        setAnchorEl(null)
    }

    /**
     * Set a value in the text box with the value by which items (/ users) will be filtered.
     * <br/>
     * <i>User will only be able to enter a value in the text field if it meets the syntax of the regular expression.
     *
     * @param event the event of pressing a key (entering a value) in the text field, to get the entered value
     */
    function handleChange(event) {
        const {value} = event.target

        if (userToolbarContext.filterBy === FILTER_BY_FIRST_NAME) {
            if (checkEmptyValue(value) || REG_EX_FILTER_FIRST_NAME) {
                userToolbarContext.setFilter(value)
            }
        } else if (userToolbarContext.filterBy === FILTER_BY_LAST_NAME) {
            if (checkEmptyValue(value) || REG_EX_FILTER_LAST_NAME) {
                userToolbarContext.setFilter(value)
            }
        } else if (userToolbarContext.filterBy === FILTER_BY_USERNAME) {
            if (checkEmptyValue(value) || REG_EX_FILTER_USERNAME) {
                userToolbarContext.setFilter(value)
            }
        } else if (userToolbarContext.filterBy === FILTER_BY_EMAIL) {
            if (checkEmptyValue(value) || REG_EX_CHARACTERS_IN_EMAIL.test(value)) {
                userToolbarContext.setFilter(value)
            }
        }
    }

    function checkEmptyValue(text) {
        return text.replace(/\s/, '') === ""
    }

    /**
     * Set values for filtering by a specific column.
     * <br/>
     * <i>For example, a user chose to filter items that contain a username, so sets the appropriate icon next to the filter text field, a placeholder that informs the user what value to filter, closes the menu (user has selected a filter), and empties text field for re-entering the filtering value.</i>
     *
     * @param filterBy the column by which the values will be filtered
     * @param selectedFilterByText text for placeholder text box
     * @param selectedFilterByIcon icon representing the selected item in the filter menu
     */
    function handleFilterBy(filterBy, selectedFilterByText, selectedFilterByIcon) {
        userToolbarContext.setFilterBy(filterBy);
        userToolbarContext.setFilter("");
        setFilterByIcon(selectedFilterByIcon);
        setFilterByText(selectedFilterByText);
        setAnchorEl(null);
    }

    /**
     * If the expression to be filtered in the specific column matches the required regular expression, the users are searched.
     */
    function searchUsers() {
        if ((userToolbarContext.filterBy === FILTER_BY_FIRST_NAME && !REG_EX_FILTER_FIRST_NAME.test(userToolbarContext.filter)) ||
            (userToolbarContext.filterBy === FILTER_BY_LAST_NAME && !REG_EX_FILTER_LAST_NAME.test(userToolbarContext.filter)) ||
            (userToolbarContext.filterBy === FILTER_BY_USERNAME && !REG_EX_FILTER_USERNAME.test(userToolbarContext.filter)) ||
            (userToolbarContext.filterBy === FILTER_BY_EMAIL && !REG_EX_CHARACTERS_IN_EMAIL.test(userToolbarContext.filter))) {
            return
        }
        userToolbarContext.loadUserList()
    }

    return (
        <Toolbar className={classes.root}>
            <IconButton className={classes.iconButton}
                        aria-label="menu"
                        onClick={handleOpenMenu}>
                {filterByIcon}
            </IconButton>
            <InputBase
                className={classes.input}
                placeholder={filterByText}
                inputProps={{'aria-label': filterByText}}
                value={userToolbarContext.filter}
                onChange={handleChange}
            />
            <IconButton type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={searchUsers}>
                <SearchIcon/>
            </IconButton>

            <FilterMenu anchorEl={anchorEl}
                        closeMenu={handleCloseMenu}
                        handleFilterBy={handleFilterBy}/>
        </Toolbar>
    )
}

export default UserTableToolbar;
