import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    footer: {
        fontSize: 'medium',
        fontFamily: "Arial, Helvetica, sans-serif",
        letterSpacing: 'normal',
        height: '35px',
        lineHeight: '35px',
        textAlign: 'center',
        borderTop: '1px solid #D8D8D8'
    },
    span: {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: 'normal'
    }
}));

/**
 * Always display the footer at the bottom of the page.
 * <br/>
 * <i>Center-aligned (even vertical) text source https://stackoverflow.com/questions/8865458/how-do-i-vertically-center-text-with-css</i>
 * <br/>
 * <i>Source for setting the footer display (its contents) https://love2dev.com/blog/html-website-copyright/</i>
 * <br/>
 * <i>Distribution of components for correct display https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-2</i>
 *
 * @author Jan Krunčík
 * @since 15.09.2019 0:35
 */

function StickyFooter() {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
          <span className={classes.span}>
            &copy; Copyright {new Date().getFullYear()} dofouknisimozek. All Rights Reserved
          </span>
        </footer>
    )
}

export default StickyFooter;