import {ACCESS_TOKEN, USER_ROLES} from "./properties";
import Cookies from 'js-cookie';
import {ALL_ROLES} from "./userRoles";

/**
 * Operations in the context of security and user access to components in the application.
 *
 * That is, determining whether a user is signed in, generating a token when the user signs in, signing off the user, and so on.
 *
 * @author Jan Krunčík
 * @since 08.09.2019 16:54
 */

const AuthService = () => {

    /**
     * For encrypting values when storing them in Cookies and decrypting them when retrieving them from Cookies.
     * <br/>
     * When encrypted, it calls JSON.stringify, so the object can also be saved.
     * <br/>
     * It calls JSON.parse during decryption.
     * <br/>
     * Returns null if an encryption or decryption error occurs.
     *
     * @type {*} encryption and decryption functions
     */
    const encryptor = require('simple-encryptor')(process.env.REACT_APP_ENCRYPTOR_KEY)

    const BEARER = "Bearer "
    const ACCESS_TOKEN_REGEX = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]{200,}$/

    /**
     * Sign-in user data settings.
     * <br/>
     * <i>The data of the signed-in user will be stored in places so that the application can continue to work with them.</i>
     *
     * @param accessToken generated access token of the signed-in user
     * @param roles role / permissions of the signed-in user
     * @param accessTokenExpiration
     */
    function setSignedInUserData(accessToken = "", roles = [], accessTokenExpiration = 1) {
        // Expiration time of cookies containing access data to the application
        // They will have the same validity period as the generated access token
        const cookiesValidityPeriod = new Date(new Date().getTime() + accessTokenExpiration)
        const cookieAttributes = {
            expires: cookiesValidityPeriod,
            sameSite: 'strict'
        }

        const token = BEARER + accessToken

        const encryptedToken = encryptor.encrypt(token)
        const encryptedRoles = encryptor.encrypt(roles)

        Cookies.set(ACCESS_TOKEN, encryptedToken, cookieAttributes)
        Cookies.set(USER_ROLES, encryptedRoles, cookieAttributes)
    }

    /**
     * Obtaining an access token from Cookies (and decrypting it).
     *
     * @returns {*} if the access token is obtained from Cookies and successfully decrypted, it will be returned, otherwise null will be returned
     */
    function getAccessToken() {
        return decryptValue(Cookies.get(ACCESS_TOKEN))
    }

    /**
     * Obtaining the role of the signed-in user (and decrypting them).
     *
     * @returns {*} if roles are retrieved from Cookies and successfully decrypted, they will be returned, otherwise an empty field will be returned
     */
    function getUserRoles() {
        return decryptValue(Cookies.get(USER_ROLES), [])
    }

    /**
     * Decryption of the value in the 'cipherText' parameter.
     *
     * @param cipherText value (/ encrypted text) to be decrypted
     * @param defaultValue value that will be returned if the 'cipherText' parameter is not specified or an error occurs during its decryption
     * @returns {any} decrypted text from the 'cipherText' parameter, otherwise the value in the defaultValue parameter (or null)
     */
    function decryptValue(cipherText, defaultValue = null) {
        if (cipherText) {
            const decryptedValue = encryptor.decrypt(cipherText)
            return decryptedValue ? decryptedValue : defaultValue
        }
        return defaultValue
    }

    function isUserLoggedIn() {
        const accessToken = getAccessToken()
        const roles = getUserRoles()

        return Boolean(accessToken && ACCESS_TOKEN_REGEX.test(accessToken.substring(BEARER.length))
            && roles && roles.length > 0 && roles.every(role => ALL_ROLES.includes(role)))
    }

    function logout() {
        Cookies.remove(ACCESS_TOKEN);
        Cookies.remove(USER_ROLES);
    }

    return {
        setSignedInUserData,
        getAccessToken,
        getUserRoles,
        isUserLoggedIn,
        logout
    }
}

export default AuthService;
