import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Route} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {SCORE, SIGN_IN} from "../../route/url"
import AuthService from "../../server/auth/AuthService";
import Divider from "@material-ui/core/Divider"
import ScoreIcon from '@material-ui/icons/Score'
import {FiLogOut} from "react-icons/fi"
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1)
    }
}));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

/**
 * The menu that is displayed on the right side of the dashboard screen.
 *
 * @author Jan Krunčík
 * @since 15.09.2019 14:48
 */

function UserMenu() {

    const {logout} = AuthService();

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {t} = useTranslation()

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function createRouteItem(url, text, icon) {
        return <Route render={({history}) => (
            <StyledMenuItem onClick={() => {
                history.push(url)
                handleClose()
            }}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text}/>
            </StyledMenuItem>
        )}/>
    }

    function createRouteSignOut() {
        return <Route render={({history}) => (
            <StyledMenuItem onClick={() => {
                history.push(SIGN_IN);
                logout();
                handleClose();
            }}>
                <ListItemIcon>
                    <FiLogOut/>
                </ListItemIcon>
                <ListItemText primary={t("sign-out")}/>
            </StyledMenuItem>
        )}/>
    }

    return (
        <>
            <IconButton className={classes.button} onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {createRouteItem(SCORE, t("score"), <ScoreIcon/>)}
                <Divider/>
                {createRouteSignOut()}
            </StyledMenu>
        </>
    )
}

export default UserMenu
