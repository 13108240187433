import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useToolbarStyles = makeStyles(() => ({
    title: {
        flex: '1 1 100%',
        textAlign: 'center'
    }
}));

/**
 * The title of the player's game scoreboard.
 *
 * @author Jan Krunčík
 * @since 03.02.2020 11:27
 */

function TableToolbar() {

    const classes = useToolbarStyles();

    const {t} = useTranslation()

    return (
        <Toolbar>
            <Typography className={classes.title} color="inherit" variant="subtitle1">
                {t("player-games-table-toolbar")}
            </Typography>
        </Toolbar>
    )
}

export default TableToolbar;
