import React, {useState} from 'react';
import ProfileForm from "./ProfileForm";
import Call from "../../server/Call";
import {useTranslation} from "react-i18next";
import {useMountEffect} from "../../mount/useMountEffect";

/**
 * Profile of signed-in user in the application.
 * <br/>
 * The entered data of the signed-in user will be loaded here and the user will be able to edit it.
 *
 * @author Jan Krunčík
 * @since 15.09.2019 15:37
 */

function Profile() {

    const {getProfileData} = Call();

    const {t} = useTranslation()

    const [userProfile, setUserProfile] = useState(undefined);

    useMountEffect(() => {
        getProfileData(userProfileResponse => {
            setUserProfile(userProfileResponse)
        })
    });

    return (
        <>
            <h1>{t("profile-page-title")}</h1>
            <ProfileForm userProfile={userProfile}
                         setUserProfile={setUserProfile}/>
        </>
    )
}

export default Profile;
