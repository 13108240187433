import React from 'react';
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import QuizResultQuestionListItem from "./QuizResultQuestionListItem";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        maxWidth: '85%',
        backgroundColor: theme.palette.background.paper
    }
}));

/**
 * View a list of questions (and its results) the user guessed in the game.
 *
 * @param questions the player guessed in the game (got generated)
 * @param selectedAnswers an array of objects with information about what answer the player has chosen and which are correct
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 29.01.2020 14:56
 */
function QuizResultQuestionList({questions, selectedAnswers}) {

    const classes = useStyles();

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {questions.map((question, index) => {
                return <QuizResultQuestionListItem key={index}
                                                   question={question}
                                                   selectedAnswerInfo={selectedAnswers[index]}/>
            })}
        </List>
    )
}

export default QuizResultQuestionList;
