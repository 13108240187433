import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import QuestionOrAnswerTextOrImage from "../image/QuestionOrAnswerTextOrImage";
import {makeStyles, withStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4)
    },
    answerItem: {
        textAlign: 'center'
    }
}));

const StyledListItem = withStyles({
    root: {
        backgroundColor: "rgb(118, 255, 3)",
        textAlign: 'center'
    }
})(ListItemText);

/**
 * View one specific answer to a guessed question.
 * <br/>
 * <i>Source for item styling: https://github.com/mui-org/material-ui/issues/13672<i/>
 *
 * @param answer one of the answers to the question
 * @param selectedAnswerInfo information about the answer to the question, which answer the player chose and which answer is correct
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 29.01.2020 18:02
 */
function QuizResultAnswerListItem({answer, selectedAnswerInfo}) {

    const classes = useStyles();

    function isSelectedAnswer() {
        return answer.id === selectedAnswerInfo.selectedAnswerId;
    }

    function isAnswerCorrect() {
        return answer.id === selectedAnswerInfo.correctAnswerId;
    }

    /**
     * Returns either a styled item (colored) if it is the correct answer to the question or a "normal" item to render the answer without any special adjustments.
     *
     * @returns {*} above item to display answer
     */
    function getListItem() {
        if (isAnswerCorrect()) {
            return <StyledListItem id={answer.id}
                                   primary={
                                       <QuestionOrAnswerTextOrImage
                                           questionOrAnswer={answer}
                                           isQuestion={false}/>
                                   }/>
        }

        return <ListItemText id={answer.id}
                             className={classes.answerItem}
                             primary={
                                 <QuestionOrAnswerTextOrImage
                                     questionOrAnswer={answer}
                                     isQuestion={false}/>
                             }/>
    }

    return (
        <ListItem key={answer.id} button className={classes.nested}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={isSelectedAnswer()}
                    tabIndex={-1}
                    disableRipple
                />
            </ListItemIcon>
            {getListItem()}
        </ListItem>
    )
}

export default QuizResultAnswerListItem;
