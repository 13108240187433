import React from 'react'
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep"
import {makeStyles} from "@material-ui/core/styles"
import {useTranslation} from "react-i18next"
import useForm from "../../../form/useForm"
import ConfirmDeleteAllGamesForm from "./ConfirmDeleteAllGamesForm"
import Call from "../../../server/Call"

const useStyles = makeStyles(() => ({
    fab: {
        marginTop: 5,
        margin: "auto"
    }
}))

/**
 * Button for deleting all games stored in the database.
 *
 * @author Jan Krunčík
 * @since 26.09.2020 0:06
 */
function DeleteAllGamesBtn() {

    const classes = useStyles()

    const {t} = useTranslation()

    const useDeleteAllGamesConfirmForm = useForm()

    const {deleteAllGames} = Call()

    /**
     * Removal of all played games (all players) from the database.
     */
    function deleteAllGamesFromDatabase() {
        useDeleteAllGamesConfirmForm.closeForm()
        deleteAllGames()
    }

    return (
        <>
            <Tooltip title={t("btn-delete-all-games-tooltip")}>
                <Fab color="secondary"
                     aria-label="delete games"
                     size="small"
                     className={classes.fab}
                     onClick={useDeleteAllGamesConfirmForm.openForm}
                >
                    <DeleteSweepIcon/>
                </Fab>
            </Tooltip>

            <ConfirmDeleteAllGamesForm
                isFormOpen={useDeleteAllGamesConfirmForm.isFormOpen}
                closeForm={useDeleteAllGamesConfirmForm.closeForm}
                deleteAllGames={deleteAllGamesFromDatabase}
            />
        </>
    )
}

export default DeleteAllGamesBtn
