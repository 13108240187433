import React from 'react'
import {useTranslation} from "react-i18next"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import PropTypes from 'prop-types'

/**
 * Dialog asking to confirm the deletion of all games in the database.
 *
 * @param isFormOpen true if the dialog is to be opened, otherwise false
 * <br/>
 * @param closeForm function to close the dialog
 * <br/>
 * @param deleteAllGames function for deleting all games in the database
 * <br/>
 * @returns {JSX.Element} dialog, which is used to confirm (/ ask the user) whether the user is sure that he wants to delete all played games in the database
 * <br/>
 * @constructor
 *
 * @author Jan Krunčík
 * @since 26.09.2020 0:25
 */
function ConfirmDeleteAllGamesForm({isFormOpen, closeForm, deleteAllGames}) {

    const {t} = useTranslation()

    return (
        <Dialog
            open={isFormOpen}
            aria-labelledby="confirm-delete-all-games-dialog-title"
        >
            <DialogTitle
                id="confirm-delete-all-games-dialog-title">{t("confirm-delete-all-games-title")}
            </DialogTitle>
            <DialogActions>
                <Button onClick={closeForm} color="primary">
                    {t("confirm-delete-all-games-btn-cancel")}
                </Button>
                <Button onClick={deleteAllGames} color="primary" autoFocus>
                    {t("confirm-delete-all-games-btn-delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDeleteAllGamesForm.propTypes = {
    isFormOpen: PropTypes.bool.isRequired,
    closeForm: PropTypes.func.isRequired,
    deleteAllGames: PropTypes.func.isRequired
}

export default ConfirmDeleteAllGamesForm
