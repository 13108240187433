import React, {useState} from 'react'
import PropTypes from "prop-types"
import {makeStyles, withStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import {Route} from "react-router-dom"
import {DASHBOARD, HOME, SCORE, SIGN_IN} from "../../route/url"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import AuthService from "../../server/auth/AuthService"
import Divider from "@material-ui/core/Divider"
import HomeIcon from '@material-ui/icons/Home'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ScoreIcon from '@material-ui/icons/Score'
import {FiLogIn, FiLogOut} from "react-icons/fi"
import UhkLogo from "../image/uhk-logo.png"
import {useTranslation} from "react-i18next"
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffSharpIcon from '@material-ui/icons/VolumeOffSharp'
import useSoundSupport from "../../quiz/sound/useSoundSupport"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    title: {
        fontFamily: "Bangers, cursive",
        fontSize: 40,
        flexGrow: 1,
        '@media (max-width:959px)': {
            fontSize: 25
        },
        '@media (max-width:599px)': {
            fontSize: 17
        }
    },
    image: {
        maxHeight: 50
    },
    menuButton: {
        marginRight: theme.spacing(1)
    }
}))

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    }
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
))

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem)

/**
 * Default menu that is displayed if the (signed-in) user is not at '/dashboard' route.
 * <br/>
 * <i>If the signed-in user is at the specified address, another menu will be drawn, which has the option to open the left (/ side) menu.</i>
 * <br/>
 * <i>So instead of the image, a button to open the side menu will be displayed.</i>
 *
 * @param isUserSignedIn true, if the user is signed-in, otherwise false, according to this value the menu items will be drawn (with options for signed-in or unsigned-in user)
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 31.07.2020 20:33
 */

function DefaultMenu({isUserSignedIn}) {

    const classes = useStyles()

    const {logout} = AuthService()

    const {isSoundOn, toggleSoundOn} = useSoundSupport()
    const getSoundIcon = () => isSoundOn() ? <VolumeUpIcon/> : <VolumeOffSharpIcon/>
    const [soundIcon, setSoundIcon] = useState(getSoundIcon())

    const menuId = "primary-menu"

    const [anchorEl, setAnchorEl] = React.useState(null)
    const isMenuOpen = Boolean(anchorEl)

    const {t} = useTranslation()

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    function createRouteItem(url, text, icon) {
        return <Route render={({history}) => (
            <StyledMenuItem onClick={() => {
                history.push(url)
                handleMenuClose()
            }}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text}/>
            </StyledMenuItem>
        )}/>
    }

    function createRouteSignOut() {
        return <Route render={({history}) => (
            <StyledMenuItem onClick={() => {
                handleMenuClose()
                history.push(SIGN_IN)
                logout()
            }}>
                <ListItemIcon>
                    <FiLogOut/>
                </ListItemIcon>
                <ListItemText primary={t("sign-out")}/>
            </StyledMenuItem>
        )}/>
    }

    function toggleSoundIcon() {
        toggleSoundOn()
        setSoundIcon(getSoundIcon())
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="static"
                style={{backgroundColor: "black"}}
            >
                <Toolbar>
                    <a
                        target="_blank"
                        href="https://www.uhk.cz/"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={UhkLogo}
                            alt={"Logo UHK"}
                            className={classes.image}
                        />
                    </a>
                    <Typography
                        variant="h6"
                        className={classes.title}>
                        {t("default-menu-title")}
                    </Typography>
                    <IconButton
                        edge="end"
                        className={classes.menuButton}
                        aria-label="button-volume"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={toggleSoundIcon}
                    >
                        {soundIcon}
                    </IconButton>
                    <IconButton
                        edge="end"
                        className={classes.menuButton}
                        aria-label="default menu"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleMenuOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <StyledMenu
                id={menuId}
                anchorEl={anchorEl}
                keepMounted
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                {createRouteItem(HOME, t("home"), <HomeIcon/>)}
                {isUserSignedIn && createRouteItem(DASHBOARD, t("dashboard"), <DashboardIcon/>)}
                {createRouteItem(SCORE, t("score"), <ScoreIcon/>)}
                <Divider/>
                {isUserSignedIn ? createRouteSignOut() : createRouteItem(SIGN_IN, t("sign-in"), <FiLogIn/>)}
            </StyledMenu>
        </div>
    )
}

DefaultMenu.propTypes = {
    isUserSignedIn: PropTypes.bool.isRequired
}

export default DefaultMenu
