import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

/**
 * The dialog is used to confirm the deletion of all questions and their answers from the database.
 *
 * @param isFormOpen - true if the dialog is to be opened, otherwise false
 * <br>
 * @param closeForm - function to close the dialog
 * <br>
 * @param deleteAllQuestions - a function that deletes all questions (including their answers) from the database
 * <br>
 * @returns {*} dialog to confirm deletion of all questions and their answers from the database
 * <br>
 * @constructor
 *
 * @author Jan Krunčík
 * @since 24.09.2019 15:14
 */
function ConfirmDeleteAllQuestionsForm({isFormOpen, closeForm, deleteAllQuestions}) {

    const {t} = useTranslation()

    return (
        <Dialog
            open={isFormOpen}
            aria-labelledby="confirm-delete-all-questions-dialog-title"
        >
            <DialogTitle
                id="confirm-delete-all-questions-dialog-title">{t("confirm-delete-all-questions-title")}
            </DialogTitle>
            <DialogActions>
                <Button onClick={closeForm} color="primary">
                    {t("confirm-delete-all-questions-btn-cancel")}
                </Button>
                <Button onClick={deleteAllQuestions} color="primary" autoFocus>
                    {t("confirm-delete-all-questions-btn-delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteAllQuestionsForm;
