import React, {useContext, useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useProfileTextField from "../useProfileTextField";
import {REG_EX_EMAIL, REG_EX_PASSWORD} from "../../properties/form";
import ProfileTextField from "../ProfileTextField";
import Container from "@material-ui/core/Container";
import Call from "../../../server/Call";
import {WARNING} from "../../../snackbar/Variant";
import {HORIZONTAL_POSITION, VERTICAL_POSITION} from "../../../snackbar/Settings";
import {QuizContext} from "../../../quiz/context/QuizContext";
import {getSecureText} from "../../../xss/xss";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    title: {
        textAlign: 'center'
    }
}));

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

/**
 * The form for changing the password of the signed in user.
 * <br/>
 * https://material-ui.com/components/dialogs/#customized-dialogs
 *
 * @param isFormOpen true if this form / dialog is to be opened, otherwise false
 * @param username the username of the currently signed-in user who wants to change its password (to send a request)
 * @param closeForm close dialog box
 * @param email signed-in user email
 * @returns {*}
 * @constructor
 *
 * @author Jan Krunčík
 * @since 04.02.2020 22:50
 */
function ChangePasswordForm({isFormOpen, closeForm, username, email}) {

    const classes = useStyles();

    const quizContext = useContext(QuizContext);

    const {changePassword} = Call();

    const {t} = useTranslation()

    const txtEmail = useProfileTextField("email",
        false,
        false,
        "",
        "change-password-form-email-text-field",
        t("change-password-txt-email-init-label"),
        "email",
        "",
        getSecureText(email),
        REG_EX_EMAIL,
        true);

    const txtCurrentPassword = useProfileTextField("current-password",
        true,
        false,
        t("change-password-txt-current-password-helper-text"),
        "change-password-form-current-password-text-field",
        t("change-password-txt-current-password-init-label"),
        "password",
        t("change-password-txt-current-password-init-placeholder"),
        "",
        REG_EX_PASSWORD);

    const txtNewPassword = useProfileTextField("new-password",
        false,
        false,
        t("change-password-txt-new-password-helper-text"),
        "change-password-form-new-password-text-field",
        t("change-password-txt-new-password-init-label"),
        "password",
        t("change-password-txt-new-password-init-placeholder"),
        "",
        REG_EX_PASSWORD);

    const txtConfirmPassword = useProfileTextField("new-password",
        false,
        false,
        t("change-password-txt-confirmation-password-helper-text"),
        "change-password-form-confirm-password-text-field",
        t("change-password-txt-confirmation-password-init-label"),
        "password",
        t("change-password-txt-confirmation-password-init-placeholder"),
        "",
        REG_EX_PASSWORD);

    useEffect(() => {
        txtEmail.setValue(getSecureText(email));
    }, [email]);

    /**
     * Each time user open the dialog box, the password input text fields are emptied.
     */
    useEffect(() => {
        if (isFormOpen) {
            txtCurrentPassword.setValue("");
            txtNewPassword.setValue("");
            txtConfirmPassword.setValue("");
        }
    }, [isFormOpen]);

    function executeChangePassword() {
        if (!validatePasswords()) {
            return;
        }

        const dtoIn = createChangePasswordDtoIn();
        changePassword(dtoIn, () => {
            closeForm()
        })
    }

    function validatePasswords() {
        if (!validatePassword(txtCurrentPassword, t("change-password-password-current-password-not-specified"), t("change-password-password-current-password-wrong-syntax"))) {
            return false;
        }
        if (!validatePassword(txtNewPassword, t("change-password-password-new-password-not-specified"), t("change-password-password-new-password-wrong-syntax"))) {
            return false;
        }
        if (!validatePassword(txtConfirmPassword, t("change-password-password-confirmation-password-now-specified"), t("change-password-password-confirmation-password-wrong-syntax"))) {
            return false;
        }
        if (!checkCurrentAndNewPasswordsAreDifferent()) {
            return false;
        }
        return validateConfirmationPassword();

    }

    function validatePassword(passwordField, txtPasswordNotSpecified, txtInvalidPasswordSyntax) {
        if (passwordField.value.replace(/\s*/, "") === "") {
            showSnackBarDialogWarning(txtPasswordNotSpecified);
            return false;
        }
        if (!passwordField.validate()) {
            showSnackBarDialogWarning(txtInvalidPasswordSyntax);
            return false;
        }
        return true;
    }

    /**
     * Check that the new password is different from the original (/ current).
     */
    function checkCurrentAndNewPasswordsAreDifferent() {
        if (txtCurrentPassword.value.trim() === txtNewPassword.value.trim()) {
            showSnackBarDialogWarning(t("change-password-password-new-and-current-password-are-same"));
            return false;
        }
        return true;
    }

    function validateConfirmationPassword() {
        if (txtNewPassword.value !== txtConfirmPassword.value) {
            showSnackBarDialogWarning(t("change-password-password-new-and-confirmation-password-are-different"));
            return false;
        }
        return true;
    }

    const createChangePasswordDtoIn = () => {
        return {
            username,
            email,
            originalPassword: txtCurrentPassword.value,
            newPassword: txtNewPassword.value,
            confirmationPassword: txtConfirmPassword.value
        }
    };

    function showSnackBarDialogWarning(message) {
        quizContext.snackBar.openSnackBar(WARNING, message, VERTICAL_POSITION, HORIZONTAL_POSITION);
    }

    return (
        <Dialog aria-labelledby="change-password-form-title"
                open={isFormOpen}>
            <DialogTitle id="change-password-form-title" onClose={closeForm} className={classes.title}>
                {t("change-password-form-title")}
            </DialogTitle>
            <DialogContent dividers>
                <Container component="main" maxWidth="xs">
                    <form>
                        <ProfileTextField use={txtEmail}/>
                        <ProfileTextField use={txtCurrentPassword}/>
                        <ProfileTextField use={txtNewPassword}/>
                        <ProfileTextField use={txtConfirmPassword}/>
                    </form>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={executeChangePassword} color="primary">
                    {t("change-password-btn-save-changes")}
                </Button>
                <Button autoFocus onClick={closeForm} color="primary">
                    {t("change-password-btn-cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePasswordForm;
// fixme
//     - pak přes víkend udelat tu validaci na počet otázek apod.
//
//
//     pak treba filtrovani otazek v managementu otazek! a razeni dle varianty atd.??? - DOMYSLET
//
//
//     - ten formulář pro registraci asi dat do toho menu prihlaseneho uzivatele, protože pak jestli se to bude rozšiřovat, tak tento dialog bude pouze pro běžného uživatele, akorát tam nebudou role a bude zasílat veci jinam! - takže tento fomulář pak když tak smazat nebo vypnout apod. ??? nebo nechat, ale akce zrušit ???
//     - pripadne doplnit něco zakladniho pro bezneho uzivatele?
//     - zvazit, jestli nejak ma smysl delat, kdyz existuje hrac se stejnym jmenem? - jestli se to nějako nespoji?
//     postup:
//     - to jeste napsat, ze pri registraci bezneho uzivatele doplnit, ze kdyz zada username a email hrace, tak jen dodelat jmeno a prijmeni a bude to i uzivatel, ktery budemit své hry!
//     - ale kdyz to bude User - admin apod. z tabulky user, tak uz je to obsazene!
//     - pak po prihlaseni udelat, ze se mu zobrazi pouze treba jeho hry a vychozi dashboard?
//         - doplnit se to da postupne vzdy!
