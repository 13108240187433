import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    roles: {
        width: '100%',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        maxHeight: 150
    }
}));

/**
 * Used to display a list of all set roles to the user.
 *
 * @author Jan Krunčík
 * @since 24.09.2019 21:07
 */

function RolesList({roles, removeRole}) {

    const classes = useStyles();

    const {t} = useTranslation()

    return (
        <List className={classes.roles}>
            {roles.map(role => {
                return (
                    <ListItem key={role.name} role={undefined} dense button>
                        <ListItemText id={role.name} primary={t(role.text)}/>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => removeRole(role)}>
                                <DeleteIcon/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )
            })}
        </List>
    )
}

export default RolesList;
